import {
  FETCH_RECENT_PLAYED, ADD_TO_RECENT_PLAYED, DELETE_FROM_RECENT_PLAYED
}
  from '../actions/types';
// import { initialState } from '../store';
import { getFetchRecentPlayedEpisodes, setFetchRecentPlayedEpisodes } from '../lib/fileUtils';

// REDUCERS
export const recentPlayedReducer = (state = {}, action) => {
  // console.log('recent : ' + action.type);
  switch (action.type) {
    case FETCH_RECENT_PLAYED:
      setFetchRecentPlayedEpisodes(true);
      return Object.assign({}, state, action.payload);
    case ADD_TO_RECENT_PLAYED:
      if (getFetchRecentPlayedEpisodes()) {
        return Object.assign({}, state, action.payload);
      } else {
        return state;
      }
    case DELETE_FROM_RECENT_PLAYED: {
      const copy = Object.assign({}, state);
      if (copy[action.payload])
        delete copy[action.payload];
      return copy; //Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
};