import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Search from '../Search';
import SignUp from '../Auth/SignUp';
import AccountOptions from './AccountOptions';
import AddCustomRSS from '../Podcast/AddCustomRSS';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppCustomLink from '../Common/AppCustomLink';
import { staticAssetsPrefix, saveLocally } from '../../lib/utils';
import { toggleLeftBar } from '../../actions';
import { throttle } from '../../lib/debounce';
import { LeftOutlined, RightOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import { spacing, fontSize, maxScreenSize, minScreenSize, colors } from '../../lib/theme';
// import { IS_DARK_MODE } from '../../lib/strings';
import ThemeOptions from '../Common/ThemeOptions';
import Premium from '../Common/Premium';
import { getDashboardPlanId, getIsPremiumUser } from '../../lib/fileUtils';
import DashboardAccountOptions from './DashboardAccountOptions';
// const TopBar = () => (
class TopBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = { scrolledDown: false, showPremiumDelay: false };
    this.handleScroll = throttle(this.handleScroll, 200);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    setTimeout(() => {
      this.setState({ showPremiumDelay: true });
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    // const { isArticlePage } = this.props;
    // if (!isArticlePage) {
    let scrollTop = window.scrollY; // event.srcElement.body.scrollTop;
    if (scrollTop > 20) {
      const { scrolledDown } = this.state;
      if (!scrolledDown) {
        this.setState({ scrolledDown: true });
      }
    } else {
      const { scrolledDown } = this.state;
      if (scrolledDown) {
        this.setState({ scrolledDown: false });
      }
    }
    // }
    // let itemTranslate = Math.min(0, scrollTop / 3 - 60);
    // let scrollTop = event.srcElement.body.scrollTop,
    //     itemTranslate = Math.min(0, scrollTop/3 - 60);

    // this.setState({
    //   transform: itemTranslate
    // });
  }

  goBack = () => {
    window.history.back();
  }

  goForward = () => {
    window.history.forward();
  }

  toggleLeftBar = () => {
    // put in redux
    this.props.toggleLeftBar();
  }

  render() {
    const { userDetails, showRefresh, showNavigator,
      isArticlePage, settingsState, isDashboard, isPremiumPage } = this.props;
    const { loadingUserDetails } = this.props.loadingState;
    // const { leftBarColor } = this.props.settingsState;
    // const leftBarColor = 'lightgray';

    const { scrolledDown, showPremiumDelay } = this.state;
    if (!showPremiumDelay) {
      return <></>
    }
    // const bgColor = scrolledDown ? `${leftBarColor}` : 'none';
    const scrollClass = scrolledDown ? 'scrollClass' : '';
    console.dir(getIsPremiumUser());
    const showRightBar = !getIsPremiumUser() && !isDashboard; // false;
    return (
      <div className={'padding ' + scrollClass}>
        <Row className='top-bar-row'>
          <Col xs={3} md={2} xl={0} >
            <p className='toggle-button' onClick={this.toggleLeftBar}>
              <FontAwesomeIcon className='bars-icon' icon='bars' size='lg' />
            </p>
          </Col>
          {showNavigator && <Col xs={0} md={2} xl={2} style={{ 'marginTop': `${spacing('xs')}`, color: `${colors.primary}` }}>
            {/* <Icon type="arrow-left" />
            <Icon type="left-square" /> */}
            <LeftOutlined onClick={this.goBack} style={{ 'fontSize': `${fontSize('lg')}` }} />&nbsp;
            {/* <Icon type="left-circle" theme="filled" />
            <Icon type="left-circle" theme="twoTone" /> */}
            <RightOutlined onClick={this.goForward} style={{ 'fontSize': `${fontSize('lg')}` }} />
          </Col>}
          {isArticlePage && <Col xs={15} md={6} xl={5} style={{}}> <AppCustomLink href='/' >
            {/* <span className='footer-link'>Legal</span> */}
            <a style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
              {/* <img src={`${staticAssetsPrefix}/static/images/main-page/landing.svg`} /> */}
              <img style={{ width: '2.25em' }} className='logo-img' src={`${staticAssetsPrefix}/static/images/Logo.png`} alt='Podurama logo' />
              <h2 style={{ fontWeight: '700', marginBottom: '0' }}>&nbsp;Podurama</h2>
            </a>
          </AppCustomLink>
          </Col>}
          {!isDashboard &&
            <Col xs={isArticlePage ? 0 : 15} md={isArticlePage ? 7 : 10} xl={8}>
              <Search />
            </Col>
          }
          {!isDashboard && !isArticlePage && <Col xs={0} md={2} xl={2}>
            <AddCustomRSS />
          </Col>}
          {isDashboard && <Col xs={0} md={11} xl={11}>&nbsp;</Col>}
          <Col xs={0} xl={1}>
            {showRefresh &&
              <div style={{ textAlign: 'center', marginTop: '7px' }}>
                <Tooltip placement="bottom" title='Refresh'>
                  <a onClick={() => location.reload()}>
                    {/* <FontAwesomeIcon icon='sync' size='lg' /> */}
                    <SyncOutlined style={{ fontSize: '1.25em' }} />
                  </a>
                </Tooltip>
              </div>}
          </Col>
          {!isDashboard && <Col xs={0} xl={3}>
            {/* <Switch checked={isDarkTheme} onChange={this.onChangeTheme} /> */}
            <div style={{ alignItems: 'center', textAlign: 'right', marginTop: '2px', marginLeft: '25%' }}>
              <ThemeOptions />
            </div>
          </Col>
          }
          {loadingUserDetails && <Col xs={{ span: 2, offset: 1 }} md={{ span: 2, offset: 4 }} xl={{ span: 2, offset: 3 }}>
            <p>Loading&hellip;</p>
          </Col>}
          {!loadingUserDetails && isDashboard && getDashboardPlanId() < 2 && showPremiumDelay &&
            <Col xs={{ span: 2, offset: isDashboard ? 2 : 0 }} md={{ span: 2, offset: 0 }} xl={{ span: 2, offset: 0 }}>
              <AppCustomLink href={`/pricing`}>
                <Button className='third-button' type='primary'>20% Off Premium</Button>
              </AppCustomLink>
            </Col>}
          <>
            <Col xs={{ span: 6, offset: isDashboard ? 10 : 0 }} md={{ span: 3, offset: isDashboard ? 4 : 1 }} xl={{ span: 3, offset: isArticlePage ? 2 : 1 }}
              style={{ textAlign: 'right', display: `${(!loadingUserDetails && !userDetails) ? 'block' : 'none'}` }}>
              <SignUp key='signUp' typeId='signUp' isAds={isDashboard} />
            </Col>
            {!loadingUserDetails && !userDetails &&
              <Col xs={0} md={isDashboard ? 0 : 4} xl={3} style={{ textAlign: 'center' }}>
                <SignUp key='signIn' typeId='signIn' isAds={isDashboard} />
              </Col>
            }
          </>
          {!loadingUserDetails && userDetails && !isDashboard && showPremiumDelay &&
            <Col xs={{ span: 0, offset: 0 }} md={{ span: 2, offset: 1 }} xl={{ span: 3, offset: 1 }}>
              {(!getIsPremiumUser() && !isPremiumPage) ? <Premium /> : <></>}
            </Col>}

          {!loadingUserDetails && userDetails &&
            <Col style={{ marginTop: `${spacing('xs')}` }} xs={{ span: 4, offset: isDashboard ? 16 : 2 }} md={{ span: 2, offset: 2 }} xl={{ span: 3, offset: isDashboard ? 2 : 0 }}>
              {/* <Row>
                <Col style={{ textAlign: 'right' }} xs={24} xl={14}> */}
              {isDashboard ? <DashboardAccountOptions /> :
                <AccountOptions />}
              {/* </Col>
              </Row> */}
            </Col>
          }
        </Row>
        <style jsx>
          {`
            .padding{
              padding: ${spacing('md')};
              padding-left: ${isArticlePage ? '20em' : spacing('xl')};
              padding-right: ${showRightBar ? (isArticlePage ? '20em' : spacing('xl')) : 0};
              position: fixed;
              width: ${isArticlePage ? '100vw' : (showRightBar ? '70vw' : '90vw')};
              z-index: 10;
              top: ${this.props.settingsState.topPaddingGlobal}px;
              transition: top 1s; 
              background-color: ${settingsState.topBarColor}; // white;
              transition: background-color 0.1s linear;
              height: 64px;
            }
            .scrollClass {
              background: ${settingsState.topBarColor}; // white;
              box-shadow: 0 4px 4px -2px rgba(0,0,0,.2);
            }
            .toggle-button {
              text-align: center;
              margin-top: 6px;
            }
            :global(.ant-btn-primary) {
              background: ${colors.primary};
              border-color: ${colors.primary};
            }
            :global(.third-button) {
                background: ${colors.third};
                border-color: ${colors.third};
                color: white;
            }
            :global(.third-button.outline) {
                background: white; // ${colors.third};
                border-color: ${colors.third};
                color: ${colors.third};
            }
            :global(.third-button:hover) {
                background: #8d2256;
                border-color: #8d2256;
                color: white;
            }
            :global(.auth-modal .ant-modal-content) {
              width: 60vw;
              transform: translate(-9vw,0);
              // height: 50vh;
            }
            :global(.auth-modal .ant-form-item-label) {
                text-align: left;
            }
            @media ${maxScreenSize.tablet} {
              :global(.auth-modal .ant-modal-content) {
                  width: 95vw;
                  left: 10vw;
                  // transform: translate(-9vw,0);
                  // height: 50vh;
              }
              :global(.top-bar-row) {
                width: 95vw;
              }
            }
            @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                :global(.auth-modal .ant-modal-content) {
                    width: 70vw;
                    left: 10vw;
                }
                .padding{
                  padding-right: ${isArticlePage ? '2em' : spacing('xl')};
                }
            }
            @media ${maxScreenSize.laptop} {
              .padding{
                width: 100vw;
                padding-left: ${spacing('xs')};
              }
            }
          `}
        </style>
      </div>
    );
  }
}

TopBar.propTypes = {
  showRefresh: PropTypes.bool,
  showNavigator: PropTypes.bool,
  isArticlePage: PropTypes.bool,
  isDashboard: PropTypes.bool,
  isPremiumPage: PropTypes.bool,
  userDetails: PropTypes.object,
  loadingState: PropTypes.object,
  toggleLeftBar: PropTypes.func,
  // toggleDarkTheme: PropTypes.func,
  settingsState: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userDetails,
    loadingState: state.loadingState,
    settingsState: state.settingsState,
    // loadingUserDetails: state.loadingState.loadingUserDetails,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLeftBar: bindActionCreators(toggleLeftBar, dispatch),
    // toggleDarkTheme: bindActionCreators(toggleDarkTheme, dispatch),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
// export default TopBar