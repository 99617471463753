import React, { Component } from 'react'
import { getIsPremiumUser } from '../../lib/fileUtils'
import PropTypes from 'prop-types';

export default class ProBadge extends Component {
    render() {
        if (!getIsPremiumUser()) {
            return <></>
        }
        const { fontSize, marginTop } = this.props;
        return (
            <>
                <h4 className='main'>
                    Pro
                </h4>
                <style jsx>
                    {`
                    .main {
                        position: absolute;
                        font-size: ${fontSize || 8}px;
                        z-index: 10;
                        top: -${marginTop || 8}px;
                        right: -8px;
                        background: #FF6D00;
                        color: white !important;
                        padding: 2px 4px;
                        border-radius: 4px;
                    }
                    .badge {

                    }
                    `}
                </style>
            </>
        )
    }
}


ProBadge.propTypes = {
    fontSize: PropTypes.number,
    marginTop: PropTypes.number,
}
