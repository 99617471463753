import React from 'react';
import PropTypes from 'prop-types';
// import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
// import Router from 'next/router';

// import { getRequiredPodcasts } from '../../reducers';
import ShowApps from '../Common/ShowApps';
import PodcastSmall from '../Podcast/PodcastSmall';
import { spacing, rightSideBar, maxScreenSize } from '../../lib/theme';
import OtherRelated from '../Related/OtherRelated';
import RelatedMain from '../Related/RelatedMain';
import Router from 'next/router';
import { CloseCircleOutlined } from '@ant-design/icons';
import { getIsPremiumUser } from '../../lib/fileUtils';
import { notification } from 'antd';
import ShowAd from '../Common/ShowAd';
import AdPodcast from './AdPodcast';

//List component which loads posts as they come in.
class RightBar extends React.Component {
    //Renders the list and all the post items returned from the listener.
    constructor(props) {
        super(props);
        this.state = { keyNeeded: '', loading: true };
    }

    componentDidMount() {
        const mainUrl = window.location.href;
        this.setKeyNeeded(mainUrl);
        Router.events.on('routeChangeComplete', (url) => {
            console.dir(url);
            this.setKeyNeeded(url);
        })
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1000);
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    setKeyNeeded = (url) => {
        console.dir(url);
        if (url.includes('/podcast')) {
            const vals = url.split('-i');
            const collectionId = vals[vals.length - 1];
            this.setState({ keyNeeded: collectionId });
        } else {
            this.setState({ keyNeeded: '' });
        }
    }

    closeBar = () => {
        if (!getIsPremiumUser()) {
            notification.info({
                // duration: 0,
                message: 'Please move to premium version to close this section.',
            });
            return;
        }
    }

    render() {
        // console.dir(userPlaylists);
        const { sidePodcastsObj, similarPodcasts, dbPodcastState, userDetails } = this.props;
        const { keyNeeded, loading } = this.state;
        // console.dir(keyNeeded);
        const similarPodcastsNeeded = similarPodcasts[`${keyNeeded}`] || [];
        // console.dir(similarPodcastsNeeded);
        const sidePodcasts = Object.values(sidePodcastsObj);
        const { loadingSidePodcasts } = this.props.loadingState;
        const { rightBarColor, rightBarText, borderColor } = this.props.settingsState;

        const showSimilarPodcasts = keyNeeded && similarPodcastsNeeded.length > 0;
        const currentPodcastName = dbPodcastState && dbPodcastState[keyNeeded] && dbPodcastState[keyNeeded].collectionName || '';

        const showRightBar = true;
        if (!showRightBar || loading) {
            return <></>
        }

        return (
            <div className='right-bar'>
                <div style={{ marginLeft: '12px', fontSize: '18px', cursor: 'pointer' }}>
                    <CloseCircleOutlined onClick={this.closeBar} />
                </div>
                <h2 className='side-podcasts gothic'>{showSimilarPodcasts ? (currentPodcastName ? `Podcasts like ${currentPodcastName}` : 'Similar Podcasts') : 'Featured'}</h2>
                <div style={{ minHeight: showSimilarPodcasts ? '180px' : '180px' }}>
                    {loadingSidePodcasts && <p style={{ paddingLeft: `${spacing()}` }}>Loading&hellip;</p>}
                    {showSimilarPodcasts ?
                        <div style={{ paddingBottom: '20px' }}>
                            {similarPodcastsNeeded.slice(0, 6).map(similarPodcast => (
                                <PodcastSmall key={similarPodcast.collectionId} podcast={similarPodcast} />
                            ))}
                        </div> :
                        <div>{sidePodcasts && sidePodcasts.slice(0, 6).map(sidePodcast => (
                            <PodcastSmall key={sidePodcast.collectionId} podcast={sidePodcast} />
                        ))}
                        </div>
                    }
                </div>
                {userDetails && userDetails.uid &&
                    <AdPodcast />
                }
                {userDetails && userDetails.uid &&
                    <div>
                        {/* <ShowAd /> */}
                        {/* <ins className="adsbygoogle"
                            style={{ display: 'block' }}
                            data-ad-client="ca-pub-5279418794909403"
                            data-ad-slot="5892986085"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins> */}
                    </div>
                }
                <br />
                {/* <RelatedMain keyNeeded={keyNeeded} /> */}
                <div style={{ position: 'fixed', bottom: '8em' }}>
                    <ShowApps key='rightBar' />
                </div>
                <style jsx>
                    {`
            .right-bar{
              height: 100%;
              width: ${rightSideBar};
              max-width: 300px;
              position: fixed;
              z-index: 11;
              top: ${this.props.settingsState.topPaddingGlobal}px;
              transition: top 1s; 
              right: 0;
              background-color: ${rightBarColor}; // #111;
              overflow-x: hidden;
              padding-top: 1em;
              color: ${rightBarText};
              border-left: 1px solid ${borderColor};
            }
            @media ${maxScreenSize.laptop} {
                .right-bar {
                    display: none;
                }
            }
            .side-podcasts {
                // color: white;
                font-size: 14px;
              color: ${rightBarText};
              margin-left: ${spacing()};
                margin-bottom: ${spacing()};
            }
          `}
                </style>
            </div>
        )
    }
}

RightBar.propTypes = {
    loadingState: PropTypes.object,
    sidePodcastsObj: PropTypes.object,
    settingsState: PropTypes.object,
    similarPodcasts: PropTypes.object,
    dbPodcastState: PropTypes.object,
    userDetails: PropTypes.object,
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        similarPodcasts: state.similarPodcasts,
        sidePodcastsObj: state.sidePodcasts,
        loadingState: state.loadingState,
        settingsState: state.settingsState,
        dbPodcastState: state.dbPodcastState
    }
}

// const mapStateToProps = (state) => {
//   return {
//     userDetails: state.userDetails,
//     userPlaylists: state.userPlaylists,
//   }
// }

export default connect(mapStateToProps, null)(RightBar)