import { createStore, applyMiddleware } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import { thunk } from 'redux-thunk'
// import thunkMiddleware from 'redux-thunk'
// import { PostReducer } from './reducers/PostReducer';
import { rootReducer } from './reducers/index';

const initialStateLoc = {};

export const initialState = initialStateLoc;
// {
//   // posts: [],
//   // loadingUserDetails: true,
//   // userPost: ''
// };

export const initStore = (initialState = initialStateLoc) => {
  return createStore(rootReducer, initialState, applyMiddleware(thunk))
};