import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { getPremiumInfoData } from '../../actions';
import { maxScreenSize } from '../../lib/theme';
import { Col, Row } from 'antd';
import { mainPoints } from '../../lib/utils';

const LottieComponent = dynamic(() => import('../Common/LottieComponent'), {
    ssr: false, // Set to true if you want server-side rendering
    // loading: () => <p>Loading...</p>, // Optional loading component
});

const defaultPoints = [
    'No Visual ads',
    'Unlimited playlists and tags',
    'Unlimited trailers per day',
    'Unlimited bookmarks',
    'Multiple themes + personalized launch screen (mobile)',
    'Personalized premium recommendations',
    'Pro badge on your profile',
];


export default class PremiumPoints extends Component {
    render() {
        // const { premiumInfoLoc } = this.props;
        const premiumInfoLoc = getPremiumInfoData() || {};
        return (
            <div style={{ position: 'relative' }}>
                <Row>
                    <Col xs={24} md={12}>
                        {(mainPoints).map((point, index) => (
                            <p key={index} style={{
                                marginBottom: '24px'
                            }} className='serif'>
                                <div style={{
                                    background: '#E03145', display: 'inline', padding: '6px', borderRadius: '50%',
                                }}>
                                    <FontAwesomeIcon style={{ color: 'white', width: '16px' }} icon={point['icon']} />
                                </div>
                                &nbsp; {point['name']}
                            </p>
                        ))}
                    </Col>
                    <Col xs={0} md={12}>
                        <div className='lot'>
                            <LottieComponent image={'gift2'} lottieWidth={100} loop={false} />
                        </div>
                    </Col>
                </Row>
                <style jsx>
                    {`
                        // .lot {
                        // position: absolute;
                        // right: 0;
                        // top: 0;
                        // width: 500px;
                        // display: block;
                        // }
                        // @media ${maxScreenSize.laptop} {
                        // .lot {
                        //     display: none;
                        // }
                        // }
                    `}
                </style>
            </div >
        )
    }
}

PremiumPoints.propTypes = {
    // premiumInfoLoc: PropTypes.object,
    index: PropTypes.number,
}
