import { deleteField, doc, getDoc, setDoc } from 'firebase/firestore/lite';
import { firestore, storage } from '../lib/db';

import { ADD_MY_FILES_ACTION, ADD_MY_FILE_UPLOAD_ACTION, ADD_MY_FILE_UPLOAD_PROGRESS_ACTION, DELETE_MY_FILES_ACTION, DELETE_MY_FILE_UPLOAD_ACTION, DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION, FETCH_MY_FILES_ACTION } from "./types";
import { tokenFetch, tokenFetchPOST } from '../lib/tokenUtils';
import { convertMyFileToEpisode, createRandomShortString, dashboardPlanIds, getFirebaseUser, getUUID, isMyFileUrl, maxCloudStorageInBytes, minsUsed, serverApi, setMyFileDownloadFileNameFinal, sleep } from '../lib/utils';
import { notification } from 'antd';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { getDashboardPlanId } from '../lib/fileUtils';
import { billingDataGlobal } from './DashboardActions';
import { logEpisodeData } from './EpisodeActions';
import Router from 'next/router';

//     return (Store<AppState> store) async {
export const fetchAllMyFiles = (userUID) => async (dispatch, getState) => {
    getDoc(doc(firestore, `myFiles`, userUID))
        .then((doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const files = data['files'] ?? {};
                console.dir(files);
                const episodes = {};
                // utils.printLog('fetchAllMyFiles00');
                const myFileUploadStatus = getState().myFileUploadStatus;

                Object.keys(files).forEach((key) => {
                    const value = files[key];
                    value['isMyFile'] = true;
                    if (value['enclosure'] != null &&
                        value['enclosure']['link'] != null) {
                        if (value['enclosure']['link'].includes('://')) {
                            value['description'] = value['enclosure']['link'];
                        }
                    }

                    /// if upload was cancelled, delete it.
                    if (!(key in myFileUploadStatus) ||
                        myFileUploadStatus[key] != 2) {
                        episodes[key] = value;
                    }
                });
                console.dir(episodes);
                // store.dispatch(FetchMyFilesAction(episodes));
                dispatch({
                    type: FETCH_MY_FILES_ACTION,
                    files: episodes
                });
            }
        }).catch((error) => {
            console.error(error);
        });
}


// AudioPlayer _fileDurationPlayer = AudioPlayer();

// void firstTimeFileMoveIntoApp(String fileName, String fileTitle, int fileSize,
//     List<int> fileBytes) async {
//   final String storageKey = await utils.currentDownloadDirectory();
//   utils.printLog('storageKey0: ' + storageKey);
//   final Directory downloadDir = await localDownloadsStoragePath(
//       storageKey); // Directory('${dir.path}/episodeDownloads');
//   final String fileNameFin = utils.setMyFileDownloadFileNameFinal(fileName);

//   final String filePath = '${downloadDir.path}/${fileNameFin}';
//   final File file = new File(filePath);
//   await file.create();
//   await file.writeAsBytes(fileBytes);

//   int duration = 0;
//   try {
//     final Duration mediaItemDuration =
//         await _fileDurationPlayer.setFilePath(filePath);
//     duration = mediaItemDuration?.inSeconds ?? 0;
//     utils.printLog('duration01');
//     utils.printLog(duration.seconds);
//   } catch (err) {
//     duration = 0;
//   }

//   final PodcastEpisode podcastEpisode = PodcastEpisode.fromJson(
//       utils.convertMyFileToEpisode(fileTitle, fileNameFin, fileSize, duration,
//           utils.getUUID(fileNameFin)));
//   utils.printLog('downloadDir : ' + downloadDir.path.toString());
//   podcastEpisode.downloadNameSet = fileNameFin;

//   StoreClass.store.dispatch(saveLocalDownloadsAction(podcastEpisode));

//   SharedPreferences.getInstance().then((prefs) {
//     if (myFilesAutoUploadToCloud) {
//       StoreClass.store.dispatch(uploadMyFile(podcastEpisode));
//     }
//   });
// }

export const uploadingFilesData = {};
const timers = {};
// ThunkAction < AppState > uploadMyFile(PodcastEpisode podcastEpisode) {
//     return (Store < AppState > store) async {
export const uploadMyFile = (fileOrig, fileTitle) => async (dispatch, getState) => {
    const objectURL = URL.createObjectURL(fileOrig);
    let duration = 0;
    try {
        duration = await getDuration(objectURL);
        URL.revokeObjectURL(objectURL);
    } catch (err) {
        URL.revokeObjectURL(objectURL);
    }
    console.dir(duration);

    const fileName = fileOrig.name;
    const fileSize = fileOrig.size;
    console.dir(fileName);
    const fileNameFin = setMyFileDownloadFileNameFinal(fileName);
    console.dir(fileNameFin);

    const podcastEpisode =
        convertMyFileToEpisode(fileTitle, fileNameFin, fileSize, duration,
            getUUID(fileNameFin));
    console.dir(podcastEpisode);
    const user = getFirebaseUser();
    if (user == null) {
        return;
    }
    const myFiles = getState().myFileEpisodes;
    let length = 0;
    for (const episode of Object.values(myFiles)) {
        if (episode.isMyFile && !isMyFileUrl(episode)) {
            length += episode.enclosure.length;
        }
    }
    console.dir(length);

    // final String storageKey = await utils.currentDownloadDirectory();
    // utils.printLog('storageKey0: ' + storageKey);
    // final Directory downloadDir = await localDownloadsStoragePath(
    //     storageKey); // Directory('${dir.path}/episodeDownloads');
    // // final String fileNameFin = utils.setMyFileDownloadFileNameFinal(fileName);

    // final String filePath =
    //     '${downloadDir.path}/${podcastEpisode.downloadName}';
    // final File file = File(filePath);
    // final List < int > fileBytes = await file.readAsBytes();
    // final int fileSize = await file.length();

    if (length + fileSize > maxCloudStorageInBytes) {
        notification.error({
            // duration: 0,
            message: 'Cloud storage limit reached. Uploading this file would exceed the max limit of 10GB.',
        });
        return;
    } else {
        // Get.dialog(AppAdaptiveAlert(
        //     title: 'Upload Started',
        //     content:
        //     "Please don't minimize or close the app until upload has finished. Larger files might take a while to upload. You might prefer our webApp to upload large files.",
        //     okCallback: () { },
        // ));
    }

    // final String authToken = await user.getIdToken();
    let url =
        // 'http://localhost:5001/podcastapp-767c2/us-central1/uploadMyFileV2?' +
        `${serverApi}uploadMyFileV2?` +
        `fileTitle=${fileTitle}&` +
        `duration=${duration}&` +
        `fileSize=${fileSize}&` +
        `fileName=${fileNameFin}`;
    // var client = http.Client();
    // store.dispatch(DeleteMyFileUploadProgressAction(podcastEpisode.guid));
    uploadingFilesData[podcastEpisode.guid] = { fileName, fileTitle };
    dispatch({
        type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
        guid: podcastEpisode.guid
    });
    // store.dispatch(AddMyFileUploadAction(podcastEpisode.guid));
    dispatch({
        type: ADD_MY_FILE_UPLOAD_ACTION,
        guid: podcastEpisode.guid,
        fileName
    });
    if (timers[podcastEpisode.guid]) {
        clearInterval(timers[podcastEpisode.guid]);
    }
    // utils.printLog('podcastEpisode.enclosure.length');
    console.dir(fileSize);
    const fileSizeInMB = (fileSize ?? 0) / (1000 * 1000);
    let count = 0;
    timers[podcastEpisode.guid] = setInterval(() => {
        count++;
        const progress = (2 * count) / fileSizeInMB;
        console.dir(count);
        /// upload 2MB every 2sec for now
        if (progress < 0.85) {
            // dispatch(
            //     AddMyFileUploadProgressAction(podcastEpisode.guid, progress));

            dispatch({
                type: ADD_MY_FILE_UPLOAD_PROGRESS_ACTION,
                guid: podcastEpisode.guid,
                progress
            });

        } else {
            clearInterval(timers[podcastEpisode.guid]);
        }
    }, 2000);

    try {
        const storagePath = `uploadTempFiles/${fileNameFin}`;
        const uploadTempRef = ref(storage, storagePath);
        const snapshot = await uploadBytes(uploadTempRef, fileOrig);
        console.dir(snapshot);
        url += `&storagePath=${storagePath}`;

        tokenFetch(url,
            // fileOrig,
            // {
            //     'Content-Type': 'audio/mpeg',
            //     'Accept': "*/*",
            //     // 'Content-Length': File(filePath).lengthSync().toString(),
            //     'Connection': 'keep-alive',
            // }
        )
            .then((value) => {
                // utils.printLog('upload result');

                const userDetails =
                    getState().userDetails;
                dispatch(fetchAllMyFiles(userDetails.uid));
                dispatch({
                    type: DELETE_MY_FILE_UPLOAD_ACTION,
                    guid: podcastEpisode.guid
                });
                dispatch({
                    type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
                    guid: podcastEpisode.guid
                });
                console.dir(value);
                const myFileUploadStatus =
                    getState().myFileUploadStatus;

                /// if upload was cancelled, delete it.
                if (myFileUploadStatus[podcastEpisode.guid] == 2) {
                    setTimeout(() => {
                        dispatch(deleteMyFiles(podcastEpisode));
                    }, 1500);
                }
            }).catch((err) => {
                console.dir(err);
                console.error(err);
                notification.error({
                    message: 'There was an error uploading the file. Pls try again later.'
                })
                dispatch({
                    type: DELETE_MY_FILE_UPLOAD_ACTION,
                    guid: podcastEpisode.guid
                });
                dispatch({
                    type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
                    guid: podcastEpisode.guid
                });
            });
    } catch (err2) {
        notification.error({
            message: 'There was an error uploading the file. Pls try again.'
        })
    }
}

export const uploadMyFileDashboard = (fileOrig, fileTitle, prevTranscripts) => async (dispatch, getState) => {
    const objectURL = URL.createObjectURL(fileOrig);
    let duration = 0;
    try {
        duration = await getDuration(objectURL);
        URL.revokeObjectURL(objectURL);
    } catch (err) {
        URL.revokeObjectURL(objectURL);
    }
    console.dir(duration);

    const fileName = fileOrig.name;
    const fileSize = fileOrig.size;
    console.dir(fileName);
    const fileNameFin = setMyFileDownloadFileNameFinal(fileName);
    console.dir(fileNameFin);

    const podcastEpisode =
        convertMyFileToEpisode(fileTitle, fileNameFin, fileSize, duration,
            createRandomShortString(20));
    console.dir(podcastEpisode);
    const user = getFirebaseUser();
    if (user == null) {
        return;
    }

    const userDetails = getState().userDetails;
    console.dir(userDetails);
    console.dir(userDetails.metadata.createdAt);
    const startDate = billingDataGlobal?.premium?.startDate || userDetails.metadata.createdAt;
    const minsRemaining = dashboardPlanIds[getDashboardPlanId() || 1]['mins'] - minsUsed(startDate, prevTranscripts) - Math.round(duration / 60);

    // const myFiles = getState().myFileEpisodes;
    // let length = 0;
    // for (const episode of Object.values(myFiles)) {
    //     if (episode.isMyFile && !isMyFileUrl(episode)) {
    //         length += episode.enclosure.length;
    //     }
    // }
    console.dir(minsRemaining);
    // final String storageKey = await utils.currentDownloadDirectory();
    // utils.printLog('storageKey0: ' + storageKey);
    // final Directory downloadDir = await localDownloadsStoragePath(
    //     storageKey); // Directory('${dir.path}/episodeDownloads');
    // // final String fileNameFin = utils.setMyFileDownloadFileNameFinal(fileName);

    // final String filePath =
    //     '${downloadDir.path}/${podcastEpisode.downloadName}';
    // final File file = File(filePath);
    // final List < int > fileBytes = await file.readAsBytes();
    // final int fileSize = await file.length();

    if (minsRemaining <= 0) {
        notification.error({
            // duration: 0,
            message: 'Transcript AI mins limit reached. Please upgrade or wait till next month to transcribe more.',
        });
        return;
    } else {
        // Get.dialog(AppAdaptiveAlert(
        //     title: 'Upload Started',
        //     content:
        //     "Please don't minimize or close the app until upload has finished. Larger files might take a while to upload. You might prefer our webApp to upload large files.",
        //     okCallback: () { },
        // ));
    }

    // var client = http.Client();
    // store.dispatch(DeleteMyFileUploadProgressAction(podcastEpisode.guid));
    uploadingFilesData[podcastEpisode.guid] = { fileName, fileTitle };
    dispatch({
        type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
        guid: podcastEpisode.guid
    });
    // store.dispatch(AddMyFileUploadAction(podcastEpisode.guid));
    dispatch({
        type: ADD_MY_FILE_UPLOAD_ACTION,
        guid: podcastEpisode.guid,
        fileName
    });
    if (timers[podcastEpisode.guid]) {
        clearInterval(timers[podcastEpisode.guid]);
    }
    // utils.printLog('podcastEpisode.enclosure.length');
    console.dir(fileSize);
    const fileSizeInMB = (fileSize ?? 0) / (1000 * 1000);
    let count = 0;
    timers[podcastEpisode.guid] = setInterval(() => {
        count++;
        const progress = (2 * count) / fileSizeInMB;
        console.dir(count);
        /// upload 2MB every 2sec for now
        if (progress < 0.85) {
            // dispatch(
            //     AddMyFileUploadProgressAction(podcastEpisode.guid, progress));

            dispatch({
                type: ADD_MY_FILE_UPLOAD_PROGRESS_ACTION,
                guid: podcastEpisode.guid,
                progress
            });

        } else {
            clearInterval(timers[podcastEpisode.guid]);
        }
    }, 2000);

    try {
        const storagePath = `uploadTempFilesDashboard/${fileNameFin}`;
        const uploadTempRef = ref(storage, storagePath);
        const snapshot = await uploadBytes(uploadTempRef, fileOrig);
        console.dir(snapshot);
        // console.dir(snapshot.ref.getDownloadURL());
        getDownloadURL(uploadTempRef)
            .then(async (urlPath) => {
                console.dir(urlPath);
                podcastEpisode.enclosure.link = urlPath;
                logEpisodeData(podcastEpisode, true);
                await sleep(2000);
                dispatch({
                    type: DELETE_MY_FILE_UPLOAD_ACTION,
                    guid: podcastEpisode.guid
                });
                dispatch({
                    type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
                    guid: podcastEpisode.guid
                });
                Router.push(`/podcast-transcript?id=${getUUID(podcastEpisode.guid)}`);
                // Insert url into an <img> tag to "download"
            })
            .catch((error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/object-not-found':
                        // File doesn't exist
                        break;
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect the server response
                        break;
                }
                notification.error({
                    message: 'There was an error uploading the file. Pls try again or contact support.'
                })
            });
        // url += `&storagePath=${storagePath}`;

        // tokenFetch(url,
        //     // fileOrig,
        //     // {
        //     //     'Content-Type': 'audio/mpeg',
        //     //     'Accept': "*/*",
        //     //     // 'Content-Length': File(filePath).lengthSync().toString(),
        //     //     'Connection': 'keep-alive',
        //     // }
        // )
        //     .then((value) => {
        //         // utils.printLog('upload result');

        //         const userDetails =
        //             getState().userDetails;
        //         dispatch(fetchAllMyFiles(userDetails.uid));
        //         dispatch({
        //             type: DELETE_MY_FILE_UPLOAD_ACTION,
        //             guid: podcastEpisode.guid
        //         });
        //         dispatch({
        //             type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
        //             guid: podcastEpisode.guid
        //         });
        //         console.dir(value);
        //         const myFileUploadStatus =
        //             getState().myFileUploadStatus;

        //         /// if upload was cancelled, delete it.
        //         if (myFileUploadStatus[podcastEpisode.guid] == 2) {
        //             setTimeout(() => {
        //                 dispatch(deleteMyFiles(podcastEpisode));
        //             }, 1500);
        //         }
        //     }).catch((err) => {
        //         console.dir(err);
        //         console.error(err);
        //         notification.error({
        //             message: 'There was an error uploading the file. Pls try again later.'
        //         })
        //         dispatch({
        //             type: DELETE_MY_FILE_UPLOAD_ACTION,
        //             guid: podcastEpisode.guid
        //         });
        //         dispatch({
        //             type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
        //             guid: podcastEpisode.guid
        //         });
        //     });
    } catch (err2) {
        notification.error({
            message: 'There was an error uploading the file. Pls try again.'
        })
    }
}

function getDuration(src) {
    return new Promise(function (resolve) {
        const audio = document.createElement('AUDIO');
        audio.src = src;
        audio.addEventListener('loadedmetadata', () => {
            console.dir(audio.duration);
            resolve(Math.round(audio.duration));
        });

        // var audio = new Audio();
        // audio.on("loadedmetadata", function () {
        //     resolve(audio.duration);
        // });
        // audio.src = src;
    });
}

// ThunkAction<AppState> addMyFilesUrl(String fileName, String fileUrl) {
//   return (Store<AppState> store) async {
export const addMyFilesUrl = (fileName, fileUrl) => async (dispatch, getState) => {
    const guid = getUUID(createRandomShortString(10));
    let duration = 0;
    const fileSize = 0;

    try {
        const mediaItemDuration =
            await getDuration(fileUrl);
        duration = mediaItemDuration ?? 0;
    } catch (err) {
        duration = 0;
    }

    dispatch(addMyFiles(guid, fileName, duration, fileSize, fileUrl, fileUrl,
        fileUrl.includes('mp4') ? 'video/mp4' : 'audio/mpeg'));
}

// ThunkAction<AppState> addMyFiles(
//     String guid, String fileName, int duration, int fileSize, String fileUrl,
//     {String description = '', String fileType: 'audio/mpeg'}) {
//   return (Store<AppState> store) async {
export const addMyFiles = (guid, fileName, duration, fileSize, fileUrl, description = '', fileType = 'audio/mpeg') => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    const json = {
        'author': '-',
        'collectionName': '-',
        'enclosure': {
            'duration': duration,
            'length': fileSize,
            'link': fileUrl,
            'url': fileUrl,
            'type': fileType
        },
        'guid': guid ?? '',
        'pubDate': Date.now(),
        'thumbnail': '',
        'artworkUrl600': '',
        'description': description,
        'title': fileName ?? '',
        'genres': [],
        'collectionId': -1,
        'rating': 0,
        'ratedBy': 0
    };

    const data = { [guid]: json };

    // store.dispatch(AddMyFilesAction(guid, PodcastEpisode.fromJson(json)));
    dispatch({
        type: ADD_MY_FILES_ACTION,
        guid: guid,
        episode: json
    });
    setDoc(doc(firestore, 'myFiles', userUID), { 'files': data }, { merge: true })
        .then(() => {
            const userDetails =
                getState().userDetails;
            dispatch(fetchAllMyFiles(userDetails.uid));
        })
        .catch((error) => {
            console.error(error);
        });
}

// ThunkAction<AppState> deleteMyFiles(PodcastEpisode podcastEpisode) {
//   return (Store<AppState> store) async {
export const deleteMyFiles = (podcastEpisode) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];

    const data = {};
    data['files'] = {};
    data['files'][`${podcastEpisode.guid}`] = deleteField();
    // utils.printLog('data10: ' + data.toString());
    // store.dispatch(DeleteMyFilesAction(podcastEpisode.guid));
    dispatch({
        type: DELETE_MY_FILES_ACTION,
        guid: podcastEpisode.guid
    });

    // store.dispatch(DeleteMyFileUploadProgressAction(podcastEpisode.guid));
    dispatch({
        type: DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION,
        guid: podcastEpisode.guid
    });
    // store.dispatch(DeleteMyFileUploadAction(podcastEpisode.guid));
    dispatch({
        type: DELETE_MY_FILE_UPLOAD_ACTION,
        guid: podcastEpisode.guid
    });

    // .then((data) {})
    setDoc(doc(firestore, 'myFiles', userUID), data, { merge: true })
        .catch((error) => {
            // print("Error deleting myfiles: " + error.toString());
            console.error(error);
        });
    if (!isMyFileUrl(podcastEpisode)) {
        const url =
            `${serverApi}deleteUploadedFile?` +
            `fileName=${podcastEpisode.enclosure?.link}`;
        tokenFetch(url);
    }
}