import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Router from 'next/router';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, message, Modal, Tooltip } from 'antd';
import { addCustomRSSFeed, fetchUserSubscribedPodcasts } from '../../actions';
import { spacing, fixedSpacing } from '../../lib/theme';
import { podcastSlugUrl, podcastHrefUrl, setCurrentPodcastData } from '../../lib/utils';
import AppAlertDiv from '../Common/AppAlertDiv';
import { setIsTyping } from '../../lib/fileUtils';

class AddCustomRSS extends Component {
    constructor(props) {
        super(props);
        this.state = { visible: false, errorMessage: '', urlValue: '', needsAuth: false, username: '', password: '' };
    }

    _addCustomRSS = () => {
        const { userDetails } = this.props;
        if (!userDetails || !userDetails['uid']) {
            message.warning('Please sign in to add custom RSS url.');
            return;
        }
        this.setState({ visible: true });
    }
    handleOk = () => {
        const { urlValue, username, password } = this.state;
        console.log(urlValue);
        this.setState({
            confirmLoading: true,
            errorMessage: ''
        });
        // setTimeout(() => {
        //     this.setState({
        //         visible: false,
        //         confirmLoading: false,
        //     });
        // }, 2000);


        addCustomRSSFeed(urlValue, username, password).then((data) => {
            console.dir(data);
            let customItunesData = data['customItunesData'];
            let rssFeedData = data['rssFeedData'];
            /// dispatch this to store FetchPodcastsAction {itunesId: result}
            let itunesId = customItunesData['collectionId'];
            // let storeData = {
            //     itunesId: rssFeedData
            // };
            // vm.fetchPodcastsActionFn(storeData);
            // console.dir('storeData : ' + storeData);
            // Navigator.of(context).pop();
            // vm.loadingCustomRSSActionFn(false);

            // parentCallback({
            //     'success': true,
            //     'customItunesData': customItunesData
            // });
            if (itunesId) {
                // let customItunesData = data['customItunesData'];
                this.props.fetchUserSubscribedPodcasts(this.props.userDetails['uid']);
                /// then proceed to the podcast() page
                this.setState({
                    confirmLoading: false,
                    visible: false
                });
                setCurrentPodcastData(customItunesData);
                // const podcastFeedUrl = urlValue;
                // const genres = customItunesData.genres && customItunesData.genres.join(',') || '';
                Router.push(podcastHrefUrl(itunesId), podcastSlugUrl(customItunesData.collectionName, itunesId));
                // Router.push(`/podcast?feed=${podcastFeedUrl}&PzID=${itunesId}&genres=${genres}`,
                //     `/podcast/${convertToSlug(cleanText(customItunesData.collectionName))}?feed=${podcastFeedUrl}&PzID=${itunesId}&genres=${genres}`);

                // Navigator.push(
                //     context,
                //     FadePageRoute(
                //         widget: podcast(
                //             itunesData: customItunesData,
                //             isCustomRSS: true,
                //         ),
                //     ));
            } else {
                this.setState({
                    confirmLoading: false,
                    errorMessage: 'Invalid rss url.'
                });
                // showDialog<void>(
                //   context: context,
                //   // barrierDismissible: false, // user must tap button!
                //   builder: (BuildContext context) {
                //     return AppAdaptiveAlert(
                //       title: 'Error',
                //       content: 'Invalid rss url.',
                //       okCallback: () {},
                //     );
                //   },
                // );
            }
            // }
            return;
        }).catch((err) => {
            // vm.loadingCustomRSSActionFn(false);
            // parentCallback({'success': true, 'error': err});
            this.setState({
                confirmLoading: false,
                errorMessage: 'Unknown error. Please try again.'
            });
            return;
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visible: false,
        });
    };

    onChange = (e) => {
        setIsTyping();
        this.setState({ urlValue: e.target.value });
    }

    render() {
        const { confirmLoading, visible, errorMessage, urlValue, needsAuth, username, password } = this.state;
        return (
            <div>
                <Tooltip title="Add custom RSS url">
                    <div style={{ cursor: 'pointer', fontSize: `${fixedSpacing('md')}`, textAlign: "center" }}
                        onClick={this._addCustomRSS}><PlusCircleOutlined /></div>
                </Tooltip>
                <Modal
                    title="Add custom RSS url"
                    visible={visible}
                    okText='Add'
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <Input style={{ marginBottom: `${spacing()}` }} value={urlValue} onChange={this.onChange} placeholder="RSS url" />
                    <br />
                    <div style={{ display: 'flex' }}>
                        <Checkbox checked={needsAuth} onChange={(e) => this.setState({ needsAuth: e.target.checked })}></Checkbox>
                        <p> &nbsp; &nbsp;Needs Authentication</p>
                    </div>
                    {needsAuth && <div>
                        <Input style={{ marginBottom: `${spacing()}` }} value={username} onChange={e => this.setState({ username: e.target.value })} placeholder="Username" />
                        <Input type='password' style={{ marginBottom: `${spacing()}` }} value={password} onChange={e => this.setState({ password: e.target.value })} placeholder="Password" />
                    </div>}
                    <br />
                    {errorMessage &&
                        // <Alert message={errorMessage} type="error" />
                        <AppAlertDiv message={errorMessage} type="error" />
                    }
                </Modal>
            </div>
        );
    }
}

AddCustomRSS.propTypes = {
    // loadingUserDetails: PropTypes.bool,
    userDetails: PropTypes.object,
    loadingState: PropTypes.object,
    fetchUserSubscribedPodcasts: PropTypes.func,
    // settingsState: PropTypes.object,
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        loadingState: state.loadingState,
        // settingsState: state.settingsState,
        // loadingUserDetails: state.loadingState.loadingUserDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //   toggleLeftBar: bindActionCreators(toggleLeftBar, dispatch)
        fetchUserSubscribedPodcasts: bindActionCreators(fetchUserSubscribedPodcasts, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomRSS);