import {
    CHANGE_SEARCH_TERM
}
    from '../actions/types';


export const initialState = {
    searchTerm: '',
};

export const searchReducer = (state = initialState, action) => {
    // console.dir(action);
    switch (action.type) {
        case CHANGE_SEARCH_TERM: {
            // const copy = Object.assign({}, state);
            // const leftBarOpen = !copy['leftBarOpen'];
            return Object.assign({}, state, { searchTerm: action.payload });
        }
        default:
            return state;
    }
};