import {
  FETCH_PODCASTS, FETCH_SIDE_PODCASTS, LATEST_EPISODES,
  FETCH_SUBSCRIBED_PODCASTS, SUBSCRIBE_PODCAST_SUCCESS, SUBSCRIBE_PODCAST_FAILURE,
  UNSUBSCRIBE_PODCAST_SUCCESS,
  FETCH_DB_PODCASTS, EDIT_DB_PODCASTS_SUSCRIBERS, ADD_SKIP_BEGINNING_ACTION,
  FETCH_YOUR_PODCAST_SETTINGS, UPDATE_YOUR_PODCAST_SETTINGS, UPDATE_CLEAR_BADGES, FETCH_PODCAST_EXTRA_DETAILS, UPDATE_PODCAST_EXTRA_DETAILS
  // LAST_PLAYED_EPISODE,
  // PERSONAL_VALUES_SUCCESS,
  // PERSONAL_VALUES_RATING_SUCCESS, PERSONAL_VALUES_RATING_DELETE,
  // DELETE_FROM_HISTORY
}
  from '../actions/types';
// import { initialState } from '../store';
// import { mergeDeep } from '../lib/utils';

// REDUCERS
export const podcastReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SUBSCRIBED_PODCASTS:
      return Object.assign({}, state, action.payload);
    case SUBSCRIBE_PODCAST_SUCCESS:
      // return Object.assign({}, state, { subscribedPodcasts: podcastsState(state.subscribedPodcasts, action) });
      // return [
      //   ...state,
      //   action.payload
      // ];
      // console.dir(Object.assign({}, state, action.payload));
      return Object.assign({}, state, action.payload);
    // case SUBSCRIBE_PODCAST_FAILURE:
    //   return Object.assign({}, state, action.payload);
    case UNSUBSCRIBE_PODCAST_SUCCESS: {
      // return Object.assign({}, state, { subscribedPodcasts: podcastsState(state.subscribedPodcasts, action) });
      // return state.filter((podcast) => podcast.id + '' !== action.payload + '')
      let copy = Object.assign({}, state);
      delete copy[`${action.payload}`];
      return copy;
    }
    case ADD_SKIP_BEGINNING_ACTION: {
      const specificPodcast = state[action.collectionId] || state[`${action.collectionId}`] || {};
      const returnMap1 = { ...specificPodcast, 'skipBeginningInSeconds': action.skipBeginningInSeconds };
      return { ...state, [`${action.collectionId}`]: returnMap1 };
    }
    default:
      return state;
  }
};

export const yourPodcastSettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_YOUR_PODCAST_SETTINGS:
      return Object.assign({}, state, action.payload);
    case UPDATE_YOUR_PODCAST_SETTINGS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const podcastExtraDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_PODCAST_EXTRA_DETAILS:
      return Object.assign({}, state, action.payload);
    case UPDATE_PODCAST_EXTRA_DETAILS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const clearBadgesReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLEAR_BADGES:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const getRequiredPodcastsFn = (podcastsObj, filter) => {
  switch (filter) {
    case 'top':
      return Object.values(podcastsObj).filter(podcast => podcast.topPodcast == true);
    case 'subscribed':
      return podcastsObj; //Object.values(podcastsObj).filter(podcast => podcast.subscribed == true);
    default:
      throw new Error(`Unknown filter ${filter}`)
  }
}

export const dbPodcastReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_DB_PODCASTS:
      return Object.assign({}, state, action.payload);
    // case EDIT_DB_PODCASTS_SUSCRIBERS: {
    //   const { itunesId, value } = action.payload;
    //   console.dir(state);
    //   console.dir(action.payload);
    //   return produce(state, draft => {
    //     // delete draft[`${action.payload}`]['subscribed'];
    //     if (state[itunesId] && state[itunesId]['subscribers']) {
    //       draft[itunesId].subscribers += value;
    //     } else {
    //       draft[itunesId] = { 'subscribers': 1 };
    //     }
    //   });
    // }
    default:
      return state;
  }
}
// export const getRequireddbPodcastsFn = (dbPodcastsObj, itunesId) => {
//   return dbPodcastsObj[`${itunesId}`];
// }

export const sidePodcastReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_SIDE_PODCASTS:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}

export const episodeReducer = (state = {}, action) => {
  switch (action.type) {
    case LATEST_EPISODES:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}