import {
    ADD_PODCAST_TO_TAG_ACTION,
    ADD_SEARCH_ALERTS,
    CREATE_PODCAST_TAGS_ACTION,
    DELETE_PODCAST_TAGS_ACTION,
    DELETE_SEARCH_ALERTS,
    EDIT_PODCAST_TAGS_ACTION,
    FETCH_FOLLOWED_TAGS,
    FETCH_PODCAST_TAGS,
    FETCH_SEARCH_ALERTS,
    FOLLOW_TAG,
    REMOVE_PODCAST_FROM_TAG_ACTION,
    UNFOLLOW_TAG
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const podcastTagReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_PODCAST_TAGS:
            return Object.assign({}, state, action.payload);
        case CREATE_PODCAST_TAGS_ACTION:
            return Object.assign({}, state, { [`${action.tagName}`]: action.collectionIdsMap || {} });
        case EDIT_PODCAST_TAGS_ACTION: {
            const copy = { ...state };
            const tagToChangeMap = state[`${action.oldTagName}`] || {};
            delete copy[action.oldTagName];
            copy[`${action.newTagName}`] = tagToChangeMap;
            // console.dir(copy);
            return copy;
        }
        case DELETE_PODCAST_TAGS_ACTION: {
            const copy = { ...state };
            delete copy[action.tagName];
            return copy;
        }
        case ADD_PODCAST_TO_TAG_ACTION: {
            const currentTag = state[`${action.tagName}`] || {};
            const return1 = { ...currentTag, ...action.collectionsIdsMap };
            return { ...state, [`${action.tagName}`]: return1 };
        }
        case REMOVE_PODCAST_FROM_TAG_ACTION: {
            const currentTag = state[`${action.tagName}`] || {};
            const copy = { ...currentTag };
            delete copy[`${action.collectionId}`];
            return { ...state, [`${action.tagName}`]: copy };
        }
        default:
            return state;
    }
};

export const searchAlertsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SEARCH_ALERTS:
            return Object.assign({}, state, action.payload);
        case ADD_SEARCH_ALERTS:
            return Object.assign({}, state, { [action.alertId]: action.payload });
        case DELETE_SEARCH_ALERTS: {
            const copy = { ...state };
            delete copy[action.alertId];
            return copy;
        }
        default:
            return state;
    }
};

export const followedPodcastTagReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_FOLLOWED_TAGS:
            return Object.assign({}, state, action.payload);
        case FOLLOW_TAG:
            return Object.assign({}, state, { [action.tagId]: action.payload });
        case UNFOLLOW_TAG: {
            const copy = { ...state };
            delete copy[action.tagId];
            return copy;
        }
        default:
            return state;
    }
};