import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, spacing, maxScreenSize } from '../../lib/theme';
import { Col, Row } from 'antd';

export default class InterestOption extends Component {
    render() {
        const { genreMap, selectOption, selectedGenreIds } = this.props;
        return (
            <div>
                <div onClick={selectOption} className='main-div' style={{
                    marginRight: `${spacing('lg')}`, marginBottom: '1em', cursor: 'pointer',
                    background: genreMap['color'], padding: '1em 1.5em', borderRadius: '8px', height: '108px'
                }}>
                    <Row>
                        <Col span={12}>
                            <FontAwesomeIcon style={{ color: 'white', fontSize: '1.75em', marginBottom: '4px' }} icon={genreMap['icon']} />
                        </Col>
                        <Col span={12} style={{ textAlign: 'center' }}>
                            {selectedGenreIds[genreMap['id']] &&
                                <FontAwesomeIcon style={{ color: 'white', fontSize: '1.5em', marginBottom: '4px' }} icon='check' />}
                        </Col>
                    </Row>
                    <p style={{ color: 'white', marginBottom: '0' }}>{genreMap['name']}</p>
                </div>
                <style jsx>
                    {`
                        .main-div {
                            width: 90%; // 140px;
                        }
                        @media ${maxScreenSize.laptop} {
                            .main-div {
                                width: 90%; // 100px;
                            }
                        }
                    `}
                </style>
            </div>
        )
    }
}

InterestOption.propTypes = {
    genreMap: PropTypes.object,
    selectedGenreIds: PropTypes.object,
    selectOption: PropTypes.func
}