import {
    FETCH_BLOG_LISTS_ACTION,
    FETCH_FEATURED_LISTS_ACTION
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const featuredListsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_FEATURED_LISTS_ACTION:
            return [...action.payload];
        default:
            return state;
    }
};
export const blogListsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_BLOG_LISTS_ACTION:
            return [...action.payload];
        // return Object.assign({}, state, { [action.guid]: action.bookmarkData });
        default:
            return state;
    }
};