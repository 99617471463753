export const colors = {
    'primary': '#4E7CFF', // 'hsla(225.9, 63.7%, 62.2%, 1)', // 'hsla(223.9, 63.7%, 62.2%, 1)', // '#3897f0', // 'hsla(223.9, 63.7%, 62.2%, 1)', // '#2cbac2',
    'primaryDim': 'hsla(223.9, 63.7%, 62.2%, 0.2)', // '#2cbac2',
    'secondary': '#fff',
    'third': '#F42A79', // 'hsla(337, 87%, 50%,1)' // '#cc317c' // '#cf202e',
    'danger': '#dc3545'
}

export const leftSideBar = '14vw';
export const rightSideBar = '18vw';
// export const mainMiddleBar = '68vw';
// export const topBar = 64;

export const mainLayoutColor = 'hsla(228, 55%, 98%, 1)';
export const mainLayoutColorDark = '#2d3142'; // '#151515';
export const bottomBarColor = 'hsla(223, 22%, 21%, 0.99)';

export const leftSideBarColor1 = 'hsla(222, 96%, 6%, 1)';
export const leftSideBarColor2 = 'hsla(259, 24%, 27%, 1)';
export const leftSideBarColor = '#f4f6f8'; // '#0c0f27'; // 'hsl(200,10%,93.3%)';  //'hsl(0,0%,93.3%)'; // 'hsla(223.9, 63.7%, 62.2%, 1)'; // 'lightgray';
export const rightSideBarColor = 'hsla(223, 22%, 97%, 0.99)'; // 'hsla(180, 12%, 90%, 1)'; // '#f4f6f8'; // '#fafafa'; // '#0c0f27'; // 'hsl(200,10%,93.3%)'; // 'hsla(223.9, 63.7%, 62.2%, 1)'; // 'lightgray';
export const rightSideBarColorDark = '#2d3142'; // '#151515';
export const leftSideBarText = '#e2e5e9'; // 'white'; // 'inherit'; // 'black';
export const rightSideBarText = 'hsla(198, 8%, 32%, 1)'; // 'inherit'; // 'black';

export const podcastEpBgColor = 'hsla(223.9, 63.7%, 62.2%, 0.2)'; //  'rgba(126,199,239,0.8)';
export const borderColor = '#e6e6e6';
export const borderColorDark = '#403c3e'; // 'gray';

export const largeTextColor = '#030028';
export const largeTextColorDark = 'white';
export const mediumTextColor = '#24232f';
export const mediumTextColorDark = '#d7e2ffbb';
export const primaryColorDark = '#030028';
export const primaryColorDarkDark = '#e0e0e0';
export const antdTextColor = 'rgba(0, 0, 0, 0.65)';
export const antdTextColorDark = 'rgba(255, 255, 255, 0.65)';
export const cardColor = 'white';
export const cardColorDark = '#252836'; // '#121212';
export const leftBarBgColor = '#122B44';
export const leftBarBgColorDark = '#2d3142'; //'#151515';

export const topBarColor = 'white';
export const topBarColorDark = '#252836'; // '#121212'; // '#151515';

export const minImageWidth = '120px';

// export const spacing = {
//     'low': '8px'
// }
export const screenSize = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1080px',
    laptopL: '1440px',
    laptopXL: '1840px',
    desktop: '2560px'
}
export const minScreenSize = {
    mobileS: `(min-width: ${screenSize.mobileS})`,
    mobileM: `(min-width: ${screenSize.mobileM})`,
    mobileL: `(min-width: ${screenSize.mobileL})`,
    tablet: `(min-width: ${screenSize.tablet})`,
    laptop: `(min-width: ${screenSize.laptop})`,
    laptopL: `(min-width: ${screenSize.laptopL})`,
    laptopXL: `(min-width: ${screenSize.laptopXL})`,
    desktop: `(min-width: ${screenSize.desktop})`,
    desktopL: `(min-width: ${screenSize.desktop})`
};
export const maxScreenSize = {
    mobileS: `(max-width: ${screenSize.mobileS})`,
    mobileM: `(max-width: ${screenSize.mobileM})`,
    mobileL: `(max-width: ${screenSize.mobileL})`,
    tablet: `(max-width: 767.98px)`,
    laptop: `(max-width: ${screenSize.laptop})`,
    laptopL: `(max-width: ${screenSize.laptopL})`,
    desktop: `(max-width: ${screenSize.desktop})`,
    desktopL: `(max-width: ${screenSize.desktop})`
};



// export function fontSize(size, media = 'md') {
export function fontSize(size) {
    const base = 1;
    const scaleRatio = 1.2;
    switch (size) {
        case 'sm':
            return (base / scaleRatio) + 'em';
        case 'md':
            return (base * scaleRatio) + 'em';
        case 'lg':
            return (base * scaleRatio * scaleRatio) + 'em';
        default:
            return base + 'em';
    }
}

export function spacing(size) {
    const base = 1;
    switch (size) {
        case 'xxs':
            return base * 0.25 + 'em';
        case 'xs':
            return base * 0.5 + 'em';
        case 'sm':
            return base * 0.75 + 'em';
        case 'md':
            return base * 1.25 + 'em';
        case 'lg':
            return base * 2 + 'em';
        case 'xl':
            return base * 3.25 + 'em';
        case 'xxl':
            return base * 5.25 + 'em';
        default:
            return base + 'em';
    }
}

export function fixedSpacing(size) {
    const base = 1;
    switch (size) {
        case 'xxs':
            return base * 0.25 + 'rem';
        case 'xs':
            return base * 0.5 + 'rem';
        case 'sm':
            return base * 0.75 + 'rem';
        case 'md':
            return base * 1.25 + 'rem';
        case 'lg':
            return base * 2 + 'rem';
        default:
            return base + 'rem';
    }
}

export function compSpacing(size) { // component spacing
    const base = 1;
    switch (size) {
        case 'xs':
            return base * 0.5 + 'em';
        case 'sm':
            return base * 0.75 + 'em';
        case 'md':
            return base * 1.25 + 'em';
        case 'lg':
            return base * 2 + 'em';
        case 'xl':
            return base * 3.25 + 'em';
        default:
            return base + 'em';
    }
}