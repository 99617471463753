import {
    TOGGLE_LEFT_BAR, TOGGLE_DARK_THEME, ADD_TOP_PADDING, FORWARD_SEEK_TIME, REWIND_SEEK_TIME, ENABLE_KEYBOARD_PLAYBACK
}
    from '../actions/types';

import {
    leftSideBarColor, rightSideBarColor, largeTextColor, mediumTextColor, topBarColor, primaryColorDark, antdTextColor, cardColor,
    leftSideBarText, rightSideBarText, podcastEpBgColor, borderColor, mainLayoutColor, leftBarBgColor,
    mainLayoutColorDark, rightSideBarColorDark, largeTextColorDark, mediumTextColorDark, topBarColorDark, primaryColorDarkDark,
    borderColorDark, antdTextColorDark, cardColorDark, leftBarBgColorDark
} from '../lib/theme';

export const initialState = {
    themeVal: 0,
    leftBarOpen: true,
    mainLayoutColor: mainLayoutColor,
    leftBarColor: leftSideBarColor,
    rightBarColor: rightSideBarColor,
    leftBarText: leftSideBarText,
    rightBarText: rightSideBarText,
    podcastEpBgColor: podcastEpBgColor,
    borderColor: borderColor,
    largeTextColor: largeTextColor,
    mediumTextColor: mediumTextColor,
    topBarColor: topBarColor,
    primaryColorDark: primaryColorDark,
    antdTextColor: antdTextColor,
    cardColor: cardColor,
    leftBarBgColor: leftBarBgColor,
    featuredBgColor: 'fcfbfc',
    topPaddingGlobal: 0,
    topBar: 64,
    forwardSeekTime: 15,
    rewindSeekTime: 15,
    enableKeyboardPlayback: false,
    inputBgColor: 'white'
};

export const settingsReducer = (state = initialState, action) => {
    // console.dir(action);
    switch (action.type) {
        case TOGGLE_LEFT_BAR: {
            const copy = Object.assign({}, state);
            const leftBarOpen = !copy['leftBarOpen'];
            return Object.assign({}, state, { leftBarOpen });
        }
        case ADD_TOP_PADDING: {
            const { topBar } = initialState;
            return Object.assign({}, state, { topPaddingGlobal: action.payload, topBar: topBar + action.payload });
        }
        case FORWARD_SEEK_TIME: {
            return Object.assign({}, state, { forwardSeekTime: action.payload });
        }
        case REWIND_SEEK_TIME: {
            return Object.assign({}, state, { rewindSeekTime: action.payload });
        }
        case ENABLE_KEYBOARD_PLAYBACK: {
            return Object.assign({}, state, { enableKeyboardPlayback: action.payload });
        }
        case TOGGLE_DARK_THEME: {
            console.dir(action);
            const isDarkTheme = action.payload == 1;
            return Object.assign({}, state,
                {
                    themeVal: isDarkTheme ? 1 : 0,
                    mainLayoutColor: isDarkTheme ? mainLayoutColorDark : mainLayoutColor,
                    rightBarColor: isDarkTheme ? rightSideBarColorDark : rightSideBarColor,
                    largeTextColor: isDarkTheme ? largeTextColorDark : largeTextColor,
                    mediumTextColor: isDarkTheme ? mediumTextColorDark : mediumTextColor,
                    topBarColor: isDarkTheme ? topBarColorDark : topBarColor,
                    primaryColorDark: isDarkTheme ? primaryColorDarkDark : primaryColorDark,
                    borderColor: isDarkTheme ? borderColorDark : borderColor,
                    antdTextColor: isDarkTheme ? antdTextColorDark : antdTextColor,
                    cardColor: isDarkTheme ? cardColorDark : cardColor,
                    leftBarBgColor: isDarkTheme ? leftBarBgColorDark : leftBarBgColor,
                    featuredBgColor: isDarkTheme ? '1d1f2a' : 'e3dadb',
                    inputBgColor: isDarkTheme ? cardColorDark : 'white',
                }
            );
        }
        default:
            return state;
    }
};