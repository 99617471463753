import {
    SET_USER_DETAILS,
    CREATE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    // CREATE_USER_FAILURE,
    SIGNIN_USER_SUCCESS,
    UPDATE_USER_IMAGE,
    USER_HAS_ADS,
    USER_DASHBOARD_PLAN,
    // SIGNIN_USER_FAILURE,
    // LOG_OUT_USER
}
    from '../actions/types';

export const userDetailsReducer = (state = null, action) => {
    // console.dir(state);
    switch (action.type) {
        case SET_USER_DETAILS:
            return Object.assign({}, state, action.payload);
        case CREATE_USER_SUCCESS:
            return Object.assign({}, state, action.payload);
        case UPDATE_USER_SUCCESS:
            return Object.assign({}, state, action.payload);
        // case CREATE_USER_FAILURE:
        //     return Object.assign({}, state, action.payload);
        case SIGNIN_USER_SUCCESS:
            return Object.assign({}, state, action.payload);
        case UPDATE_USER_IMAGE:
            return Object.assign({}, state, action.payload);
        case USER_HAS_ADS:
            return Object.assign({}, state, { 'hasAdHistory': true });
        case USER_DASHBOARD_PLAN:
            return Object.assign({}, state, { 'dashboardPlan': action.payload });
        // case SIGNIN_USER_FAILURE:
        //     return Object.assign({}, state, action.payload);
        // case LOG_OUT_USER:
        //     return null;
        // return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};