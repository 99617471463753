import {
    FETCH_QUEUE_MAIN_ACTION, ADD_TO_QUEUE_MAIN_ACTION, DELETE_FROM_QUEUE_MAIN_ACTION,
    DELETE_ALL_FROM_QUEUE_ACTION, EPISODE_ORDINALITY_CHANGED_QUEUE_ACTION, UPDATE_AUTO_QUEUE_MAIN_ACTION,
    AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION
}
    from '../actions/types';

export const manualQueueReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case FETCH_QUEUE_MAIN_ACTION:
            return Object.assign({}, state, action.payload);
        case ADD_TO_QUEUE_MAIN_ACTION: {
            const episodeNew = action.payload;
            return Object.assign({}, state, { [episodeNew.guid]: episodeNew });
        }
        case DELETE_FROM_QUEUE_MAIN_ACTION: {
            const copy = Object.assign({}, state);
            if (copy[action.payload])
                delete copy[action.payload];
            return copy; //Object.assign({}, state, action.payload);
        }
        case DELETE_ALL_FROM_QUEUE_ACTION: {
            const copy = Object.assign({}, state);
            Object.keys(copy).forEach(key => {
                delete copy[key];
            });
            return copy; //Object.assign({}, state, action.payload);
        }
        case EPISODE_ORDINALITY_CHANGED_QUEUE_ACTION: {
            const episodeGUID = action.episodeGUID;
            const newOrdinalityDate = action.newOrdinalityDate;
            const episode = state[episodeGUID];
            const episodeNew = { ...episode, ordinalityDate: newOrdinalityDate };
            return { ...state, [episodeGUID]: episodeNew };
        }
        default:
            return state;
    }
};

export const autoQueueReducer = (state = [], action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case UPDATE_AUTO_QUEUE_MAIN_ACTION:
            return action.payload;
        default:
            return state;
    }
};

export const autoQueuePlaylistIdNotifierReducer = (state = '', action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION:
            return action.payload;
        default:
            return state;
    }
};