import {
  FETCH_EPISODE_REVIEWS, ADD_LIKES_REVIEWS, REMOVE_LIKES_REVIEWS, ADD_REPORTED_REVIEWS, REMOVE_REPORTED_REVIEWS, ADD_TO_REVIEWS, UPDATE_REVIEWS, DELETE_FROM_REVIEWS,
  PIN_REVIEW, UNPIN_REVIEW
}
  from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const episodeReviewsReducer = (state = {}, action) => {
  // console.log('recent : ' + action.type);
  switch (action.type) {
    // case FETCH_EPISODE_REVIEWS: {
    //   return Object.assign({}, state, action.payload);
    // }
    // case ADD_LIKES_REVIEWS: {
    //   let reviewId = action.reviewId;
    //   let userUID = action.userUID;

    //   let return2 = state[`${reviewId}`]['likesArray'] ? [...state[`${reviewId}`]['likesArray'], userUID] : [userUID];
    //   let return1 = { ...state[`${reviewId}`], 'likesArray': return2 };
    //   console.dir(return1);
    //   return Object.assign({}, state, { [reviewId]: return1 });
    // }
    // case REMOVE_LIKES_REVIEWS: {
    //   let reviewId = action.reviewId;
    //   let userUID = action.userUID;

    //   let return2 = state[`${reviewId}`]['likesArray'] ? state[`${reviewId}`]['likesArray'].filter(uid => uid !== userUID) : [];
    //   let return1 = { ...state[`${reviewId}`], 'likesArray': return2 };
    //   console.dir(return1);
    //   return Object.assign({}, state, { [reviewId]: return1 });
    // }
    // case ADD_REPORTED_REVIEWS: {
    //   let reviewId = action.reviewId;
    //   let userUID = action.userUID;

    //   let return2 = state[`${reviewId}`]['reportedArray'] ? [...state[`${reviewId}`]['reportedArray'], userUID] : [userUID];
    //   let return1 = { ...state[`${reviewId}`], 'reportedArray': return2 };
    //   console.dir(return1);
    //   return Object.assign({}, state, { [reviewId]: return1 });
    // }
    // case REMOVE_REPORTED_REVIEWS: {
    //   let reviewId = action.reviewId;
    //   let userUID = action.userUID;

    //   let return2 = state[`${reviewId}`]['reportedArray'] ? state[`${reviewId}`]['reportedArray'].filter(uid => uid !== userUID) : [];
    //   let return1 = { ...state[`${reviewId}`], 'reportedArray': return2 };
    //   console.dir(return1);
    //   return Object.assign({}, state, { [reviewId]: return1 });
    // }
    // case PIN_REVIEW: {
    //   let reviewId = action.reviewId;
    //   let return1 = { ...state[`${reviewId}`], 'pinned': true };
    //   return Object.assign({}, state, { [reviewId]: return1 });
    // }
    // case UNPIN_REVIEW: {
    //   let reviewId = action.reviewId;
    //   let return1 = { ...state[`${reviewId}`], 'pinned': false };
    //   return Object.assign({}, state, { [reviewId]: return1 });
    // }
    
    default:
      return state;
  }
};