import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import { colors } from '../../lib/theme'

export default class SendMessage extends Component {
    render() {
        return (
            <div className='main'>
                <div className='bg'>
                    <a href="mailto:support@podurama.com">
                        <FontAwesomeIcon style={{ color: 'white', fontSize: '20px' }} icon='envelope' />
                    </a>
                </div>
                <style jsx>
                    {`
                .main {
                    position: fixed;
                    bottom: 32px;
                    right: 32px;
                    cursor: pointer;
                }
                .bg {
                    background: ${colors.primary};
                    border-radius: 50%;
                    padding: 12px;
                    box-shadow: 0 5px 40px 0 rgba(0,11,40,.54);
                }
            `}
                </style>
            </div>
        )
    }
}
