import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
var UAParser = require('ua-parser-js');

const loadIcon = <LoadingOutlined style={{ fontSize: '3em' }} spin />;

// const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
//Generic loading spinner.
class LoadingSpinner extends Component {
  // const LoadingSpinner = (props) => (

  constructor(props) {
    super(props);
    this.state = { isMacOS: false };
  }

  componentDidMount() {
    var parser = new UAParser();
    console.dir(parser.getResult());
    let info = parser.getResult();
    if (info.os) {
      let osName = info.os.name || '';
      if (osName.toLowerCase().includes('mac')) {
        this.setState({ isMacOS: true });
      }
    }
  }

  render() {
    const { isMacOS } = this.state;
    // const { color } = this.props;
    return (
      <>

        {!isMacOS &&
          <div className="center-spinner">
            {/* {props.message || 'Loading...'} */}
            <Spin style={{ marginTop: '15%', width: '100%' }} indicator={loadIcon} />
          </div>
        }
        {
          isMacOS &&
          <div style={{ marginTop: '15%', width: '100%' }}>
            <div className="spinner-global">
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
              <div className="bar4"></div>
              <div className="bar5"></div>
              <div className="bar6"></div>
              <div className="bar7"></div>
              <div className="bar8"></div>
              <div className="bar9"></div>
              <div className="bar10"></div>
              <div className="bar11"></div>
              <div className="bar12"></div>
            </div>
          </div>
        }
      </>
    )
  }
  // );
}

LoadingSpinner.propTypes = {
  message: PropTypes.string,
  // color: PropTypes.string
}

export default LoadingSpinner;