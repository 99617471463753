import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { podcastTypes } from '../../lib/utils'
import { Col, Row, Button } from 'antd';
import InterestOption from './InterestOption';
import { saveUserInterests } from '../../actions';

class Interests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGenreIds: { ...props.userInterests }, showError: false
        }
    }

    selectOption = (index) => {
        const { selectedGenreIds } = this.state;
        const genreId = podcastTypes[index].id;
        if (genreId in selectedGenreIds) {
            delete selectedGenreIds[genreId];
            this.setState({
                selectedGenreIds
            });
        } else {
            selectedGenreIds[genreId] = true;
            this.setState({
                selectedGenreIds
            });
        }
    }

    createInterestOptions = () => {
        const { selectedGenreIds } = this.state;
        let rows = [];
        for (let i = 1; i < podcastTypes.length; i = i + 3) {
            const row = <Row key={i}>
                <Col span={8}>
                    <InterestOption selectedGenreIds={selectedGenreIds} genreMap={podcastTypes[i]} selectOption={this.selectOption.bind(this, i)} />
                </Col>
                <Col span={8}>
                    <InterestOption selectedGenreIds={selectedGenreIds} genreMap={podcastTypes[i + 1]} selectOption={this.selectOption.bind(this, i + 1)} />
                </Col>
                <Col span={8}>
                    <InterestOption selectedGenreIds={selectedGenreIds} genreMap={podcastTypes[i + 2]} selectOption={this.selectOption.bind(this, i + 2)} />
                </Col>
            </Row>;

            rows.push(row);
        }
        return rows;
    }

    cancel = () => {
        this.props.handleClose();
    }

    handleOk = () => {
        const { isEdit } = this.props;
        const { selectedGenreIds } = this.state;
        console.dir(Object.keys(selectedGenreIds));
        if (Object.keys(selectedGenreIds).length < 2) {
            this.setState({ showError: true });
            return;
        }
        if (isEdit) {
            this.props.saveUserInterests(selectedGenreIds);
        } else {
            // handled in parent
        }
        this.props.handleClose(selectedGenreIds);
    }

    render() {
        const { isEdit } = this.props;
        const { selectedGenreIds, showError } = this.state;
        console.dir(selectedGenreIds);
        return (
            <div style={{ textAlign: 'left' }}>
                <Row>
                    <Col md={14}>
                        <h4>What are your interests&#63;</h4>
                    </Col>
                    <Col md={5}>
                        {isEdit ? <Button onClick={this.cancel}>Cancel</Button> : ' '}
                    </Col>
                    <Col md={5}>
                        <Button type='primary' style={{ minWidth: '64px' }} onClick={this.handleOk}>Ok</Button>
                    </Col>
                </Row>
                <p style={{ color: showError && 'red' }}>Please pick atleast two.</p>
                {this.createInterestOptions()}
            </div>
        )
    }
}

Interests.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    userDetails: PropTypes.object,
    userInterests: PropTypes.object,
    loadingState: PropTypes.object,
    fetchError: PropTypes.object,
    saveUserInterests: PropTypes.func,
    handleClose: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        loadingState: state.loadingState,
        userInterests: state.userInterests,
        fetchError: state.errorsState,
    }
}

// export default withRedux(initStore, null, null)(Personal)
const mapDispatchToProps = (dispatch) => {
    return {
        saveUserInterests: bindActionCreators(saveUserInterests, dispatch),
        // getEpisodeReviews: bindActionCreators(getEpisodeReviews, dispatch),
        // getMoreEpisodeReviews: bindActionCreators(getMoreEpisodeReviews, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Interests);