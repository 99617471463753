import {
  FETCH_PLAYLISTS_MAIN, ADD_TO_PLAYLISTS_MAIN, DELETE_FROM_PLAYLISTS_MAIN, UPDATE_PLAYLISTS_MAIN, DELETE_PLAYLISTS_MAIN,
  DELETE_ALL_FROM_PLAYLISTS_MAIN, EPISODE_ORDINALITY_CHANGED, PLAYLIST_SORT_ORDER_CHANGED
}
  from '../actions/types';
// import { editPlaylist } from '../actions';
import { mergeDeep } from '../lib/utils';
// import { initialState } from '../store';

// const editPlaylist = (state = [], action) => {

// }

// REDUCER containing all the playlist episodes
export const playlistMainReducer = (state = {}, action) => {
  // console.log('recent : ' + action.type);
  switch (action.type) {
    case FETCH_PLAYLISTS_MAIN:
      return Object.assign({}, state, action.payload);
    case ADD_TO_PLAYLISTS_MAIN: {
      const playlistMainObj = action.payload;
      const currentFirestoreId = Object.keys(playlistMainObj) && Object.keys(playlistMainObj)[0];
      if (typeof state[`${currentFirestoreId}`] !== 'undefined') {
        return mergeDeep(state, action.payload);
      } else {
        return state;
      }
    }
    case EPISODE_ORDINALITY_CHANGED: {
      const firestoreId = action.firestoreId;
      const episodeGUID = action.episodeGUID;
      const newOrdinalityDate = action.newOrdinalityDate;
      const playlist = state[firestoreId];
      const episodes = playlist.episodes;
      const episode = episodes[episodeGUID];

      const episodeNew = { ...episode, 'ordinalityDate': newOrdinalityDate };
      const episodesNew = { ...episodes, [`${episodeGUID}`]: episodeNew };
      const playlistNew = { ...playlist, 'episodes': episodesNew };

      return { ...state, [firestoreId]: playlistNew };
    }
    case PLAYLIST_SORT_ORDER_CHANGED: {
      const firestoreId = action.firestoreId;
      const sortOrder = action.sortOrder;
      const playlist = state[firestoreId];
      const playlistNew = { ...playlist, 'sortOrder': sortOrder };

      return { ...state, [firestoreId]: playlistNew };
    }
    case UPDATE_PLAYLISTS_MAIN:
      return mergeDeep(state, action.payload);
    case DELETE_FROM_PLAYLISTS_MAIN:
      // episodeId, firestoreId
      var copy = Object.assign({}, state);
      var data = action.payload;
      var episodeId = data['episodeId'];
      var firestoreId = data['firestoreId'];
      if (copy[firestoreId]) {
        var episodes = copy[firestoreId].episodes || {};
        if (episodes[episodeId])
          delete episodes[episodeId];
      }
      return copy; //Object.assign({}, state, action.payload);
    case DELETE_PLAYLISTS_MAIN: {
      let copy1 = Object.assign({}, state);
      if (copy1[action.payload])
        delete copy1[action.payload];
      return copy1; //Object.assign({}, state, action.payload);
    }
    case DELETE_ALL_FROM_PLAYLISTS_MAIN: {
      let firestoreId = action.payload;
      let copy = Object.assign({}, state);
      if (copy[firestoreId]) {
        // let episodes = copy[firestoreId].episodes || {};
        delete copy[firestoreId]['episodes'];
      }
      return copy; //Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
};