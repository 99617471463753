import {
  UPDATE_AUDIO_INFO, PLAY_AUDIO
}
  from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const audioReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AUDIO_INFO:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export const audioPlayReducer = (state = {}, action) => {
  switch (action.type) {
    case PLAY_AUDIO: {
      let obj = action.payload;
      let newObj = {};
      if (obj.playAudio != null) {
        newObj['playAudio'] = obj.playAudio;
      }
      if (obj.currentEpisodeId != null) {
        newObj['currentEpisodeId'] = obj.currentEpisodeId;
      }
      if (obj.loadingAudio != null) {
        newObj['loadingAudio'] = obj.loadingAudio;
      }
      if (obj.maxDuration != null) {
        newObj['maxDuration'] = obj.maxDuration;
      }
      return Object.assign({}, state, newObj);
    }
    default:
      return state;
  }
};