import React from 'react';
import PropTypes from 'prop-types';
// import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppCustomLink from '../Common/AppCustomLink';
// import Link from 'next/link';
import { spacing, fixedSpacing } from '../../lib/theme';
import { getPodcastImgSize, convertToSlug, cleanText, cleanGenres, podcastSlugUrl, podcastHrefUrl, setCurrentPodcastData } from '../../lib/utils';
import { Img } from 'react-image';

import { Tooltip } from 'antd';

class PodcastSmall extends React.Component {
  // loadPage = () => {
  //   message.loading('Loading...', 0);
  //   // Dismiss manually and asynchronously
  //   // setTimeout(hide, 2500);
  // }

  // componentWillUnmount() {
  //   message.destroy();
  // }

  setPodcast = () => {
    const { podcast } = this.props;
    setCurrentPodcastData(podcast);
  }

  // const podcast = (props) => {
  render() {
    const { podcast } = this.props;

    const itunesRawId = podcast.collectionId || '';
    const podcastFeedUrl = podcast.feedUrl && podcast.feedUrl.split('?limit')[0];
    let cleanedGenres = cleanGenres(podcast.genres);
    let genres = cleanedGenres.join(',');
    // return (<></>)
    return (
      <div className='post-item'>
        <AppCustomLink as={podcastSlugUrl(podcast.collectionName, itunesRawId)}
          href={podcastHrefUrl(itunesRawId)} >
          <a>
            {/* <div className='post-item' onClick={this.props.onClick}> */}
            <div className='details' onClick={this.setPodcast}>
              {/* <Tooltip title={podcast.collectionName}> */}
              {/* <img src={getPodcastImgSize(podcast.artworkUrl600, 'sm')} alt={`Image of ${podcast.collectionName} podcast`} /> */}
              <div className='image-container-small'>
                <Img style={{}}
                  src={[getPodcastImgSize(podcast.artworkUrl600, 'sm')]}
                  alt={`${podcast.collectionName || ''} podcast`}
                  loader={<div className='load-image'></div>} />
              </div>
              {/* <h4 className='title'>{podcast.collectionName}</h4> */}
              {/* </Tooltip> */}
              {/* Tags - {podcast.genres !== undefined ? podcast.genres.map((genre) => (
          <p> {genre} </p>
        )) : ''} */}
            </div>
          </a>
        </AppCustomLink>
        <style jsx>
          {`
            :global(.image-container-small img), load-image {
              min-height: 60px;
              width: 100%;
              // image-rendering: -moz-crisp-edges;
              image-rendering: -o-crisp-edges;
              // image-rendering: -webkit-optimize-contrast;
              // image-rendering: auto; // crisp-edges;
              // -ms-interpolation-mode: nearest-neighbor;
            }
           .post-item{
              cursor: pointer;
              display: inline-block;
              width: 33%;
           }
        `}
        </style>
      </div>
    )
  }
}

PodcastSmall.propTypes = {
  // userDetails: PropTypes.object,
  podcast: PropTypes.object,
  // showSubscribe: PropTypes.bool,
  // fromSearch: PropTypes.bool,
}

// export default podcast;
// const mapDispatchToProps = (dispatch) => {
//   return {
//   }
// };

export default connect(null, null)(PodcastSmall)