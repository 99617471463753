import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Modal, notification } from 'antd';
import PlaylistForm from './PlaylistForm';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { spacing } from '../../lib/theme';
import { getIsPremiumUser } from '../../lib/fileUtils';
import { playlistPremiumLimit } from '../../lib/utils';

class NewPlaylist extends Component {
    state = {
        visible: false,
        confirmLoading: false,
    }
    showModal = () => {
        const { userDetails, userPlaylists } = this.props;
        if (!userDetails || !userDetails.uid) {
            message.warning('Please sign in to create a playlist.');
            return;
        }
        if (!getIsPremiumUser() && Object.keys(userPlaylists).length >= playlistPremiumLimit) {
            notification.info({
                // duration: 0,
                message: 'Please move to premium version to create more than ' +
                    playlistPremiumLimit +
                    ' playlists',
            });
            return;
        }
        this.setState({
            visible: true,
        });
    }

    handleOk = (loading, playlistInfo = {}) => {
        console.log('loading : ' + loading);
        this.setState({
            confirmLoading: loading,
        });
        // if (typeof this.props.callOrigParent !== 'undefined') {
        //     this.props.callOrigParent(playlistInfo);
        // }
        if (!loading) {
            console.dir(playlistInfo);
            setTimeout(() => {
                this.setState({
                    visible: false,
                });
            }, 100);
        }
    }

    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            visible: false,
        });
    }
    render() {
        const { podcastDetails, edit, display } = this.props;
        console.log(edit);
        const { visible, confirmLoading } = this.state;
        return (
            <div>
                <Modal
                    title={<h4 className='mb-0'>Playlist</h4>}
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <PlaylistForm podcastDetails={podcastDetails} callBackParent={this.handleOk} edit={edit} />
                </Modal>
                {!edit && display !== 'btn' && <p style={{ padding: `${spacing('xs')} ${spacing()}`, marginBottom: 0 }} onClick={this.showModal}>New playlist</p>}
                {!edit && display === 'btn' && <Button type='primary' style={{ boxShadow: '0 3px 3px -1px rgba(24,24,23,1)', width: '100%', borderRadius: '8px' }} onClick={this.showModal}>New Playlist</Button>}
                {edit && <p style={{ padding: '2px 12px', marginBottom: 0 }} onClick={this.showModal}>Edit playlist Info</p>}
            </div>
        )
    }
}

NewPlaylist.propTypes = {
    edit: PropTypes.object,
    display: PropTypes.string,
    userDetails: PropTypes.object,
    podcastDetails: PropTypes.object,
    userPlaylists: PropTypes.object,
    // callOrigParent: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        userPlaylists: state.userPlaylists,
    }
}
export default connect(mapStateToProps, null)(NewPlaylist)
