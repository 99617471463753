import {
    FETCH_RELATED_PRODUCTS_ACTION, FETCH_SIMILAR_PODCASTS_ACTION
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const relatedProductsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_RELATED_PRODUCTS_ACTION:
            return Object.assign({}, state, { [action.key]: action.data });
        default:
            return state;
    }
}

export const relatedPodcastsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SIMILAR_PODCASTS_ACTION:
            return Object.assign({}, state, { [action.collectionId]: action.data });
        default:
            return state;
    }
}