import { firestore } from '../lib/db';
import { cleanFirebaseId, firestoreApi, getUUID, podcastTypes, serverApiEurope } from '../lib/utils';
import { createReviewId } from '../lib/reviewUtils';
import { tokenFetch } from '../lib/tokenUtils';
import { logLikeReview } from '../lib/analyticsEvents';
import {
    FETCH_RELATED_PRODUCTS_ACTION, FETCH_SIMILAR_PODCASTS_ACTION
}
    from './types';
import fetch from 'isomorphic-unfetch';
import FireStoreParser from 'firestore-parser';
import { itunesByCollectionId } from '../lib/searchItunes';
import { getCountForRelated } from '../lib/fileUtils';
import { Timestamp, collection, query, where, getDoc, doc, setDoc } from "firebase/firestore/lite";


// ThunkAction < AppState > fetchRelatedProducts(String docId,
//     { Map podcastData, String type = PODCASTS }) {
//     return (Store < AppState > store) async {
export const fetchRelatedProducts = (docId, podcastData, type = 'PODCASTS') => async (dispatch, getState) => {
    try {
        // const docData = {};
        // print('docId 0 : ' + docId);
        // Dio dio = new Dio();
        // dio.interceptors..add(UrlCache(onlineCacheFetchHours: 12));

        let genreIdNeededArray = [];
        if (podcastData) {
            /// get main genre from data
            const genres = podcastData['genres'] || [];
            console.dir(genres);
            for (const genreMap of podcastTypes) {
                // console.dir(genreMap['name']);
                if (genres.includes(genreMap['name'])) {
                    genreIdNeededArray.push(genreMap['id']);
                    // break;
                }
            }
            console.dir('genreIdNeeded 1 : ' + genreIdNeededArray);
        }

        let dataNeededFin = [];
        // console.dir(podcastData);

        const futures = [];
        const res = fetch(`${firestoreApi}related/${docId}`)
            .catch((onError) => null);
        futures.push(res);

        for (const genreIdNeeded of genreIdNeededArray) {
            const res2 = fetch(`${firestoreApi}related/0_${genreIdNeeded}`)
                .catch((onError) => null);
            futures.push(res2);
        }

        const responses = await Promise.all(futures);
        let ind = 1;
        for (const res of responses) {
            try {
                console.dir(res);
                const result = await res.json();
                const parsedResult = FireStoreParser(result);
                const docData = parsedResult?.fields || {};
                // print('articleData : ' + docData.toString());
                const dataNeeded = docData['data'] || {};
                if (ind == 1) {
                    Object.keys(dataNeeded).forEach(key => {
                        dataNeededFin.push({ ...dataNeeded[key], key });
                    });
                } else {
                    const sortedKeys = Object.keys(dataNeeded).sort((k1, k2) => (dataNeeded[k1]['index'] || 0) - (dataNeeded[k2]['index'] || 0));
                    sortedKeys.forEach(key => {
                        const alreadyExists = dataNeededFin.findIndex(el => el.key == key);
                        if (alreadyExists < 0 && dataNeededFin.length < getCountForRelated()) {
                            dataNeededFin.push({ ...dataNeeded[key], key });
                        }
                    });
                }
                ind++;
                // dataNeededFin = { ...dataNeededFin, ...dataNeeded };
            } catch (errLoc) {
                console.error(errLoc);
            }
        }
        console.dir(dataNeededFin);

        // return articleData;
        if (type == 'PODCASTS') {
            // store.dispatch(FetchRelatedProductsAction(docId, dataNeededFin));
            dispatch({
                'type': FETCH_RELATED_PRODUCTS_ACTION,
                'key': `${docId}`,
                'data': dataNeededFin
            });
        }
    } catch (err) {
        console.error(err);
        return;
    }
}

export const fetchRelatedPodcastsFromId = (collectionId) => async (dispatch, getState) => {
    getDoc(doc(firestore, `podcasts`, `${collectionId}`))
        .then(doc => {
            if (doc.exists()) {
                dispatch(fetchRelatedPodcasts(doc.data()));
            }
        })
}

// ThunkAction<AppState> fetchRelatedPodcasts(Map podcastData) {
//   return (Store<AppState> store) async {
export const fetchRelatedPodcasts = (podcastData) => async (dispatch, getState) => {
    if (!podcastData || !podcastData['collectionId']) {
        return;
    }
    const collectionId = podcastData['collectionId'];
    // final Map < String, List < Podcast >> similarPodcastsState =
    // similarPodcastsSelector(store.state);
    const similarPodcastsState = getState().similarPodcasts;
    if (`${collectionId}` in similarPodcastsState) {
        return;
    }

    const relatedData = podcastData['related'] || {};
    const relatedProData = podcastData['relatedPro'] || {};
    let lookUpIds = '';
    const proDataLength = Object.keys(relatedProData).length;
    Object.keys(relatedProData).forEach((collectionId) => {
        lookUpIds += `${collectionId},`;
    });
    const relatedDataKeys = Object.keys(relatedData);
    const maxNeeded = 6;
    for (let i = 0;
        i < Math.min(relatedDataKeys.length, maxNeeded - proDataLength);
        i++) {
        if (!lookUpIds.includes(`${relatedDataKeys[i]}`)) {
            lookUpIds += `${relatedDataKeys[i]},`;
        }
    }
    console.dir(lookUpIds);
    if (lookUpIds) {
        const queryString = itunesByCollectionId(lookUpIds);
        // 'https://itunes.apple.com/lookup?id=${lookUpIds}';
        try {
            // Dio dio = new Dio();
            // dio.interceptors..add(UrlCache(onlineCacheFetchHours: 12));
            const res = await fetch(queryString);
            // http.Response response = await http.get(
            //   Uri.encodeFull(queryString),
            // );
            const result = await res.json();
            if ('resultCount' in result && result['resultCount'] > 0) {
                if ('results' in result && result['results'].length > 0) {
                    const podcastsFin = result['results'];
                    // await utils.parsePodcastsJsonCompute(result['results']);
                    if (proDataLength < 1) {
                        podcastsFin.sort(() => .5 - Math.random());
                    }
                    // utils.printLog('podcastsFin: ' + podcastsFin.toString());
                    // store.dispatch(
                    //     FetchSimilarPodcastsAction('${collectionId}', podcastsFin));
                    console.dir(podcastsFin);
                    dispatch({
                        'type': FETCH_SIMILAR_PODCASTS_ACTION,
                        'collectionId': `${collectionId}`,
                        'data': podcastsFin
                    });
                }
            }
        } catch (e) {
            console.error(e);
        }
    }
    // utils.printLog('lookUpIds: ' + lookUpIds);
}
