import { Button, message, notification } from 'antd';
import React, { Component } from 'react'
import { getMonthCount, serverApiEurope } from '../../lib/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logPremiumClick } from '../../lib/analyticsEvents';

class ClickPremium extends Component {


    clickPremium = () => {
        // const { options } = this.state;
        const { isOffer, userDetails, option, email, type, hasTrial } = this.props;
        if ((!userDetails || !userDetails['uid']) && !email) {
            notification.info({
                message: 'Please sign in or register to purchase premium offer.',
                description: '',
                duration: null
            });
            return;
        }
        logPremiumClick(type == 'gift' ? 'gift-cards' : 'premium', option.id);
        // const option = options[selectedIndex];
        const postObj = {
            "price_id": option.id,
            'term': getMonthCount(option),
            'email': email,
            'success_url': type == 'gift' ? 'gift-cards' : '',
            'cancel_url': type == 'premiumSocial' ? 'premium-offer-app' : '',
            'oneTime': isOffer || getMonthCount(option) > 24,
            'hasTrial': hasTrial ?? false,
            'type': type || 'none'
        };
        console.dir(postObj);
        message.info('Loading...', 2.5);
        const optPOST = {                //http header
            'method': 'post',
            'contentType': 'application/json',
            'muteHttpExceptions': true,
            'body': JSON.stringify(postObj)
        };
        console.dir(optPOST);
        console.dir(option);
        fetch(`${serverApiEurope}stripeCheckoutSession`, optPOST).then(async (dataOrig) => {
            const data = await dataOrig.json();
            console.dir(data);
            if (data.session_url) {
                window.location.href = data.session_url;
            } else {
                notification.error({
                    message: 'There was an error opening payment url. Please try again.',
                    description: '',
                    duration: null
                });
            }
        }).catch(err => {
            notification.error({
                message: 'There was an error opening payment options. Please try again.',
                description: '',
                duration: null
            });
        })
    }

    render() {
        const { email, type, userDetails, hasTrial } = this.props;
        const isDashboard = type == 'dashboard';
        return (
            <div>
                {(type == 'gift') ?
                    <Button type="primary" className={`third-button`} style={{ width: '200px', height: '40px', borderRadius: '8px' }} onClick={this.clickPremium}>
                        {(userDetails && userDetails.uid) ? 'Purchase' : (email && email.includes('@')) ? 'Proceed' : 'Purchase as Guest'} <FontAwesomeIcon icon={'arrow-right'}
                            style={{ marginLeft: '8px' }} />
                    </Button>
                    :
                    <Button className={`third-button`}
                        style={{ background: '#E03145', width: isDashboard ? '100px' : '200px', height: '40px', borderRadius: '8px' }} onClick={this.clickPremium}>
                        {isDashboard ? 'Subscribe' : (hasTrial ? 'Start 7 Day Free Trial' : 'Continue')} {!isDashboard && <FontAwesomeIcon icon={'arrow-right'}
                            style={{ marginLeft: '8px' }} />}
                    </Button>}
            </div>
        )
    }
}

ClickPremium.propTypes = {
    // productId: PropTypes.string,
    // initIndex: PropTypes.number,
    isOffer: PropTypes.bool,
    email: PropTypes.string,
    type: PropTypes.string,
    hasTrial: PropTypes.bool,
    // subtitle: PropTypes.string,
    userDetails: PropTypes.object,
    option: PropTypes.object,
}
const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        // loadingState: state.loadingState,
    }
}
export default connect(mapStateToProps, null)(ClickPremium);
