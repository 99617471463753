import React, { Component } from 'react'
import { CloseCircleOutlined, InfoCircleFilled } from '@ant-design/icons';
import { colors } from '../../lib/theme';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class AppAlertDiv extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        }
    }

    onClose = () => {
        this.setState({ visible: false });
    }

    render() {
        const { message, type, showIcon, closable, isHtml, settingsState } = this.props;
        const { visible } = this.state;
        if (!visible) {
            return <></>
        }
        return (
            <div>
                <div className='main'>
                    {showIcon && (type == 'error' ? <></> : <InfoCircleFilled style={{ color: `${colors.primary}` }} />)}&nbsp;&nbsp;
                    {isHtml ? <div style={{ display: 'inline-block' }} dangerouslySetInnerHTML={{ __html: message }}></div> : message}
                    {closable && <div onClick={this.onClose} className='close'><CloseCircleOutlined /></div>}
                </div>
                <style jsx>
                    {`
                        .main {
                            background: ${type == 'error' ? '#fff2f0' : 'rgba(97, 130, 220, 0.2)'};
                            border: 1px solid ${type == 'error' ? '#ffccc7' : 'rgb(78, 124, 255)'};
                            padding: 8px 1em; 
                            margin: 1em 0; 
                            position: relative;
                            color: ${settingsState.primaryColorDark};
                        }
                        .close {
                            position: absolute;
                            top: 0;
                            right: 2px;
                        }
                    `}
                </style>
            </div >
        )
    }
}

AppAlertDiv.propTypes = {
    message: PropTypes.any,
    type: PropTypes.any,
    isHtml: PropTypes.bool,
    showIcon: PropTypes.any,
    closable: PropTypes.any,
    settingsState: PropTypes.any,
}

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsState,
    // searchState: state.searchState
  }
}

export default connect(mapStateToProps, null)(AppAlertDiv);
