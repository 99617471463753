import {
    FETCH_PLAYLISTS_SUCCESS, ADD_PLAYLIST_SUCCESS, EDIT_PLAYLIST_SUCCESS, DELETE_PLAYLIST_SUCCESS,
    ADD_TO_PLAYLIST_SUCCESS, ADD_GUID_TO_PLAYLIST_MAIN, DELETE_GUID_FROM_PLAYLIST_MAIN,
    DELETE_ALL_FROM_PLAYLISTS_MAIN,
    FOLLOW_PLAYLIST,
    UNFOLLOW_PLAYLIST,
    FETCH_FOLLOWED_PLAYLISTS,
    COLLABORATIVE_PLAYLIST,
    UNCOLLABORATIVE_PLAYLIST
}
    from '../actions/types';
import { mergeDeep } from '../lib/utils';

// reducer with playlist basic info like name, description etc without episodes(which is in playlistMainReducer)
export const playlistReducer = (state = {}, action) => {
    // console.log(action);
    switch (action.type) {
        case FETCH_PLAYLISTS_SUCCESS: {
            return Object.assign({}, state, action.payload);
        }
        case ADD_PLAYLIST_SUCCESS: {
            return Object.assign({}, state, action.payload);
        }
        case ADD_GUID_TO_PLAYLIST_MAIN: {
            let playlistId = action.firestoreId;
            let guid = action.guid;
            // return Object.assign({}, state, action.payload);
            // console.dir(state);
            // console.dir(playlistId);
            if (state[playlistId] && state[playlistId]['guidEpisodeArray']) {
                let returnTest = state[playlistId]['guidEpisodeArray'].concat(guid);
                let returnTest2 = { ...state[playlistId], 'guidEpisodeArray': returnTest };
                let returnTest3 = Object.assign({}, state, { [playlistId]: returnTest2 });
                return returnTest3;
            } else {
                // print('playlistId reducer returnMap 1 ');
                let return1 = { ...state[playlistId], 'guidEpisodeArray': [guid] };
                let returnMap = Object.assign({}, state, { [playlistId]: return1 });
                return returnMap;
            }
        }
        case DELETE_GUID_FROM_PLAYLIST_MAIN: {
            let playlistId = action.firestoreId;
            let guid = action.guid;
            if (state[playlistId]['guidEpisodeArray']) {
                let returnGUIDArray = state[playlistId]['guidEpisodeArray']
                    .filter((guidEl) => guidEl != guid);
                let returnTest2 = { ...state[playlistId], 'guidEpisodeArray': returnGUIDArray };
                let returnTest3 = Object.assign({}, state, { [playlistId]: returnTest2 });
                return returnTest3;
            }
            return state;
        }
        case DELETE_ALL_FROM_PLAYLISTS_MAIN: {
            let firestoreId = action.payload;
            let playlist = state[firestoreId];
            let copy = Object.assign({}, playlist);
            if (copy['guidEpisodeArray']) {
                delete copy['guidEpisodeArray'];
                delete copy['podcastsSuperset'];
            }
            return { ...state, [firestoreId]: copy };
        }
        case EDIT_PLAYLIST_SUCCESS:
            // return mergeDeep(state, action.payload);
            // console.dir(action.payload);
            return mergeDeep(state, action.payload);
        case DELETE_PLAYLIST_SUCCESS: {
            const copy = Object.assign({}, state);
            delete copy[action.payload];
            return copy;
        }
        case COLLABORATIVE_PLAYLIST: {
            const currentPlaylist = state[action.playlistId] || {};
            const collaborators = currentPlaylist['collaborators'] || {};
            const return1 = { ...currentPlaylist, collaborators: { ...collaborators, [action.userUID]: true } };
            return { ...state, [action.playlistId]: return1 };
        }
        case UNCOLLABORATIVE_PLAYLIST: {
            const currentPlaylist = state[action.playlistId] || {};
            const collaborators = currentPlaylist['collaborators'] || {};
            const copy = Object.assign({}, collaborators);
            delete copy[action.userUID];
            const return1 = { ...currentPlaylist, collaborators: copy };
            return { ...state, [action.playlistId]: return1 };
        }
        // return state.filter((playlist) => new String(playlist.id).valueOf() !== new String(action.payload).valueOf());
        // case ADD_TO_PLAYLIST_SUCCESS:
        //     return state; //Object.assign({}, state, { episodes: playlistsState(state.episodes, action) });
        default:
            return state;
    }
};

export const followedPlaylistReducer = (state = {}, action) => {
    // console.log(action);
    switch (action.type) {
        case FETCH_FOLLOWED_PLAYLISTS:
            return Object.assign({}, state, action.payload);
        case FOLLOW_PLAYLIST:
            return Object.assign({}, state, { [`${action.firestoreId}`]: true });
        case UNFOLLOW_PLAYLIST: {
            let playlistId = action.firestoreId;
            const copy = { ...state };
            delete copy[playlistId];
            return copy;
        }
        default:
            return state;
    }
}