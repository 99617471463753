import { analytics } from './db';
import { logEvent, setCurrentScreen, setUserId, setUserProperties } from "firebase/analytics";
// const analytics = firebase.analytics();
import * as gtag from './gtag';

export async function logEventGeneric(eventName, eventParameters = {}, logBranch = false) {
    try {
        await logEvent(
            analytics,
            eventName, // 'test_event',
            eventParameters
            // parameters: <String, dynamic>{
            //   'string': 'string',
            //   'int': 42,
            //   'long': 12345678910,
            //   'double': 42.0,
            //   'bool': true,
            // },
        );
    } catch (err) { console.dir(err) }
    if (logBranch) {
        try {
            gtag.logEvent(eventName, eventParameters)
        } catch (err) { console.dir(err) }
        // const BranchSDK = (await import('branch-sdk')).default;
        // try {
        //     await BranchSDK.logEvent(
        //         eventName, // 'test_event',
        //         eventParameters,
        //         undefined,
        //         undefined,
        //         function (err) { console.dir(err); }
        //     );
        //     console.dir('event logged');
        // } catch (err) { console.dir(err) }
    }
}

export async function setUserIdFn(userId) {
    await setUserId(analytics, userId);
}

export async function setUserProperty(propertyName, propertyValue) {
    await setUserProperties(analytics, { [propertyName]: propertyValue });
}

// export async function logAppOpen() {
//     await firebase.analytics().logAppOpen();
// }

export async function logLogin() {
    await logEventGeneric('login', {}, true);
}

export async function logSignUp(signUpMethod) {
    await logEventGeneric('sign_up_web', { 'method': signUpMethod }, true);
}

// export async function logDesktopAppDownloadStart() {
//     await analytics.logEventGeneric('desktop-app-download-start');
// }
// export async function logDesktopAppDownloadValid() {
//     await analytics.logEventGeneric('desktop-app-download-valid');
// }
// export async function logDesktopAppDownloadSuccess() {
//     await analytics.logEventGeneric('desktop-app-download-success');
// }

/// id is collectionId or episode guid
/// contentType is episode or podcast
/// method is facebook, google etc
export async function logShare(
    id, contentType, method) {
    await logEventGeneric('share', {
        'content_type': contentType, 'item_id': id, 'method': method
    }, true);
}

export async function logSearch(searchTerm) {
    await logEventGeneric('search', {
        'search_term': searchTerm
    }, true
    );
}

export async function logChatBotOpen(type) {
    // await analytics.logEvent(name: 'searchGenres', // 'test_event',
    //     parameters: {'searchTerm': searchTerm, 'genres': genres});
    await logEventGeneric('chatBotOpen', { 'type': type }, true);
}

export async function logChatBotMessage(message) {
    // await analytics.logEvent(name: 'searchGenres', // 'test_event',
    //     parameters: {'searchTerm': searchTerm, 'genres': genres});
    await logEventGeneric('chatBotLog', { 'message': message }, true);
}

export async function logPremiumOpen(parent) {
    await logEventGeneric('premiumOpen', {
        parent
    }, true);
}
export async function logPremiumClick(parent, identifier) {
    await logEventGeneric('premiumClick', {
        parent, identifier
    }, true);
}
export async function logPremiumComplete(parent, identifier) {
    await logEventGeneric('premiumComplete', {
        parent, identifier
    }, true);
}
export async function logAdPurchase(genreId, collectionId, identifier) {
    await logEventGeneric('adPurchase', {
        genreId, collectionId, identifier
    }, true);
}
export async function logDashboardPremium() {
    await logEventGeneric('premiumDashboard', {
    }, true);
}

/// searchType is podcasts search or episodes Search or episodeSearch within series
export async function logViewSearchResults(searchTerm, searchType) {
    // await firebase.analytics().logViewSearchResults(
    //   searchTerm: searchTerm,
    // );
    await logEventGeneric('viewSearchResults', // 'test_event',
        { 'searchTerm': searchTerm, 'type': searchType }, true);
}

export async function logPodcastSeriesClick(collectionIdOrg) {
    let collectionId = typeof collectionIdOrg === "string"
        ? (parseInt(collectionIdOrg))
        : collectionIdOrg;
    await logEventGeneric('podcastSeriesClick', // 'test_event',
        { 'collectionId': collectionId }, true);
}


export async function logAdClickAnalytics(genreId, collectionId, parent) {
    await logEventGeneric('adClick', // 'test_event',
        { 'genreId': genreId, 'collectionId': collectionId, 'parent': parent }, true);
}

export async function logAdImpressionAnalytics(genreId, collectionId, parent) {
    await logEventGeneric('adImpression', // 'test_event',
        { 'genreId': genreId, 'collectionId': collectionId, 'parent': parent }, true);
}

export async function logPodcastEpisodeClick(podcastEpisode) {
    await logEventGeneric('podcastEpisodeClick', // 'test_event',
        {
            'guid': podcastEpisode.guid,
            'collectionId': podcastEpisode.collectionId
        }, true);
}

/// browseType is podcasts or episodes
export async function logBrowseTabGenre(genreId, browseType) {
    await logEventGeneric('browseTabGenre', // 'test_event',
        { 'genreId': genreId, 'browseType': browseType }, true);
}

/// do this for downloads and recentlyPlayed
export async function logScreen(screenName) {
    await setCurrentScreen(analytics,
        screenName
    );
}

// export async function logTogglePushNotifications(toggleType) {
//     await logEventGeneric('togglePushNotifications', // 'test_event',
//         { 'value': toggleType });
// }

// /// theme is dark or light
// export async function logToggleTheme(theme) {
//     await logEventGeneric('theme', // 'test_event',
//         { 'value': theme });
// }

export async function logToggleAutoPlayNextEpisode(value) {
    await logEventGeneric('autoPlayNextEpisode', // 'test_event',
        { 'value': value }, true);
}

export async function logToggleAutoSkipPlayedEpisode(value) {
    await logEventGeneric('autoSkipPlayedEpisode', // 'test_event',
        { 'value': value }, true);
}

export async function logTagPublic(tagId, userId, interactionType) {
    await logEventGeneric('logTagPublic', // 'test_event',
        {
            'tagId': tagId,
            'userId': userId,
            'interactionType': interactionType
        }, true);
}

// export async function logToggleAutoRemovePlayedEpisodeFromPlaylist(value) {
//     await logEventGeneric('autoRemovePlayedEpisodeFromPlaylist', // 'test_event',
//         { 'value': value });
// }

/// interactionType: 0-delete, 1-add, 2-editPlaylist
export async function logPlaylist(playlistId, interactionType) {
    await logEventGeneric('logPlaylist', // 'test_event',
        {
            'playlistId': playlistId,
            'interactionType': interactionType
        }, true);
}

export async function logPlaylistPublic(playlistId, interactionType) {
    await logEventGeneric('logPlaylistPublic', // 'test_event',
        {
            'playlistId': playlistId,
            'interactionType': interactionType
        }, true);
}

export async function logPlaylistCollaborative(playlistId, interactionType) {
    await logEventGeneric('logPlaylistCollaborative', // 'test_event',
        {
            'playlistId': playlistId,
            'interactionType': interactionType
        }, true);
}

/// interactionType: 0-remove, 1-add
export async function logEpisodeInPlaylist(guid, collectionId, interactionType) {
    await logEventGeneric('episodeInPlaylist', // 'test_event',
        {
            'guid': guid,
            'collectionId': collectionId,
            'interactionType': interactionType
        }, true);
}

// /// interactionType: 0-deleteDownload, 1-download
// export async function logDownloadEpisode(guid, collectionId, interactionType) {
//     await logEventGeneric('downloadEpisode', // 'test_event',
//         {
//             'guid': guid,
//             'collectionId': collectionId,
//             'interactionType': interactionType
//         });
// }

/// interactionType: 0-pause, 1-playNewAudio, 2-resumeAudio
export async function logPlayAudio(guid, collectionId,
    progress, interactionType) {
    await logEventGeneric('playAudio', // 'test_event',
        {
            'guid': guid,
            'collectionId': collectionId,
            'progress': progress || 0,
            'interactionType': interactionType
        }, true);
}

/// timeMoved can be +ve or -ve depending on forward or rewind
export async function logChangeAudioPosition(guid, collectionId,
    progress, timeMovedInSeconds) {
    await logEventGeneric('changeAudioPosition', // 'test_event',
        {
            'guid': guid,
            'collectionId': collectionId,
            'progress': progress,
            'timeMovedInSeconds': timeMovedInSeconds,
        });
}

export async function logPlaybackRate(guid, collectionId, playbackRate) {
    await logEventGeneric('playbackRate', // 'test_event',
        {
            'guid': guid,
            'collectionId': collectionId,
            'playbackRate': playbackRate
        });
}

// export async function logSkipSecondsBeginningSeries(secondsSkipped, collectionIdOrg) {
//     let collectionId = typeof collectionIdOrg === "string"
//         ? (parseInt(collectionIdOrg))
//         : collectionIdOrg;
//     await logEventGeneric(
//         'skipSecondsBeginningSeries', // 'test_event',
//         {
//             'secondsSkipped': secondsSkipped,
//             'collectionId': collectionId
//         });
// }

/// interactionType : 0-unlike, 1-like
export async function logLikeReview(reviewId, interactionType) {
    await logEventGeneric('likeReview', // 'test_event',
        { 'reviewId': reviewId, 'interactionType': interactionType }, true);
}

/// interactionType : 0-unsubscribe, 1-subscribe
export async function logSubscribePodcast(collectionIdOrg, interactionType) {
    let collectionId = typeof collectionIdOrg === "string"
        ? (parseInt(collectionIdOrg))
        : collectionIdOrg;
    await logEventGeneric('subscribePodcast', {
        'collectionId': collectionId,
        'interactionType': interactionType
    }, true);
}

/// interactionType : 0-delete, 1-create, 2-edit
export async function logReview(guid, collectionId, rating,
    privacyType, interactionType) {
    await logEventGeneric('logReview', // 'test_event',
        {
            'guid': guid,
            'collectionId': collectionId,
            'rating': rating,
            'privacyType': privacyType,
            'interactionType': interactionType
        }, true);
}

/// interactionType : 0-delete, 1-create, 2-edit
export async function logAffiliateClick(title, url) {
    await logEventGeneric('affiliateClick', // 'test_event',
        {
            'title': title,
            'url': url
        });
}