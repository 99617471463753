import {
  FETCH_REVIEWS, ADD_TO_REVIEWS, UPDATE_REVIEWS, DELETE_FROM_REVIEWS,
  ADD_LIKES_REVIEWS, REMOVE_LIKES_REVIEWS, ADD_REPORTED_REVIEWS, REMOVE_REPORTED_REVIEWS,
  PIN_REVIEW, UNPIN_REVIEW, SET_HAS_REPLIES
}
  from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const reviewReducer = (state = {}, action) => {
  // console.log('recent : ' + action.type);
  switch (action.type) {
    case FETCH_REVIEWS: {
      return Object.assign({}, state, action.payload);
      // const userId = action.payload.userID || '';
      // const reviewsMeta = action.payload.reviewsMeta || {};
      // return produce(state, draft => {
      //   draft[userId] = { ...draft[userId], reviewsMeta } // reviews;
      // })
    }
    case ADD_TO_REVIEWS: {
      // return Object.assign({}, state, action.payload);
      const reviewId = action.payload.reviewId || '';
      const review = action.payload.newEpisodeReview || '';

      // // add to state only if reviews of that particular rating already exist in state
      // const specificReviews = review && Object.values(state).filter(currReview => currReview.rating == review.rating);

      // if (specificReviews && specificReviews.length > 0) {
      if (reviewId && review) {
        return Object.assign({}, state, { [reviewId]: review });
      } else {
        return state;
      }
      // } else {
      //   return state;
      // }
    }
    case UPDATE_REVIEWS: {
      // return Object.assign({}, state, action.payload);
      const reviewId = action.payload.reviewId || '';
      const review = action.payload.newEpisodeReview || '';

      //  // add to state only if reviews of that particular rating already exist in state
      // const specificReviews = review && Object.values(state).filter(currReview => currReview.rating == review.rating);
      // if (specificReviews && specificReviews.length > 0) {
      if (reviewId && review) {
        if (state[reviewId]) {
          let return1 = { ...state[`${reviewId}`], ...review };
          return Object.assign({}, state, { [reviewId]: return1 });
          // return {}
          //   ..addAll(state)
          //   ..addAll({
          //     '${reviewId}': {}..addAll(state['${reviewId}'])..addAll(review)
          //   });
        } else {
          return state;
        }
      } else {
        return state;
      }
      // } else {
      //   return state;
      // }
    }
    case DELETE_FROM_REVIEWS: {
      const reviewId = action.payload || '';
      if (reviewId) {
        let copy = Object.assign({}, state);
        delete copy[reviewId]; //.progress;
        return copy;
      } else {
        return state;
      }
    }
    case ADD_LIKES_REVIEWS: {
      let reviewId = action.reviewId;
      let userUID = action.userUID;

      let return2 = state[`${reviewId}`]['likesArray'] ? [...state[`${reviewId}`]['likesArray'], userUID] : [userUID];
      let return1 = { ...state[`${reviewId}`], 'likesArray': return2 };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    case REMOVE_LIKES_REVIEWS: {
      let reviewId = action.reviewId;
      let userUID = action.userUID;

      let return2 = state[`${reviewId}`]['likesArray'] ? state[`${reviewId}`]['likesArray'].filter(uid => uid !== userUID) : [];
      let return1 = { ...state[`${reviewId}`], 'likesArray': return2 };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    case ADD_REPORTED_REVIEWS: {
      let reviewId = action.reviewId;
      let userUID = action.userUID;

      let return2 = state[`${reviewId}`]['reportedArray'] ? [...state[`${reviewId}`]['reportedArray'], userUID] : [userUID];
      let return1 = { ...state[`${reviewId}`], 'reportedArray': return2 };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    case REMOVE_REPORTED_REVIEWS: {
      let reviewId = action.reviewId;
      let userUID = action.userUID;

      let return2 = state[`${reviewId}`]['reportedArray'] ? state[`${reviewId}`]['reportedArray'].filter(uid => uid !== userUID) : [];
      let return1 = { ...state[`${reviewId}`], 'reportedArray': return2 };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    case SET_HAS_REPLIES: {
      let reviewId = action.reviewId;
      let return1 = { ...state[`${reviewId}`], 'hasReplies': true };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    case PIN_REVIEW: {
      let reviewId = action.reviewId;
      let return1 = { ...state[`${reviewId}`], 'pinned': true };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    case UNPIN_REVIEW: {
      let reviewId = action.reviewId;
      let return1 = { ...state[`${reviewId}`], 'pinned': false };
      return Object.assign({}, state, { [reviewId]: return1 });
    }
    default:
      return state;
  }
};