import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { getPodcastImgSize, podcastHrefUrl, podcastSlugUrl } from '../../lib/utils';
import { logAdClick, logAdImpression } from '../../actions';
import AppCustomLink from '../Common/AppCustomLink';

class AdPodcast extends Component {
    constructor(props) {
        super(props);
        this.state = { podcast: null, index: 0 };
    }
    componentDidMount() {
        const { adPodcasts } = this.props;
        const { index } = this.state;
        const adPodcastsAll = [];
        Object.values(adPodcasts).forEach((pods) => {
            adPodcastsAll.push(...pods);
        })
        console.dir(adPodcastsAll);
        if (!adPodcastsAll.length) {
            return;
        }
        this.setState({ podcast: adPodcastsAll[index], index: index + 1 });
        this.logImpression(adPodcastsAll[index]);
        this.myInterval = setInterval(() => {
            const { index } = this.state;
            console.dir(adPodcastsAll);
            this.setState({ podcast: adPodcastsAll[index % adPodcastsAll.length], index: index + 1 });
            this.logImpression(adPodcastsAll[index % adPodcastsAll.length]);
        }, 20 * 1000);
    }

    logImpression(podcast) {
        const { adPodcasts } = this.props;
        console.dir(adPodcasts);
        let adGenreId = '';
        loop1: for (const adGenre of Object.keys(adPodcasts)) {
            for (const pod of (adPodcasts[adGenre] || [])) {
                if (pod.collectionId == podcast.collectionId) {
                    adGenreId = adGenre;
                    break loop1;
                }
            }
        }
        logAdImpression(adGenreId, podcast.collectionId, 'RightBar');
    }

    onClickPod = (podcast) => {
        const { adPodcasts } = this.props;
        let adGenreId = '';
        loop1: for (const adGenre of Object.keys(adPodcasts)) {
            for (const pod of (adPodcasts[adGenre] || [])) {
                if (pod.collectionId == podcast.collectionId) {
                    adGenreId = adGenre;
                    break loop1;
                }
            }
        }
        logAdClick(adGenreId, podcast.collectionId, 'RightBar');
    }

    componentWillUnmount() {
        window.clearInterval(this.myInterval);
    }
    getDescription() {
        const { podcast } = this.state;
        if (!podcast.description) {
            return '';
        }
        const trimLength = 50;
        if (podcast.description.length < trimLength) {
            return podcast.description;
        }
        return podcast.description.substring(0, trimLength) + '...';
    }
    render() {
        const { adPodcasts } = this.props;
        const { podcast } = this.state;
        console.dir(adPodcasts);
        if (!podcast) {
            return <></>
        }
        return (
            <div>
                <AppCustomLink as={podcastSlugUrl(podcast.collectionName, podcast.collectionId)}
                    href={podcastHrefUrl(podcast.collectionId)} >
                    <Row style={{ cursor: 'pointer' }}>
                        <Col md={6}>
                            <div className='ads-show-div'>Ad</div>
                            <img style={{ maxWidth: '100%' }} src={getPodcastImgSize(podcast.artworkUrl600, 'md')} alt={`${podcast.collectionName || ''} podcast`} />
                        </Col>
                        <Col md={18}>
                            <div style={{ paddingLeft: '8px' }}>
                                <h5 className='title' style={{ marginBottom: '2px' }}>{podcast.collectionName}</h5>
                                <p className='descr'>{this.getDescription()}</p>
                            </div>
                        </Col>
                    </Row>
                </AppCustomLink>
                <style jsx>
                    {`
                    .title {
                        font-size: 13px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        overflow-wrap: normal;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                    }
                    .descr {
                        font-size: 12px;
                    }
            .ads-show-div {
              padding: 0 4px;
              background: orange;
              position: absolute;
              right: 0;
              top: 0;
              border-radius: 2px;
              font-size: 10px;
            }
            `}
                </style>
            </div>
        )
    }
}

AdPodcast.propTypes = {
    adPodcasts: PropTypes.object,
    // userDetails: PropTypes.object,
}

const mapStateToProps = (state) => {
    return {
        adPodcasts: state.adPodcasts,
        // userDetails: state.userDetails,
    }
}
export default connect(mapStateToProps, null)(AdPodcast);