import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import TextareaAutosize from 'react-autosize-textarea';
import { changeSearchTerm } from '../actions';
// import Link from 'next/link';
import Router from 'next/router';
import { colors } from '../lib/theme';
import { Input, AutoComplete, Row, Col } from 'antd';
import { searchItunes } from '../lib/searchItunes';
import SubscribeOption from './Podcast/SubscribeOption';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchOutlined } from '@ant-design/icons';
import { podcastHrefUrl, podcastSlugUrl, setCurrentPodcastData } from '../lib/utils';
import { setIsTyping } from '../lib/fileUtils';

// const Search = Input.Search;

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = { query: '', value: '', options: [], dropdownOpen: false };
    this.myRef = React.createRef();

    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  //When user clicks add post fire the add post event.
  // onClick() {
  // }

  // //Update user text input as they type by firing this action.
  // handleChange(event) {
  //   this.setState({ value: event.target.value });
  // }

  onFocus = () => {
    const pathName = window.location.pathname;
    console.log(pathName);
    if (pathName !== '/search') {
      Router.push(`/search`);
    }
  }

  // handleSearch(text) {
  //   console.dir(text);
  //   const textNew = text.replace(/ /g, '-');
  //   Router.push(`/search?text=${textNew}`, `/search/${textNew}`)
  // }
  onSearchChange = async (e) => {
    console.dir(e);
    setIsTyping();
    // this.props.changeSearchTerm(e);
    // setOptions(value ? searchResult(value) : []);
    const optionsFin = await this.searchResult(e);
    console.dir(optionsFin);
    this.setState({ options: optionsFin });
  }

  searchAll = (query, type) => {
    this.setState({ dropdownOpen: false });
    Router.push(`/search-all?text=${query}&type=${type}`);
  }

  openPodcast = (podcastInfo) => {
    console.dir(podcastInfo);
    this.setState({ dropdownOpen: false });
    Router.push(podcastHrefUrl(podcastInfo.collectionId), podcastSlugUrl(podcastInfo.collectionName, podcastInfo.collectionId));
  }

  searchResult = async (query) => {
    console.dir(query);
    this.setState({ query });
    if (query && query.length > 0) {
      this.setState({ dropdownOpen: true });
    }
    if (query && query.length < 3) {
      return [{
        value: 'empty',
        label: (
          <p>Please enter atleast 3 letters.</p>
        )
      }];
    }
    this.setState({ podcasts: undefined, searching: true });
    let mapResults = [];
    try {
      const response = await fetch(searchItunes(query, 6), {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      });
      const json = await response.json();
      const podcastResults = json.results;
      this.setState({ podcasts: podcastResults });
      mapResults = podcastResults.map((podcastInfo, idx) => {
        // const category = `${query}${idx}`;
        if (idx < 2) {
          return {
            value: 'podcast_' + idx,
            label: (
              <Row>
                <Col span={5}>
                  <img key={podcastInfo.image}
                    className='main-img'
                    style={{ width: '100%', maxWidth: '100px', minHeight: '72px' }}
                    alt={`${podcastInfo.title || ''} podcast`}
                    src={podcastInfo['artworkUrl600'] && podcastInfo['artworkUrl600'].replace('&amp;', '&') || podcastInfo.image && podcastInfo.image.replace('&amp;', '&')} />
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', right: '0', bottom: '0' }}>
                      <SubscribeOption itunesId={podcastInfo.collectionId} podcast={podcastInfo} showSubscribers={false} />
                    </div>
                  </div>
                </Col>
                <Col span={19} style={{ paddingLeft: '1em' }}>
                  <h5 style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{podcastInfo.collectionName}</h5>
                  <p style={{ fontSize: '11px', opacity: '0.85' }}>{podcastInfo.artistName}</p>
                </Col>
              </Row>
            ),
          };
        } else {
          return {
            value: 'podcast_' + idx,
            label: (
              <Row style={{ paddingTop: '6px' }}>
                <Col span={5} style={{ textAlign: 'center' }}>
                  <h5><SearchOutlined style={{ fontSize: '18px' }} /></h5>
                </Col>
                <Col span={19} style={{ paddingLeft: '1em' }}>
                  <h5 style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}>{podcastInfo.collectionName}</h5>
                  {/* <p style={{ fontSize: '11px', opacity: '0.85' }}>{podcastInfo.artistName}</p> */}
                </Col>
              </Row>
            ),
          };
        }
      });
    } catch (err) {
      console.error(err);
      this.setState({ podcasts: [], searching: false });
    }
    const mapResultsMore = [
      {
        value: 'seeAll' + query + ' podcasts',
        label: (
          <p style={{ padding: '8px', marginBottom: '0' }}>See All Shows <FontAwesomeIcon icon={'arrow-right'}
            style={{}} /></p>
        )
      },
      {
        value: 'seeAll' + query + ' episodes',
        label: (
          <p style={{ padding: '8px', marginBottom: '0' }}>See All Episodes <FontAwesomeIcon icon={'arrow-right'}
            style={{}} /></p>
        )
      },
    ];
    return [...mapResults, ...mapResultsMore];
  }

  onSelectOption = (value, option) => {
    const { podcasts, query } = this.state;
    this.setState({ query });
    console.log(value);
    console.dir(option);
    if (value.startsWith('seeAll')) {
      if (value.startsWith('seeAll')) {
        this.searchAll(query, value.includes('podcasts') ? 'podcasts' : 'episodes');
      }
    } else if (value.startsWith('podcast')) {
      const index = value.split('_')[1];
      if (index < 2) {
        setCurrentPodcastData(podcasts[index]);
        this.openPodcast(podcasts[index]);
      } else {
        this.searchAll(encodeURIComponent(podcasts[index].collectionName), 'podcasts');
      }
    }
    setTimeout(() => this.myRef.current.blur(), 100);
    return;
  }

  render() {
    const { options, dropdownOpen, query } = this.state;

    return (
      <div className="padding">
        {/* <Input.Search
          placeholder="Search over 700,000 podcasts"
          onSearch={value => this.handleSearch(value)}
          enterButton
          onChange={this.onSearchChange}
          onFocus={this.onFocus}
        /> */}
        <AutoComplete
          style={{
            width: '100%'
          }}
          options={options}
          // onChange={this.onSearchChange}
          onFocus={this.onFocus}
          onSearch={this.onSearchChange}
          dropdownClassName='auto-drop'
          dropdownMatchSelectWidth={400}
          onSelect={this.onSelectOption}
          value={query}
          ref={this.myRef}
        // open={dropdownOpen}
        // allowClear={true}
        // placeholder="Search over 700,000 podcasts"
        >
          <Input.Search className='place-1' placeholder="Search over 900,000 podcasts" enterButton />
          {/* {result.map((email) => (
        <Option key={email} value={email}>
          {email}
        </Option>
      ))} */}
        </AutoComplete>
        <style jsx>
          {`
          :global(.auto-drop > div > div) {
            max-height: 90vh !important;
          }
        `}
        </style>

      </div>
    );
  }
}

Search.propTypes = {
  changeSearchTerm: PropTypes.func,
  // settingsState: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return {
    changeSearchTerm: bindActionCreators(changeSearchTerm, dispatch)
  }
};

export default connect(null, mapDispatchToProps)(Search);