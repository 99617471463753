import { auth, firestore } from '../lib/db';
import { Timestamp, collection, query, where, getDoc, doc, setDoc, arrayUnion, increment } from "firebase/firestore/lite";
import { logAdClickAnalytics, logAdImpressionAnalytics } from '../lib/analyticsEvents';
import { FETCH_AD_PODCASTS_ACTION, USER_HAS_ADS } from './types';
import { FOR_YOU, FOR_YOU_OTHER, TOP_10, TOP_10_OTHER } from '../lib/strings';
import { getRelevantPodcastGenres } from '../lib/fileUtils';
import { tokenFetch } from '../lib/tokenUtils';
import { serverApi } from '../lib/utils';

export const getAdsInfo = async () => {
    return getDoc(doc(firestore, `ad-info`, 'info'))
        .then((doc) => {
            if (doc.exists()) {
                const data = doc.data();
                console.dir(data);
                return data;
            }
        });
}

export const getAdHistory = async (uid) => {
    return getDoc(doc(firestore, `ad-history`, uid))
        .then((doc) => {
            if (doc.exists()) {
                const data = doc.data();
                console.dir(data);
                return data;
            }
            return {};
        });
}
export const getAdHistoryAll = async (uid) => {
    const result = await tokenFetch(`${serverApi}adHistoryAll?t=1`);
    const json = await result.json();
    return json;
}

export const userHasAds = () => async (dispatch, getState) => {
    dispatch({
        type: USER_HAS_ADS,
    });
}


/******** for app users */


// final Map adStats = {};
// ThunkAction<AppState> fetchAds(String genreId, {bool separateGenres = false}) {
//     return (Store<AppState> store) async {
export const fetchAds = (genreId, separateGenres = false) => async (dispatch, getState) => {
    console.dir('fetchAds');
    const adPodcasts = getState().adPodcasts;
    console.dir(adPodcasts);
    if (adPodcasts[genreId]) {
        return;
    }

    const promise1 = getDoc(doc(firestore, `ad-genres/${genreId}/stats`, 'details'));
    const promise2 = getDoc(doc(firestore, `ad-genres`, genreId));

    const results =
        await Promise.all([promise1, promise2]);
    let adStatsNeeded = {};
    for (let i = 0; i <= 1; i++) {
        const doc = results[i];
        if (i == 0) {
            if (doc.exists()) {
                const data = doc.data();
                adStatsNeeded = data[genreId] || {};
            }
        } else {
            if (doc.exists()) {
                const data = doc.data();
                // const adStatsNeeded = adStats[genreId] ?? {};
                const podcastOrigData = data['podcasts'] || {};
                Object.keys(podcastOrigData).forEach((collectionId) => {
                    // const value = podcastOrigData[collectionId];
                    const impressions = adStatsNeeded['podcasts'] &&
                        adStatsNeeded['podcasts'][collectionId]
                        ? (adStatsNeeded['podcasts'][collectionId]['impressions'] || 0)
                        : 0;
                    podcastOrigData[collectionId]['impressions'] = impressions;
                });

                ///sort podcasts based on impressions
                var sortedKeys = Object.keys(podcastOrigData)
                    .sort((k1, k2) => (podcastOrigData[k1]['impressions'] || 0)
                        - ((podcastOrigData[k2]['impressions'] || 0)));
                const podcasts = [];
                // sortedKeys.forEach((key) {
                for (let ind = 0; ind < sortedKeys.length; ind++) {
                    const podDetails = podcastOrigData[sortedKeys[ind]]['podcast'];
                    podDetails['isAd'] = true;
                    podDetails['showAd'] = ind != 1;
                    podcasts.push(podDetails);
                }
                console.dir(genreId);
                // utils.printLog(podcasts);
                if (separateGenres) {
                    const relevantGenres = getRelevantPodcastGenres();
                    if (relevantGenres.length > 0) {
                        console.dir(relevantGenres);
                        console.dir(genreId);
                        const relevantGenre = relevantGenres[0];
                        if (relevantGenre['id'] == genreId) {
                            //// this goes in top 10
                            const top10 = [];
                            const top10Other = [];
                            for (let i = 0; i < podcasts.length; i++) {
                                if (podcasts.length <= 3) {
                                    top10.push(podcasts[i]);
                                    top10Other.push(podcasts[i]);
                                } else {
                                    if (i <= 1 || i == 5) {
                                        top10.push(podcasts[i]);
                                    } else if (i <= 4) {
                                        top10Other.push(podcasts[i]);
                                    }
                                }
                            }
                            dispatch({ type: FETCH_AD_PODCASTS_ACTION, genreId: TOP_10, podcasts: top10 });
                            dispatch({ type: FETCH_AD_PODCASTS_ACTION, genreId: TOP_10_OTHER, podcasts: top10Other });
                        } else {
                            /// this goes in FOR YOU
                            const forYou = [];
                            const forYouOther = [];
                            for (let i = 0; i < podcasts.length; i++) {
                                if (podcasts.length <= 3) {
                                    forYou.push(podcasts[i]);
                                    forYouOther.push(podcasts[i]);
                                } else {
                                    if (i <= 1 || i == 5) {
                                        forYou.push(podcasts[i]);
                                    } else if (i <= 4) {
                                        forYouOther.push(podcasts[i]);
                                    }
                                }
                            }
                            dispatch({ type: FETCH_AD_PODCASTS_ACTION, genreId: FOR_YOU, podcasts: forYou });
                            dispatch(
                                { type: FETCH_AD_PODCASTS_ACTION, genreId: FOR_YOU_OTHER, podcasts: forYouOther });
                        }
                    }
                }
                console.dir(podcasts);
                dispatch({ type: FETCH_AD_PODCASTS_ACTION, genreId: genreId, podcasts: podcasts });
            }
        }
    }
}

const adImpressionsMap = {};
export const logAdImpression = async (
    genreId, collectionId, parent) => {
    if (!genreId) {
        return;
    }
    if (adImpressionsMap[parent]) {
        if (adImpressionsMap[parent][collectionId]) {
            return;
        } else {
            adImpressionsMap[parent][collectionId] = true;
        }
    } else {
        adImpressionsMap[parent] = { [collectionId]: true };
    }
    // console.dir('in logAdImpression');
    // console.dir(genreId);
    // utils.printLog(parent);
    // final User user = FirebaseAuth.instance.currentUser;
    setDoc(doc(firestore, `ad-genres/${genreId}/stats`, 'details'), {
        'podcasts': {
            [collectionId]: { 'impressions': increment(1) }
        }
    }, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
    logAdImpressionAnalytics(genreId, collectionId, parent);
}

export const logAdPurchaseExploreClick = async (genreId, userId) => {
    if (!genreId) {
        return;
    }
    // utils.printLog('in logAdClick');
    // utils.printLog(genreId);
    // utils.printLog(parent);
    // final User user = FirebaseAuth.instance.currentUser;
    const mapToLog = {};
    const docName = new Date().toJSON().slice(0, 10);
    if (userId) {
        mapToLog['users'] = { [userId]: Date.now() };
    } else {
        mapToLog['beforeRegisterClicks'] = increment(1);
    }
    console.dir(mapToLog);
    setDoc(doc(firestore, `ad-info/info/explore`, docName), {
        'genres': {
            [`${genreId}`]: mapToLog
        }
    }, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
}

export const logAdPurchaseOnSearchStart = async (genreId, userId) => {
    if (!genreId) {
        return;
    }
    // utils.printLog('in logAdClick');
    // utils.printLog(genreId);
    // utils.printLog(parent);
    // final User user = FirebaseAuth.instance.currentUser;
    const mapToLog = {};
    const docName = new Date().toJSON().slice(0, 10);
    if (userId) {
        mapToLog['usersOnSearchStart'] = arrayUnion(userId);
    } else {
        mapToLog['beforeRegisterSearchClicks'] = increment(1);
    }
    console.dir(mapToLog);
    setDoc(doc(firestore, `ad-info/info/explore`, docName), {
        'genres': {
            [`${genreId}`]: mapToLog
        }
    }, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
}

export const logAdPurchaseSearchClick = async (genreId, userId) => {
    if (!genreId) {
        return;
    }
    // utils.printLog('in logAdClick');
    // utils.printLog(genreId);
    // utils.printLog(parent);
    // final User user = FirebaseAuth.instance.currentUser;
    const docName = new Date().toJSON().slice(0, 10);
    const mapToLog = {};
    if (userId) {
        mapToLog['usersSearch'] = arrayUnion(userId);
    } else {
        mapToLog['beforeRegisterSearchClicks'] = increment(1);
    }
    console.dir(mapToLog);
    setDoc(doc(firestore, `ad-info/info/explore`, docName), {
        'genres': {
            [`${genreId}`]: mapToLog
        }
    }, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
}

export const logAdClick = async (genreId, collectionId, parent) => {
    if (!genreId) {
        return;
    }
    console.dir('in logAdClick');
    console.dir(genreId);
    // utils.printLog(parent);
    // final User user = FirebaseAuth.instance.currentUser;

    setDoc(doc(firestore, `ad-genres/${genreId}/stats`, 'details'), {
        'podcasts': {
            [collectionId]: { 'clicks': increment(1) }
        }
    }, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
    logAdClickAnalytics(genreId, collectionId, parent);
}
