import {
    ADD_ERROR
}
    from '../actions/types';

// export const initialState = {
//     loadingUserDetails: true
// };

export const errorsReducer = (state = {}, action) => {
    // console.dir(action);
    const { type, error } = action;
    if (typeof error === 'undefined') {
        return state;
    }
    const actionType = type;
    if (error) {
        return Object.assign({}, state, { [actionType]: true });
    } else if (!error) {
        let copy = Object.assign({}, state);
        if (copy[`${actionType}`]) {
            delete copy[`${actionType}`];
        }
        return copy;
    } else {
        return state;
    }
    // switch (action.type) {
    //     case ADD_ERROR:
    //         return Object.assign({}, state, action.payload);
    //     default:
    //         return state;
    // }
};

export const getRequiredErrorsFn = (errorsState, errorType) => {
    return (errorsState && errorsState[errorType]) ? errorsState[errorType] : false;
}