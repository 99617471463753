import {
    ADD_MY_FILES_ACTION, ADD_MY_FILE_UPLOAD_ACTION, ADD_MY_FILE_UPLOAD_PROGRESS_ACTION,
    CANCEL_MY_FILE_UPLOAD_ACTION, DELETE_MY_FILES_ACTION, DELETE_MY_FILE_UPLOAD_ACTION,
    DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION, FETCH_MY_FILES_ACTION
} from "../actions/types";

export const myFilesReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_MY_FILES_ACTION:
            return Object.assign({}, state, action.files);
        case ADD_MY_FILES_ACTION:
            return Object.assign({}, state, { [action.guid]: action.episode });
        case DELETE_MY_FILES_ACTION: {
            let copy = Object.assign({}, state);
            if (copy[action.guid]) {
                delete copy[action.guid];
            }
            return copy;
        }
        default:
            return state;
    }
};

export const myFileUploadsReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_MY_FILE_UPLOAD_ACTION:
            return Object.assign({}, state, { [action.guid]: 1 });
        case CANCEL_MY_FILE_UPLOAD_ACTION:
            return Object.assign({}, state, { [action.guid]: 2 });
        case DELETE_MY_FILE_UPLOAD_ACTION: {
            let copy = Object.assign({}, state);
            if (copy[action.guid]) {
                delete copy[action.guid];
            }
            return copy;
        }
        default:
            return state;
    }
};
export const myFileUploadProgressReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_MY_FILE_UPLOAD_PROGRESS_ACTION:
            return Object.assign({}, state, { [action.guid]: action.progress });
        case DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION: {
            let copy = Object.assign({}, state);
            if (copy[action.guid]) {
                delete copy[action.guid];
            }
            return copy;
        }
        default:
            return state;
    }
};