import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { createPlaylist, addToPlaylist, editPlaylist, createPlaylistPersonal, editPlaylistPersonal } from '../../actions';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Button, Col, Form, Input, message, notification, Row, Upload } from 'antd';
import { isAutoPlaylist } from '../../lib/utils';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { setIsTyping } from '../../lib/fileUtils';
// const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 16,
            offset: 8,
        },
    },
};

const openNotificationWithIcon = (type, message) => {
    notification[type]({
        message: message,
        description: 'Please try again.',
        duration: null
    });
};

class PlaylistForm extends React.Component {
    constructor(props) {
        super(props);
        // const value = props.value || {};
        const { edit } = props;
        this.state = {
            type: 'private',
            loading: false,
            loadingImage: false,
            name: edit && edit.playlistInfo && edit.playlistInfo.name || '',
            description: edit && edit.playlistInfo && edit.playlistInfo.description || '',
            imageUrl: ''
            // playlistName: undefined
        };
    }

    handleSubmit = (values) => {
        // console.dir(values);
        const { userDetails, edit } = this.props;
        const { imageUrl } = this.state;
        // e.preventDefault();
        // this.props.form.validateFields((err, values) => {
        //     if (!err) {
        console.log('Received values of form: ', values);
        this.setState({ loading: true });
        if (edit && edit.firestoreId && edit.firestoreId.length > 0) {
            this.props.callBackParent(true, values);
            // if (edit.playlistInfo.name === values.name) { // remove name field
            //     delete values['name'];
            // }
            console.log(values);
            editPlaylist(userDetails.uid, values, edit.firestoreId, imageUrl)
                .then((data) => {
                    console.dir(data);
                    if (data.success) {
                        console.dir(data);
                        // if (typeof data.playlistInfo !== 'undefined' && (typeof data.playlistInfo.name !== 'undefined' || typeof data.playlistInfo.description !== 'undefined')) {
                        //     console.dir(data);
                        //     this.props.editPlaylistPersonal(userDetails.uid, data.playlistInfo, data.firestoreId);
                        // }
                        this.props.editPlaylistPersonal(data.playlistInfo, data.firestoreId);
                        this.setState({ loading: false });
                        this.props.callBackParent(false);
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false });
                    openNotificationWithIcon('error', 'Error editing playlist');
                })
        } else {
            this.props.callBackParent(true);
            createPlaylist(userDetails, values, imageUrl)
                .then((data) => {
                    if (data.success) {
                        console.dir(data);
                        const { podcastDetails } = this.props;
                        console.dir(podcastDetails);
                        this.props.createPlaylistPersonal(data.playlistInfo, data.playlistId);
                        if (typeof podcastDetails !== 'undefined' && typeof podcastDetails.guid !== 'undefined') {
                            setTimeout(() => {
                                // const { userPlaylists } = this.props;
                                console.dir(data.playlistId);
                                this.props.addToPlaylist(podcastDetails, data.playlistId);
                            }, 1000);
                        }
                        this.setState({ loading: false });
                        this.props.callBackParent(false);
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ loading: false });
                    openNotificationWithIcon('error', 'Error creating playlist');
                })
            // this.setState({ playlistName: values.name });
        }
        //     }
        // });
    }

    validateName = (rule, value, callback) => {
        const charLength = 50;
        if (value && value.length > charLength) {
            callback(`Name should be less than ${charLength} characters`);
        } else {
            callback();
        }
    };
    validateDescription = (rule, value, callback) => {
        const charLength = 160;
        if (value && value.length > charLength) {
            callback(`Description should be less than ${charLength} characters`);
        } else {
            callback();
        }
    };

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must be smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
        console.dir(reader);
    }
    normFile = (e) => {
        console.log('Upload event:', e);
        e && this.getBase64(e.file.originFileObj, imageUrl =>
            this.setState({
                imageUrl,
                loadingImage: false,
            }),
        );
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    deleteImage = () => {
        this.setState({ imageUrl: '' });
    }
    changeName = (e) => {
        setIsTyping();
        this.setState({ name: e.target.value });
    }
    changeDescription = (e) => {
        this.setState({ description: e.target.value });
    }

    render() {
        // console.dir(this.props);
        const { edit } = this.props;
        const { imageUrl, loadingImage, name, description } = this.state;
        console.log(edit);
        // const { getFieldDecorator } = this.props.form;
        // const playlistData = () => {
        // const initName = edit && edit.playlistInfo && edit.playlistInfo.name;
        // const initDesc = edit && edit.playlistInfo && edit.playlistInfo.description;
        const initImage = edit && edit.playlistInfo && edit.playlistInfo.imageName;
        // }
        // const WrappedPlaylistForm = Form.create({ name: 'normal_login' })(PlaylistForm);
        // console.dir(imageUrl);
        const fields = [
            {
                "name": [
                    "name"
                ],
                "value": name
            }, {
                "name": [
                    "description"
                ],
                "value": description
            }
        ];

        const uploadButton = (
            <div>
                {loadingImage ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>{initImage ? 'Replace Existing Image' : 'Upload Image (Optional)'}</div>
            </div>
        );

        return (
            <Form onFinish={this.handleSubmit} fields={fields} className="login-form">
                <Row>
                    <Col xl={8}>
                        <Form.Item name="upload"
                            valuePropName="fileList"
                            getValueFromEvent={this.normFile}
                        // extra="longgggggggggggggggggggggggggggggggggg"
                        >
                            <Upload
                                name="avatar"
                                accept='image/jpeg,image/png'
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                beforeUpload={this.beforeUpload}
                            // onChange={this.handleChange}
                            >
                                {imageUrl ? <img src={imageUrl} alt="playlist" style={{ width: '100%' }} /> : uploadButton}
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col xl={16}>
                        <Form.Item {...formItemLayout} name="name"
                            rules={[{ required: true, message: 'Please enter a name for the playlist.' }, { validator: this.validateName }]}>
                            {/* {getFieldDecorator('name', {
                        initialValue: initName,
                        rules: [{ required: true, message: 'Please enter a name for the playlist.' }, { validator: this.validateName }],
                    })( */}
                            <Input placeholder="Playlist Name" onChange={this.changeName} />
                            {/* )} */}
                        </Form.Item>
                        {(!edit || !isAutoPlaylist(edit.playlistInfo)) && <Form.Item {...formItemLayout} name='description'
                            rules={[{ required: false, message: 'Please enter a description for the playlist.' }, { validator: this.validateDescription }]}>
                            {/* {getFieldDecorator('description', {
                        initialValue: initDesc,
                        rules: [{ required: false, message: 'Please enter a description for the playlist.' }, { validator: this.validateDescription }],
                    })( */}
                            <TextArea placeholder="Playlist Description (optional)" onChange={this.changeDescription} rows={3} />
                            {/* )} */}
                        </Form.Item>}
                    </Col>
                </Row>
                {imageUrl && <p>By proceeding, you agree to give Podurama access to the image you choose to upload. Please make sure you have the right to upload this image.</p>}

                <Form.Item {...tailFormItemLayout}>
                    <Button type="primary" loading={this.state.loading} htmlType="submit">
                        {(!edit || !edit.firestoreId) && 'Create Playlist'}
                        {edit && edit.firestoreId && 'Edit Playlist'}
                    </Button>
                    {/* <Button type="primary" htmlType="submit" className="login-form-button">
                        Create Playlist
                    </Button> */}
                </Form.Item>
                <style jsx>
                    {`
            // .login-form {
            //     max-width: 300px;
            //   }
            // .login-form-forgot {
            //     float: right;
            //   }
            //   .login-form-button {
            //     width: 100%;
            //   }
          `}
                </style>
            </Form>
        );
    }
}

PlaylistForm.defaultProps = {
    edit: {}
}

PlaylistForm.propTypes = {
    edit: PropTypes.object,
    userDetails: PropTypes.object,
    podcastDetails: PropTypes.object,
    form: PropTypes.object,
    userPlaylists: PropTypes.object,
    getFieldDecorator: PropTypes.func,
    createPlaylistPersonal: PropTypes.func,
    editPlaylistPersonal: PropTypes.func,
    addToPlaylist: PropTypes.func,
    callBackParent: PropTypes.func,
}

const WrappedPlaylistForm = PlaylistForm; // Form.create({ name: 'playlist_form' })(PlaylistForm);
// ReactDOM.render(<WrappedNormalLoginForm />, mountNode);

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        userPlaylists: state.userPlaylists
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createPlaylistPersonal: bindActionCreators(createPlaylistPersonal, dispatch),
        editPlaylistPersonal: bindActionCreators(editPlaylistPersonal, dispatch),
        addToPlaylist: bindActionCreators(addToPlaylist, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(WrappedPlaylistForm);