import {
    FETCH_BOOKMARK_ACTION, ADD_BOOKMARK_ACTION, DELETE_BOOKMARK_ACTION
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const episodeBookmarksReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_BOOKMARK_ACTION:
            return Object.assign({}, state, { [action.guid]: action.bookmarkData });
        case ADD_BOOKMARK_ACTION: {
            const currEpisodeBookmark = state[`${action.guid}`] || {};
            const return1 = { ...currEpisodeBookmark, ...action.bookmarkData };
            return Object.assign({}, state, { [action.guid]: return1 });
        }
        case DELETE_BOOKMARK_ACTION: {
            const currEpisodeBookmark = state[`${action.guid}`] || {};
            const copy = { ...currEpisodeBookmark };
            delete copy[action.timeId];
            return Object.assign({}, state, { [action.guid]: copy });
        }
        default:
            return state;
    }
};