import {
    FETCH_REVIEW_REPLIES, ADD_REVIEW_REPLY, EDIT_REVIEW_REPLY, REPORT_REVIEW_REPLY, UNREPORT_REVIEW_REPLY
}
    from '../actions/types';

export const reviewRepliesReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case FETCH_REVIEW_REPLIES: {
            return Object.assign({}, state, action.payload);
        }
        case ADD_REVIEW_REPLY: {
            const reviewId = action.reviewId;
            const replyInfo = action.replyInfo;
            if (state[reviewId]) {
                let return1 = { ...state[reviewId], 'comments': { ...state[reviewId]['comments'], ...replyInfo['comments'] } }
                return { ...state, [`${reviewId}`]: return1 };
            } else {
                return state;
            }
        }
        case EDIT_REVIEW_REPLY: {
            const reviewId = action.reviewId;
            const commentId = action.commentId;
            const commentInfo = action.commentInfo;

            if (state[reviewId]) {
                let return2 = { ...state[reviewId]['comments'][`${commentId}`], ...commentInfo };
                let return1 = { ...state[reviewId], 'comments': { ...state[reviewId]['comments'], [`${commentId}`]: return2 } }
                return { ...state, [`${reviewId}`]: return1 };
            } else {
                return state;
            }
        }
        case REPORT_REVIEW_REPLY: {
            const reviewId = action.reviewId;
            const commentId = action.commentId;
            const userUID = action.userUID;

            if (state[reviewId]) {
                let return3 = state[reviewId]['comments'][`${commentId}`]['reportedArray'] ?
                    [...state[reviewId]['comments'][`${commentId}`]['reportedArray'], userUID] : [userUID];
                let return2 = { ...state[reviewId]['comments'][`${commentId}`], 'reportedArray': return3 };
                let return1 = { ...state[reviewId], 'comments': { ...state[reviewId]['comments'], [`${commentId}`]: return2 } }
                return { ...state, [`${reviewId}`]: return1 };
            } else {
                return state;
            }
        }
        case UNREPORT_REVIEW_REPLY: {
            const reviewId = action.reviewId;
            const commentId = action.commentId;
            const userUID = action.userUID;

            if (state[reviewId]) {
                let return3 = state[reviewId]['comments'][`${commentId}`]['reportedArray'] ?
                    state[reviewId]['comments'][`${commentId}`]['reportedArray'].filter(uid => uid !== userUID) : [];
                let return2 = { ...state[reviewId]['comments'][`${commentId}`], 'reportedArray': return3 };
                let return1 = { ...state[reviewId], 'comments': { ...state[reviewId]['comments'], [`${commentId}`]: return2 } }
                return { ...state, [`${reviewId}`]: return1 };
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};