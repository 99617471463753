import {
    FETCH_HOME_PODCASTS, ADD_HOME_PODCASTS, ADD_POPULAR_PODCASTS
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const homePodcastsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_HOME_PODCASTS:
            return Object.assign({}, state, action.allHomePodcastsData);
        default:
            return state;
    }
};

export const homePodcastsMainReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_HOME_PODCASTS: {
            let return1 = [...action.podcastsData];


            if (action.title == 'Recommended For You') {
                const current = state['Recommended For You'] ?? [];
                return1 = [...return1, ...current];
                console.dir(return1);
            }

            return Object.assign({}, state, { [action.title]: return1 });
        }
        default:
            return state;
    }
};

export const popularEpisodesReducer = (state = {}, action) => {
    switch (action.type) {
        case ADD_POPULAR_PODCASTS: {
            // let return1 = [...action.podcastsData];
            return Object.assign({}, state, { [`${action.collectionId}`]: action.episodesMap });
        }
        default:
            return state;
    }
};