import {
    ADD_TO_REVIEWS_META, UPDATE_REVIEWS_META, DELETE_FROM_REVIEWS_META
}
    from '../actions/types';
// import { initialState } from '../store';
import { mergeDeep } from '../lib/utils';

// REDUCERS
export const reviewsMetaReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case ADD_TO_REVIEWS_META: {
            // return Object.assign({}, state, action.payload);
            // const userID = action.payload.userID || '';
            // const reviewsMeta = action.payload.reviewsMeta || '';
            // if (userID) {
            //     return { ...state, [`${userID}`]: {state[userID], {reviewsMeta}}};
            // } else {
            //     return state;
            // }
            return mergeDeep(state, action.payload);
        }
        case UPDATE_REVIEWS_META: {
            // return Object.assign({}, state, action.payload);
            const userID = action.payload.userID || '';
            const ratingFilter = action.payload.ratingFilter ? 'rating' + action.payload.ratingFilter : 'rating5';
            const entity = action.payload.entity || '';
            const entityValue = action.payload.entityValue || '';
            const newObj = {};
            if (entityValue) {
                if (entity === 'finalLastModifiedDate') {
                    // draft[userID][entity][ratingFilter] = entityValue;
                    newObj[`${userID}`] = { finalLastModifiedDate: { [`${ratingFilter}`]: entityValue } };
                } else {
                    // draft[userID][entity] = entityValue;
                    newObj[`${userID}`] = { latestFetchDate: { [`${ratingFilter}`]: entityValue } };
                }
            }
            // if (userID && entity && entityValue) {
            //     return produce(state, draft => {
            //         if (entity === 'finalLastModifiedDate') {
            //             draft[userID][entity][ratingFilter] = entityValue;
            //         } else {
            //             draft[userID][entity] = entityValue;
            //         }
            //     })
            // } else {
            //     return state;
            // }
            return mergeDeep(state, newObj);
        }
        case DELETE_FROM_REVIEWS_META: {
            const reviewId = action.payload || '';
            if (reviewId) {
                let copy = Object.assign({}, state);
                delete copy[reviewId]; //.progress;
                return copy;
            } else {
                return state;
            }
        }
        default:
            return state;
    }
};