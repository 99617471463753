// import fetch from 'isomorphic-unfetch';
import { getIdToken } from 'firebase/auth';
import { auth } from '../lib/db';

export const tokenFetch = async function (url, method = 'GET') {
    let authToken = '';
    try {
        authToken = await getIdToken(auth.currentUser);
        // console.dir(authToken);
    } catch (err) {
        authToken = '';
    }
    // console.dir(`${url}&access_token=${authToken}`);
    return fetch(`${url}&access_token=${authToken}`, {
        method: method,
        // headers: {
        //     // "Content-Type": "application/json",
        //     // 'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        //     // 'Accept': 'application/json, application/xml, text/plain, text/html, *.*'
        //     // 'Authorization': 'Bearer ' + authToken
        // },
    });
}

export const tokenFetchPOST = async function (url, body, headers = {}) {
    let authToken = '';
    try {
        authToken = await getIdToken(auth.currentUser);
        // console.dir(authToken);
    } catch (err) {
        authToken = '';
    }
    // console.dir(authToken);
    return fetch(`${url}&access_token=${authToken}`, {
        method: 'POST',
        body: body,
        headers
        // headers: {
        //     // "Content-Type": "application/json",
        //     // 'content-type': 'application/x-www-form-urlencoded; charset=utf-8',
        //     // 'Accept': 'application/json, application/xml, text/plain, text/html, *.*'
        //     // 'Authorization': 'Bearer ' + authToken
        // },
    });
}