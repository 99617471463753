import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import Router from 'next/router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeftBarOption from './LeftBarOption';
import { spacing, leftSideBar, minScreenSize, maxScreenSize, leftSideBarColor1, leftSideBarColor2 } from '../../lib/theme';
import { staticAssetsPrefix, websiteUrl, sortUserPlaylists, mainDashboardOptions } from '../../lib/utils';

// import { Button } from 'antd';

import NewPlaylist from '../Playlist/NewPlaylist';
import AddPWA from '../Common/AddPWA';
import { PRIVATE, PUBLIC } from '../../actions/types';
import { toggleLeftBar } from '../../actions';
import AppCustomLink from '../Common/AppCustomLink';
import { CloseCircleOutlined } from '@ant-design/icons';

const mainOptions = [{ url: '/home', name: 'Home', icon: 'home' }, { url: '/top-charts/for-you', name: 'Trending', icon: 'gift' },
{ url: '/personal', name: 'Personal', icon: 'crown' }];
const secondMainOptions = [
  { url: '/subscribed-podcasts', name: 'Your Podcasts', icon: 'yourPodcasts' },
  { url: '/new-episodes', name: 'New Episodes', icon: 'newEpisodes' },
  { url: '/my-files', name: 'My Files', icon: 'myFiles' },
  { url: '/favourites', name: 'Favourite Episodes', icon: 'favourites' },
  { url: '/tags', name: 'Tags (Folders)', icon: 'tags' },
  { url: '/recently-played', name: 'Recently Played', icon: 'recently-played' },
  { url: '/bookmarks', name: 'Bookmarks', icon: 'bookmarks' },
  { url: '/followed-playlists', name: 'Followed Playlists', icon: 'newEpisodes' },
];

const background = `${leftSideBarColor1} 0%, ${leftSideBarColor1} 30%,  ${leftSideBarColor2} 80%`;

//List component which loads posts as they come in.
class LeftBar extends React.Component {
  //Renders the list and all the post items returned from the listener.
  constructor(props) {
    super(props);
    this.state = { activeTabUrl: '', activeTabPlaylistId: '' };
    Router.events.on('routeChangeComplete', () => {
      // console.log('done in left bar');
      if (Router.route === '/playlist') {
        this.setState({ activeTabUrl: '', activeTabPlaylistId: '' })
      } else {
        this.setState({ activeTabUrl: Router.route, activeTabPlaylistId: 'random' })
      }
    });
  }

  componentDidMount() {
    // const { url } = this.props;
    const pathName = window.location.pathname;
    const mainUrls = [...mainOptions, ...secondMainOptions];
    for (let mainOption of mainUrls) {
      const url = mainOption.url;
      if (url === '/' && pathName === url) {
        this.setState({ activeTabUrl: url })
      } else if (url !== '/' && pathName.includes(url)) {
        this.setState({ activeTabUrl: url })
      }
    }
    // this.setState({ windowWidth: window.innerWidth });
    // console.log('window : ' + window.innerWidth);
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.windowWidth !== prevState.windowWidth) {
  //     this.setState({ windowWidth: window.innerWidth });
  //   }
  // }

  getActiveTab(mainOptionUrl) {
    const { activeTabUrl } = this.state;
    if (activeTabUrl === mainOptionUrl) {
      return true;
    } else {
      return false;
    }
  }

  getActiveTabPlaylist(playlistId) {
    const { activeTabPlaylistId } = this.state;
    if (activeTabPlaylistId) {
      if (activeTabPlaylistId === playlistId) {
        return true;
      } else {
        return false;
      }
    } else {
      const pathName = window.location.pathname;
      if (pathName.includes('playlist') && pathName.includes(playlistId)) {
        return true;
      } else {
        return false;
      }
    }
  }

  clickOption(url) {
    console.log('clicked : ' + url);
    this.setState({ activeTabUrl: url, activeTabPlaylistId: 'random' })
  }
  clickOptionPlaylist(playlistId) {
    console.log('clicked : ' + playlistId);
    this.setState({ activeTabUrl: '', activeTabPlaylistId: playlistId })
  }

  toggleLeftBar = () => {
    this.props.toggleLeftBar();
  }

  render() {
    const { userDetails, userPlaylists, isDashboard } = this.props;
    // console.dir(userPlaylists);
    if (!userPlaylists) {
      return null;
    }
    let userPlaylistsSorted = sortUserPlaylists(userPlaylists, userDetails); // {};
    // Object
    //   .keys(userPlaylists).sort(function (a, b) {
    //     // return userPlaylists[a].name - userPlaylists[b].name;
    //     return userPlaylists[a].name.localeCompare(userPlaylists[b].name);
    //   })
    //   .forEach(function (key) {
    //     userPlaylistsSorted[key] = userPlaylists[key];
    //   });
    console.dir(userPlaylistsSorted);

    const { loadingUserDetails } = this.props.loadingState;
    const { leftBarOpen, leftBarColor, leftBarText, borderColor, leftBarBgColor } = this.props.settingsState;
    // const { windowWidth } = this.state;
    // console.log('window ' + windowWidth);
    // const leftCloseClass = (!leftBarOpen || windowWidth < 1200) ? 'leftCloseClass' : '';
    // const removeTabs = (!leftBarOpen || windowWidth < 1200) ? 'removeTabs' : '';
    const leftCloseClass = !leftBarOpen ? 'leftCloseClass' : '';
    const removeTabs = !leftBarOpen ? 'removeTabsFalse' : 'removeTabs';
    const empty = {};
    // console.dir(userPlaylists);
    // console.log('leftBarOpen : ' + leftBarOpen);
    return (
      <div className={'left-bar leftCloseClassQuery' + leftCloseClass}>
        <div className='left-bar-wrapper'>
          <div>
            <p className='toggle-button' onClick={this.toggleLeftBar}>
              {!leftBarOpen ? <div className='close'><CloseCircleOutlined /></div>
                : <FontAwesomeIcon className='bars-icon' icon='bars' size='lg' />}
              {/*  */}
            </p>
          </div>
          <div className='logo-wrapper'>
            <AppCustomLink href={isDashboard ? '/' : '/home'}>
              <a style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '2em' }} src={`${staticAssetsPrefix}/static/icons/icons-192.png`} alt='Podurama logo' />
                <h3 style={{ color: 'white', fontWeight: '700', marginBottom: '0' }}>&nbsp;<span style={{ color: 'white' }}>Podurama</span></h3>
              </a>
            </AppCustomLink>
          </div>
          <div style={{ height: '12px' }}></div>
          <div className={'removeTabsQuery' + ' ' + removeTabs}>
            {!isDashboard && mainOptions.map(mainOption => (
              <LeftBarOption type={1} key={mainOption.url} onClick={this.clickOption.bind(this, mainOption.url)} leftBarColor={leftBarColor}
                leftBarText={leftBarText}
                name={mainOption.name} icon={mainOption.icon} url={mainOption.url} activeTab={this.getActiveTab(mainOption.url)} />
            ))}
            {isDashboard && mainDashboardOptions.filter(el => el.url != '/pricing').map(mainOption => (
              <LeftBarOption type={1} key={mainOption.url} onClick={this.clickOption.bind(this, mainOption.url)} leftBarColor={leftBarColor}
                leftBarText={leftBarText} isDashboard={isDashboard}
                name={mainOption.name} icon={mainOption.icon} url={mainOption.url} activeTab={this.getActiveTab(mainOption.url)} />
            ))}
            {/* <p className='title'><b>YOUR HISTORY</b></p> */}
            <div style={{ height: '20px' }}></div>
            {!isDashboard && secondMainOptions.map(mainOption => (
              <LeftBarOption type={2} key={mainOption.url} onClick={this.clickOption.bind(this, mainOption.url)} leftBarColor={leftBarColor}
                leftBarText={leftBarText}
                name={mainOption.name} icon={mainOption.icon} url={mainOption.url} activeTab={this.getActiveTab(mainOption.url)} />
            ))}

            {!isDashboard &&
              <div>
                <p className='title'><b>YOUR PLAYLISTS</b></p>
                {(!loadingUserDetails && userDetails && Object.keys(userPlaylistsSorted).length) ?
                  Object.keys(userPlaylistsSorted)
                    .map((userPlaylistId) => (
                      // <p key={userPlaylist.slug}>test</p>
                      <LeftBarOption key={userPlaylistId} name={userPlaylistsSorted[`${userPlaylistId}`] && userPlaylistsSorted[`${userPlaylistId}`].name}
                        onClick={this.clickOptionPlaylist.bind(this, userPlaylistId)} leftBarColor={leftBarColor} leftBarText={leftBarText}
                        userPlaylistType={userPlaylistsSorted[userPlaylistId] && userPlaylistsSorted[userPlaylistId].type === 'private' ? PRIVATE : PUBLIC}
                        isPlaylistCollaborative={userPlaylistsSorted[userPlaylistId] && userPlaylistsSorted[userPlaylistId].collaborative || false}
                        userPlaylistAdvancedType={userPlaylistsSorted[userPlaylistId] && userPlaylistsSorted[userPlaylistId].advancedType}
                        url='/playlist' playlistId={userPlaylistId} activeTab={this.getActiveTabPlaylist(userPlaylistId)}
                        numEpisodes={userPlaylistsSorted[`${userPlaylistId}`] && userPlaylistsSorted[`${userPlaylistId}`]['guidEpisodeArray'] &&
                          userPlaylistsSorted[`${userPlaylistId}`]['guidEpisodeArray'].length} />
                    )) : ''
                }
                {!loadingUserDetails &&
                  <div className='add-btn'>
                    <NewPlaylist display='btn' podcastDetails={empty} />
                  </div>
                }
                {/* <AddPWA /> */}
              </div>
            }
          </div>
        </div>
        <div className='left-bar-background'></div>

        <style jsx>
          {`
            .close {
              position: absolute;
              right: 12px;
              top: 8px;
            }
            .logo-wrapper {
              // text-align: center;
              padding-left: ${spacing('md')};
              // margin-bottom: ${spacing('lg')};
              background: #051E3424;
              padding-top: ${spacing('md')};
              padding-bottom: ${spacing('md')};
            }
            .logo-wrapper img {
              width: 40%;
            }
            .left-bar{
              // border-radius: 0 1.25em 0 0;
              height: 100%;
              width: ${leftSideBar};
              min-width: 200px;
              position: fixed;
              z-index: 21;
              top: ${this.props.settingsState.topPaddingGlobal}px;
              transition: top 1s; 
              left: 0;
              background-color: ${leftBarBgColor}; // #242B34; // #161B25; // ${leftBarColor};
              overflow-x: hidden;
              // padding-top: ${spacing('sm')};
              border-right: ${this.props.settingsState.themeVal == 1 ? '1px' : '0px'} solid ${borderColor};
            }
            .left-bar:after, .left-bar:before {
                display: block;
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 22;
            }
            .left-bar:before{
                opacity: .1;
                background: #000000;
            }
            // .left-bar:after {
            //     opacity: .85;
            //     // background: #9368E9;
            //     background: -moz-linear-gradient(top, ${background});
            //     background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, ${leftSideBarColor1}), color-stop(50%, ${leftSideBarColor2}));
            //     background: -webkit-linear-gradient(top, ${background});
            //     background: -o-linear-gradient(top, ${background});
            //     background: -ms-linear-gradient(top, ${background});
            //     background: linear-gradient(to bottom, ${background});
            //     // linear-gradient(to bottom,hsla(222, 96%, 6%, 1) 0%,hsla(222, 97%, 6%, 1) 30%,hsla(259, 24%, 27%, 1) 50%)
            //     background-size: 150% 150%;
            //     z-index: 23;
            // }
            .left-bar-wrapper {
                position: relative;
                max-height: calc(100vh - 75px);
                min-height: 100%;
                overflow: auto;
                // width: 260px;
                z-index: 24;
                padding-bottom: 10vh;
            }
            .left-bar-background {
                // background-image:url(${staticAssetsPrefix}/static/images/sidebar/sidebar-5.jpg);
                background-image:url(${staticAssetsPrefix}/static/images/sidebar/bg.png);
                position: absolute;
                z-index: 21;
                height: 70%;
                width: 100%;
                display: block;
                bottom: 0;
                left: 0;
                background-size: 120% 98%;
                background-position: center top;
                background-repeat: no-repeat;
            }
            
            @media ${maxScreenSize.laptop} {
              .leftCloseClassQuery {
                width: 0; // 40px;
                min-width: 0;
                background: none;
              }
              .leftCloseClassQuery p {
                // color: black;
              }
              .removeTabsQuery {
                display: none;
              }
              :global(.close-icon) {
                display: none;
              }
              // :global(.leftCloseClass .close-icon) {
              //   display: block;
              // }
              :global(.leftCloseClass .bars-icon) {
                display: none;
              }
            }
            @media ${minScreenSize.laptop} {
              .toggle-button {
                  display: none;
              }
              :global(.bars-icon) {
                display: none;
              }
              .removeTabsQuery {
                display: block;
              }
            }
            .left-bar.leftCloseClass {
              z-index: 10000;
            }
            .leftCloseClass {
              width: 40px;
              min-width: 0;
              background: none;
            }
            .leftCloseClass p{
              color: black;
            }
            // .removeTabs {
            //   display: none;
            // }
            .removeTabsFalse {
              display: block;
            }
            p {
              color: white;
            }
            .title {
              padding-top: 1em;
              padding-left: 1.5em; // ${spacing('md')};
              font-size: 0.9em;
              margin-top: ${spacing('md')};
              padding-bottom: 0; //${spacing('xs')};
              margin-bottom: 0;
              // color: rgba(0, 0, 0, 0.55);
              color: ${leftBarText};
              opacity: 0.7;
            }
            .add-btn {
              padding: ${spacing()};
            }
            .toggle-button {
              text-align: right;
              margin-right: ${spacing()};
            }
      `}
        </style>
      </div>
    )
  }
}

LeftBar.propTypes = {
  loadingUserDetails: PropTypes.bool,
  isDashboard: PropTypes.bool,
  userPlaylists: PropTypes.object,
  userDetails: PropTypes.object,
  loadingState: PropTypes.object,
  settingsState: PropTypes.object,
  toggleLeftBar: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.userDetails,
    loadingState: state.loadingState,
    userPlaylists: state.userPlaylists,
    settingsState: state.settingsState,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLeftBar: bindActionCreators(toggleLeftBar, dispatch)
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar)