import {
    PERSONAL_VALUES_SUCCESS,
    PERSONAL_VALUES_RATING_SUCCESS, PERSONAL_VALUES_RATING_DELETE,
    PERSONAL_VALUES_PROGRESS_SUCCESS,
    DELETE_FROM_HISTORY,
    PERSONAL_RATINGS_SUCCESS,
    PERSONAL_VALUES_MARK_PLAYED_ACTION,
    PERSONAL_VALUES_MARK_UNPLAYED_ACTION,
    PERSONAL_VALUES_MARK_ALL_UNPLAYED_ACTION
}
    from '../actions/types';

// import { mergeDeep } from '../lib/utils';

// const personalValuesState = (state = [], action) => {
//     switch (action.type) {
//         case PERSONAL_VALUES_SUCCESS:
//             return Object.assign({}, state, action.payload);
//         case PERSONAL_VALUES_RATING_SUCCESS:
//             return mergeDeep(state, action.payload);
//         case PERSONAL_VALUES_RATING_DELETE: {
//             // return mergeDeep(state, action.payload);
//             return produce(state, draft => {
//                 delete draft[`${action.payload}`].rating;
//             });
//         }
//         case PERSONAL_VALUES_PROGRESS_SUCCESS:
//             // return Object.assign({}, state, action.payload);
//             return mergeDeep(state, action.payload);
//         case DELETE_FROM_HISTORY: {
//             let copy = Object.assign({}, state);
//             if (action.payload && typeof copy[action.payload].progress !== 'undefined') {
//                 delete copy[action.payload].progress;
//             }
//             // console.dir(copy);
//             return copy;
//         }
//         default:
//             return state
//     }
// }

export const personalDetailsReducer = (state = {}, action) => {
    switch (action.type) {
        // case LAST_PLAYED_EPISODE:
        //   return Object.assign({}, state, { lastPlayedEpisode: lastEpisodeState(state.lastEpisodeState, action) });
        case PERSONAL_VALUES_SUCCESS:
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            return Object.assign({}, state, action.payload);
        case PERSONAL_VALUES_PROGRESS_SUCCESS: {
            // console.dir(Object.assign({}, state, action.payload));
            // return Object.assign({}, state, action.payload);
            let personalValuesSpecific = state[`${action.guidCleaned}`] || {};
            const returnMap1 = { ...personalValuesSpecific, ...action.personalProgressUpdate };
            return {
                ...state, [`${action.guidCleaned}`]: returnMap1
            };
        }
        case DELETE_FROM_HISTORY: {
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            let copy = Object.assign({}, state);
            if (action.payload && typeof copy[action.payload].progress !== 'undefined') {
                delete copy[action.payload]; //.progress;
            }
            // console.dir(copy);
            return copy;
        }
        default:
            return state;
    }
};

export const personalMarkPlayedReducer = (state = {}, action) => {
    switch (action.type) {
        case PERSONAL_VALUES_MARK_PLAYED_ACTION: {
            return {
                ...state, ...action.personalMarkPlayedUpdate
            };
        }
        case PERSONAL_VALUES_MARK_UNPLAYED_ACTION: {
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            let copy = Object.assign({}, state);
            if (action.guidCleaned && typeof copy[action.guidCleaned] !== 'undefined') {
                delete copy[action.guidCleaned]; //.progress;
            }
            // console.dir(copy);
            return copy;
        }
        case PERSONAL_VALUES_MARK_ALL_UNPLAYED_ACTION: {
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            let copy = Object.assign({}, state);
            if (action.guidsCleanedToDelete) {
                for (const guidCleaned of action.guidsCleanedToDelete) {
                    if (guidCleaned && typeof copy[guidCleaned] !== 'undefined') {
                        delete copy[guidCleaned]; //.progress;
                    }
                }
            }
            // console.dir(copy);
            return copy;
        }
        default:
            return state;
    }
};

export const personalRatingsReducer = (state = {}, action) => {
    switch (action.type) {
        // case LAST_PLAYED_EPISODE:
        //   return Object.assign({}, state, { lastPlayedEpisode: lastEpisodeState(state.lastEpisodeState, action) });
        case PERSONAL_RATINGS_SUCCESS:
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            return Object.assign({}, state, action.payload);
        case PERSONAL_VALUES_RATING_SUCCESS: {
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            // return mergeDeep(state, action.payload);
            return Object.assign({}, state, action.payload);
            // const obj = action.payload;
            // const key = obj && Object.keys(obj)[0];
            // if (key) {
            //     const newObj = {};
            //     newObj['rating'] = obj[key].rating;
            //     if (obj[key].collectionId) {
            //         newObj['collectionId'] = obj[key].collectionId;
            //     }
            //     return { ...state, [key]: { ...state[`${key}`], ...newObj } };
            // } else {
            //     return state;
            // }
        }
        case PERSONAL_VALUES_RATING_DELETE: {
            let copy = Object.assign({}, state);
            if (action.payload && typeof copy[action.payload] !== 'undefined') {
                delete copy[action.payload]; //.progress;
            }
            // console.dir(copy);
            return copy;
            // // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            // return produce(state, draft => {
            //     delete draft[`${action.payload}`].rating;
            // });
        }
        case DELETE_FROM_HISTORY: {
            // return Object.assign({}, state, { personalValues: personalValuesState(state.personalValues, action) });
            let copy = Object.assign({}, state);
            if (action.payload && typeof copy[action.payload] !== 'undefined') {
                delete copy[action.payload]; //.progress;
            }
            // console.dir(copy);
            return copy;
        }
        default:
            return state;
    }
};