import { combineReducers } from 'redux';
import { podcastReducer, getRequiredPodcastsFn, yourPodcastSettingsReducer, podcastExtraDetailsReducer, clearBadgesReducer } from './podcastReducer';
import { episodeReducer } from './podcastReducer';
import { dbPodcastReducer } from './podcastReducer';
import { sidePodcastReducer } from './podcastReducer';
import { userDetailsReducer } from './userDetailsReducer';
import { followedPlaylistReducer, playlistReducer } from './playlistReducer';
import { loadingReducer, getRequiredLoadersFn } from './loadingReducer';
import { errorsReducer, getRequiredErrorsFn } from './errorsReducer';
import { audioReducer } from './audioReducer';
import { audioPlayReducer } from './audioReducer';
import { recentPlayedReducer } from './recentPlayedReducer';
import { playlistMainReducer } from './playlistMainReducer';
import { episodeReviewsReducer } from './episodeReviewsReducer';
import { episodeReviewsMetaReducer } from './episodeReviewsMetaReducer';
import { reviewRepliesReducer } from './reviewRepliesReducer';
import { reviewReducer } from './reviewReducer';
import { reviewsMetaReducer } from './reviewsMetaReducer';
import { recentPodcastsReducer } from './recentPodcastsReducer';
import { personalDetailsReducer, personalRatingsReducer, personalMarkPlayedReducer } from './personalDetailsReducer';
// import { episodesReducer } from './episodesReducer';

import { settingsReducer } from './settingsReducer';
import { searchReducer } from './searchReducer';
import { homePodcastsReducer, homePodcastsMainReducer, popularEpisodesReducer } from './homePodcastsReducer';
import { followedPodcastTagReducer, podcastTagReducer, searchAlertsReducer } from './podcastTagReducer';
import { episodeBookmarksReducer } from './episodeBookmarksReducer';
import { userInterestsReducer } from './userInterestsReducer';
import { relatedProductsReducer, relatedPodcastsReducer } from './relatedProductsReducer';
import { manualQueueReducer, autoQueueReducer, autoQueuePlaylistIdNotifierReducer } from './queueReducer';
import { profileReducer } from './profileReducer';
import { blogListsReducer, featuredListsReducer } from './featuredListsReducer';
import { adPodcastsReducer } from './adPodcastsReducer';
import { myFilesReducer, myFileUploadsReducer, myFileUploadProgressReducer } from './myFilesReducer';
import { autoChaptersReducer, autoChapterInsightsReducer } from './autoChaptersReducer';

const appReducer = combineReducers({
    /* your app’s top-level reducers */
    podcastState: podcastReducer,
    podcastExtraDetails: podcastExtraDetailsReducer,
    clearBadgesMap: clearBadgesReducer,
    yourPodcastSettingsState: yourPodcastSettingsReducer,
    dbPodcastState: dbPodcastReducer,
    sidePodcasts: sidePodcastReducer,
    latestEpisodes: episodeReducer,
    featuredLists: featuredListsReducer,
    adPodcasts: adPodcastsReducer,
    blogLists: blogListsReducer,
    myFileEpisodes: myFilesReducer,
    autoChapters: autoChaptersReducer,
    autoChapterInsights: autoChapterInsightsReducer,
    myFileUploadStatus: myFileUploadsReducer,
    myFileUploadProgress:
        myFileUploadProgressReducer,
    // episodes: episodesReducer,
    allHomePodcastsData: homePodcastsReducer,
    allPopularEpisodesData: popularEpisodesReducer,
    homePodcastsMain: homePodcastsMainReducer,
    userDetails: userDetailsReducer,
    userPlaylists: playlistReducer,
    userPlaylistsMain: playlistMainReducer,
    followedPlaylists: followedPlaylistReducer,
    followedProfiles: profileReducer,
    loadingState: loadingReducer,
    errorsState: errorsReducer,
    audioState: audioReducer,
    audioPlayState: audioPlayReducer,
    searchAlertsState: searchAlertsReducer,
    podcastTags: podcastTagReducer,
    followedPodcastTags: followedPodcastTagReducer,
    episodeBookmarks: episodeBookmarksReducer,
    relatedProducts: relatedProductsReducer,
    similarPodcasts: relatedPodcastsReducer,
    userInterests: userInterestsReducer,
    recentPlayedEpisodes: recentPlayedReducer,
    episodeReviewsState: episodeReviewsReducer,
    episodeReviewsMetadata: episodeReviewsMetaReducer,
    recentPodcasts: recentPodcastsReducer,
    reviewRepliesState: reviewRepliesReducer,
    reviewsState: reviewReducer,
    reviewsMetadata: reviewsMetaReducer,
    personalValues: personalDetailsReducer,
    personalRatingsValues: personalRatingsReducer,
    personalMarkPlayedValues: personalMarkPlayedReducer,
    searchState: searchReducer,
    settingsState: settingsReducer,
    episodesManualQueue: manualQueueReducer,
    episodesAutoQueue: autoQueueReducer,
    autoQueuePlaylistIdNotifier: autoQueuePlaylistIdNotifierReducer,
})

export const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT_USER') {
        state = undefined
    }
    return appReducer(state, action);
}

// export const getRequiredPodcasts = (state, filter) => getRequiredPodcastsFn(state.podcastState, filter);
// export const getRequireddbPodcasts = (state, id) => getRequireddbPodcastsFn(state.dbPodcastState, id);
// export const getRequiredLoaders = (state, ...loaders) => getRequiredLoadersFn(state.loadingState, ...loaders);
// export const getRequiredErrors = (state, errorType) => getRequiredErrorsFn(state.errorsState, errorType);