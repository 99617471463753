import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Modal, notification } from 'antd'
import React, { Component } from 'react'
import { getPremiumInfo, getPremiumInfoData } from '../../actions';
import { logPremiumOpen } from '../../lib/analyticsEvents';
import { getlocationInfo } from '../../lib/fileUtils';
import PremiumPage from './PremiumPage';

// const options = [
//     // { 'title': '3', 'price_id': 'price_1M1nV3Eui0QeTi3US6GzIZP3' },
//     // { 'title': '1', 'price_id': 'price_1M1nV3Eui0QeTi3UDemgN5hI' },
//     { 'title': '3', 'price_id': 'price_1M8LMGEui0QeTi3UKcSCjByg' },
//     { 'title': '6', 'price_id': 'price_1M1m6AEui0QeTi3UDExxhB2u' },
//     { 'title': '12', 'price_id': 'price_1M1m6AEui0QeTi3UY4Xv19Wg' },
// ];

export default class Premium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // typeId: props.typeId,
            show: false,
            visible: false,
            // confirmLoading: false,
            forgotPasswordSuccess: false,
            isModalClosable: true,
            keyValue: 1,
            ballAnim: true,
            // showWaitlistForm: false,
            // showPrivacyForm: false,
            // showTermsConditionsForm: false,
            errorObj: {}
        }
    }

    componentDidMount() {
        console.dir(window.location);
        if (window.location && window.location.href.includes('premium-offer-redeem')) {
            this.setState({ show: false });
        } else {
            this.setState({ show: true });
        }
        setTimeout(() => {
            this.setState({ ballAnim: false });
        }, 2000);
        setInterval(() => {
            this.setState({ ballAnim: true });
            setTimeout(() => {
                this.setState({ ballAnim: false });
            }, 2000);
        }, 10 * 60 * 1000);
    }

    showModal = () => {
        logPremiumOpen('subscriptionModal');
        this.setState({
            visible: true,
        });
    }

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setState({
            visible: false, forgotPasswordSuccess: false, errorObj: {}
        });
    }

    render() {
        const { visible, isModalClosable, keyValue, show, ballAnim } = this.state;
        if (!show) {
            return <></>
        }
        const date = new Date().getDate();
        return (
            <div>
                <Button className={`third-button`} style={{ padding: '0 12px' }} onClick={this.showModal}>
                    <div className={ballAnim ? 'ball' : ''} style={{ display: 'inline-block', position: 'absolute' }}>
                        <FontAwesomeIcon style={{
                            // position: 'absolute', color: 'white',
                            // right: '8px', top: '8px',
                        }} icon={'gift'} /></div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{date % 2 == 1 ? 'Premium' : '50% Off'}
                </Button>
                <Modal className='auth-modal'
                    // title={typeId == 'signIn' ? type: 'Register'}
                    visible={visible}
                    // onOk={this.handleOk}
                    bodyStyle={{ padding: 0, position: 'relative' }}
                    // confirmLoading={confirmLoading}
                    closable={isModalClosable}
                    onCancel={this.handleCancel}
                    maskClosable={isModalClosable}
                    afterClose={this.afterClose}
                    footer={null}
                >
                    <PremiumPage productIds={['prod_RiYUazyO0kG3Kf', 'prod_RiYRUbuP7lPlkV']} initIndex={1} />
                </Modal>
                <style jsx>
                    {`
                    .ball {
                        // width: 50px;
                        // height: 50px;
                        // background-color: red;
                        // border-radius: 50%;
                        // position: absolute;
                        bottom: 4px;
                        // left: 50%;
                        transform: translateY(0px);
                        animation: bounce 2s ease-in-out;
                    }

                    @keyframes bounce {
                    0%,50%, 100% { bottom: 4px; }
                    25% { bottom: 14px; } 
                    75% { bottom: 8px; } 
                        // 0%, 100% { translateY(0px); }
                        // 50% { translateY(-12px); } /* Adjust height for stronger bounce */
                    }

                    `}
                </style>
            </div>
        )
    }
}
