import { firestore } from '../lib/db';
import { cleanFirebaseId, getUUID, serverApiEurope } from '../lib/utils';
import { createReviewId } from '../lib/reviewUtils';
import { tokenFetch } from '../lib/tokenUtils';
import { logLikeReview } from '../lib/analyticsEvents';
import { deleteField, collection, query, where, getDoc, doc, setDoc, getDocs } from "firebase/firestore/lite";
import {
    FETCH_BOOKMARK_ACTION, ADD_BOOKMARK_ACTION, DELETE_BOOKMARK_ACTION
}
    from './types';

const bookmarksDocId = (userUID, guid) => {
    return getUUID(guid) + '_' + userUID;
}

let alreadyFetchedAllBookmarks = false;
export let bookmarksMetadata = {};
// ThunkAction<AppState> fetchAllEpisodeBookmarks(String userUID) {
//   return (Store<AppState> store) async {
export const fetchAllEpisodeBookmarks = (userUID) => async (dispatch, getState) => {
    if (alreadyFetchedAllBookmarks) {
        return;
    }
    alreadyFetchedAllBookmarks = true;
    // store.dispatch(LoadingBookmarksAction({ 'loadingBookmarks': true }));
    // FirebaseFirestore.instance
    //     .collection('bookmarks')
    //     // .doc(docId)
    //     .where('userID', isEqualTo: userUID)
    //     .get()
    console.dir(userUID);
    getDocs(query(collection(firestore, "bookmarks"), where("userID", "==", userUID)))
        // getDocs(collection(firestore, `bookmarks`), where('userID', '==', userUID))
        .then((snapshot) => {
            snapshot.docs.forEach((doc) => {
                if (doc.exists) {
                    const data = doc.data();
                    const bookmarkData = data['bookmarks'] ?? {};
                    // print('bookmarkData: ' + bookmarkData.toString());
                    bookmarksMetadata[data['guid']] = data;
                    console.dir(data);
                    // store.dispatch(FetchBookmarkAction(data['guid'], bookmarkData));
                    dispatch({
                        'type': FETCH_BOOKMARK_ACTION,
                        'guid': data.guid,
                        'bookmarkData': bookmarkData
                    });
                }
            });
            // store.dispatch(LoadingBookmarksAction({ 'loadingBookmarks': false }));
        }).catch((error) => {
            // store.dispatch(LoadingBookmarksAction({ 'loadingBookmarks': false }));
            console.error(error);
        });
}

// ThunkAction < AppState > fetchEpisodeBookmarks(PodcastEpisode episode) {
//     return (Store < AppState > store) async {
export const fetchEpisodeBookmarks = (episode) => async (dispatch, getState) => {
    const episodeBookmarks = getState().episodeBookmarks; // episodeBookmarksSelector(store.state);
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    const userUID = userDetails['uid'];
    const docId = bookmarksDocId(userUID, episode.guid);
    if (`${episode.guid}` in episodeBookmarks) {
        return;
    }
    getDoc(doc(firestore, `bookmarks`, docId))
        .then((doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const bookmarkData = data['bookmarks'] || {};
                // print('bookmarkData: ' + bookmarkData.toString());
                // store.dispatch(FetchBookmarkAction(episode.guid, bookmarkData));
                dispatch({
                    'type': FETCH_BOOKMARK_ACTION,
                    'guid': episode.guid,
                    'bookmarkData': bookmarkData
                });
            }
        }).catch((error) => {
            // co("Error adding document: " + error.toString());
            console.error(error);
        });
}

//   ThunkAction<AppState> addEpisodeBookmark(
//       PodcastEpisode episode, Map bookmarkData) {
//     return (Store<AppState> store) async {
export const addEpisodeBookmark = (episode, bookmarkData) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    const docId = bookmarksDocId(userUID, episode.guid);

    const data = { 'userID': userUID, 'guid': episode.guid, 'lastUpdated': Date.now() };
    data['bookmarks'] = bookmarkData;
    // utils.printLog('data10: ' + data.toString());
    // store.dispatch(AddBookmarkAction(episode.guid, bookmarkData));
    dispatch({
        'type': ADD_BOOKMARK_ACTION,
        'guid': episode.guid,
        'bookmarkData': bookmarkData
    });
    console.dir(docId);
    console.dir(data);

    setDoc(doc(firestore, 'bookmarks', docId), data, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
}

//   ThunkAction<AppState> deleteEpisodeBookmark(
//       PodcastEpisode episode, String timeId) {
//     return (Store<AppState> store) async {
export const deleteEpisodeBookmark = (episode, timeId) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    const docId = bookmarksDocId(userUID, episode.guid);

    const data = {};
    data['bookmarks'] = {};
    data['bookmarks'][timeId] = deleteField();
    // utils.printLog('data10: ' + data.toString());
    // store.dispatch(DeleteBookmarkAction(episode.guid, timeId));
    dispatch({
        'type': DELETE_BOOKMARK_ACTION,
        'guid': episode.guid,
        'timeId': timeId
    });

    setDoc(doc(firestore, 'bookmarks', docId), data, { merge: true })
        // .then((data) {})
        .catch((error) => {
            console.error(error);
        });
}