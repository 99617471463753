// import firebase from '@firebase/app';
// import '@firebase/firestore';
// import '@firebase/auth';
// import '@firebase/analytics';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';
// import 'firebase/compat/analytics';

import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";
import { initializeAnalytics } from 'firebase/analytics';
import { getStorage } from "firebase/storage";

// import { analytics } from "firebase/analytics";


// export function loadDB() {
//   try {
//     var config = {
//       apiKey: "AIzaSyCy37FaXZkQ-qs0d79m4_Nkt4LHmcykrNM",
//       authDomain: "podurama.com", // "podcastapp-767c2.firebaseapp.com",
//       databaseURL: "https://podcastapp-767c2.firebaseio.com",
//       projectId: "podcastapp-767c2",
//       storageBucket: "podcastapp-767c2.appspot.com",
//       messagingSenderId: "688171323830"
//     };
//     firebase.initializeApp(config);
//   } catch (err) {
//     // we skip the "already exists" message which is
//     // not an actual error when we're hot-reloading
//     if (!/already exists/.test(err.message)) {
//       console.error('Firebase initialization error', err.stack);
//     }
//   }

//   return firebase;
// }

let auth;
let firestore;
let storage;
let analytics;
try {
  var config = {
    apiKey: "AIzaSyCy37FaXZkQ-qs0d79m4_Nkt4LHmcykrNM",
    authDomain: "podcastapp-767c2.firebaseapp.com", // "app.podurama.com", // "podcastapp-767c2.firebaseapp.com",
    databaseURL: "https://podcastapp-767c2.firebaseio.com",
    projectId: "podcastapp-767c2",
    storageBucket: "podcastapp-767c2.appspot.com",
    messagingSenderId: "688171323830",
    appId: "1:688171323830:web:d4f1f3c91dff2f1431f117",
    measurementId: "G-9V52MBB3VE"
  };
  // if (!firebase.apps.length) {
  //   firebase.initializeApp(config);
  //   // firebase.analytics();
  // }
  const firebaseApp = initializeApp(config);
  auth = getAuth(firebaseApp);
  firestore = getFirestore(firebaseApp);
  storage = getStorage(firebaseApp);



  if (typeof window !== "undefined") {
    // firebase.firestore().enablePersistence()
    //   .catch(function (err) {
    //     if (err.code == 'failed-precondition') {
    //       // Multiple tabs open, persistence can only be enabled
    //       // in one tab at a a time.
    //       // ...
    //     } else if (err.code == 'unimplemented') {
    //       // The current browser does not support all of the
    //       // features required to enable persistence
    //       // ...
    //     }
    //   });
  }
  // if (isSupported()) {
  /*************always put analytics last as it can sometimes throw an error */
  analytics = initializeAnalytics(firebaseApp);
  // }

} catch (err) {
  // we skip the "already exists" message which is
  // not an actual error when we're hot-reloading
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}

export { auth, onAuthStateChanged, firestore, storage, analytics };