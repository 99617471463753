import React from 'react'
import PropTypes from 'prop-types';
import Link from 'next/link';

const subDomain = ''; // '/app';

export default function AppCustomLink({ children, href, as, ...rest }) {
    return (
        <>
            {as ?
                <Link href={process.env.NODE_ENV === "production" ? `${subDomain}${href}` : href}
                    as={process.env.NODE_ENV === "production" ? `${subDomain}${as}` : as}
                    {...rest}>
                    {children}
                </Link> :
                <Link href={process.env.NODE_ENV === "production" ? `${subDomain}${href}` : href} {...rest}>
                    {children}
                </Link>
            }
        </>
    )
}

AppCustomLink.propTypes = {
    children: PropTypes.any,
    href: PropTypes.any,
    as: PropTypes.any,
}