import { collection, doc, getDoc, getDocs, limit, query, setDoc, where } from 'firebase/firestore/lite';
import { firestore } from '../lib/db';
import { tokenFetch, tokenFetchPOST } from '../lib/tokenUtils';
import { createRandomShortString, serverApiEurope } from '../lib/utils';
import { USER_DASHBOARD_PLAN } from './types';
import { setDashboardPlanId } from '../lib/fileUtils';

export const getShareUrl = async (userUID, uuid) => {
    return getDocs(query(collection(firestore, "hostShare"), where('userID', '==', userUID), where('uuid', '==', uuid)), limit(1))
        .then(function (snapshot) {
            let shareDocId;
            // if (snapshot.empty) {
            //     docID = createRandomShortString(5);
            //     setDoc(doc(firestore, `hostShare`, docID), { userID: userUID, uuid, 'public': true }, { merge: true })
            // } else {
            let returnData = {};
            snapshot.docs.forEach(document => {
                const data = document.data();
                shareDocId = document.id;
                console.dir(shareDocId);
                // setDoc(doc(firestore, `hostShare`, shareDocId), { 'public': true }, { merge: true })
                returnData = { shareDocId, isPublic: data.public };
            })
            // }
            console.dir(returnData);
            return returnData;
        })
}
export const toggleShareUrl = async (docID, data) => {
    // { 'public': publicVal }
    setDoc(doc(firestore, `hostShare`, docID), data, { merge: true })
}


export const fetchHostPodcasts = async (userUID) => {
    return getDoc(doc(firestore, `hostPrompts`, userUID))
        .then(function (docLoc) {
            if (docLoc.exists) {
                const docData = docLoc.data() || {};
                return docData['podcasts'] || {};
            }
        })
}
export const saveHostPodcast = async (userUID, podcastData) => {
    setDoc(doc(firestore, `hostPrompts`, userUID), { 'podcasts': { [podcastData['collectionId']]: podcastData } }, { merge: true })
}

export const fetchDashboardLinkLogs = async (slug) => {
    // let url = `http://localhost:5001/podcastapp-767c2/europe-west1/fetchDashboardLinkLogs`;
    let url = `${serverApiEurope}fetchDashboardLinkLogs`;
    url += `?slug=${slug}`;
    // if (uuid) {
    //     url += `&uuid=${uuid}`
    // }
    console.dir(url);
    // const body = { guid, audioUrl }
    return tokenFetch(url)
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json();
            return data;
        }).catch((err) => {
            throw err;
        });
}

export const setDashboardSlug = async (collectionId, slug, internalName, uuid) => {
    // let url = `http://localhost:5001/podcastapp-767c2/europe-west1/setDashboardSlug`;
    let url = `${serverApiEurope}setDashboardSlug`;
    url += `?collectionId=${collectionId}&slug=${slug}&internalName=${internalName}`;
    if (uuid) {
        url += `&uuid=${uuid}`
    }
    console.dir(url);
    // const body = { guid, audioUrl }
    return tokenFetch(url)
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json();
            return data;
        }).catch((err) => {
            throw err;
        });
}
export const getDashboardSlugs = async (userUID) => {
    return getDoc(doc(firestore, `hostLinks`, userUID))
        .then(function (docLoc) {
            if (docLoc.exists) {
                const docData = docLoc.data() || {};
                return docData['links'] || {};
            }
        })
}
export const getEpisodeFromDashboardSlug = async (slug) => {
    return getDocs(query(collection(firestore, "episodes"), where(`dashboardSlugs.${slug}`, '==', true)), limit(1))
        // return getDoc(doc(firestore, `hostLinks`, userUID))
        .then(function (snapshot) {
            let returnData = null;
            if (snapshot.empty) {
                return returnData;
            } else {
                snapshot.docs.forEach(document => {
                    const data = document.data();
                    // setDoc(doc(firestore, `hostShare`, shareDocId), { 'public': true }, { merge: true })
                    returnData = data;
                })
            }
            console.dir(returnData);
            return returnData;
        }).catch(err => {
            return null;
        })
}
export const getShowFromDashboardSlug = async (slug) => {
    console.dir(slug);
    return getDocs(query(collection(firestore, "podcasts"), where(`dashboardSlugs.${slug}`, '==', true)), limit(1))
        // return getDoc(doc(firestore, `hostLinks`, userUID))
        .then(function (snapshot) {
            let returnData = null;
            if (snapshot.empty) {
                return returnData;
            } else {
                snapshot.docs.forEach(document => {
                    const data = document.data();
                    // setDoc(doc(firestore, `hostShare`, shareDocId), { 'public': true }, { merge: true })
                    returnData = data;
                })
            }
            console.dir(returnData);
            return returnData;
        }).catch(err => {
            console.error(err);
            return null;
        })
}


export const fetchPrevTranscripts = async (userUID) => {
    return getDoc(doc(firestore, `hostUsage`, userUID))
        .then(function (docLoc) {
            if (docLoc.exists) {
                const docData = docLoc.data() || {};
                return docData['transcripts'] || {};
            }
        })
}

export let billingDataGlobal = {};
export const fetchHostBilling = async (userUID) => {
    return getDoc(doc(firestore, `hostPremium`, userUID))
        .then(function (docLoc) {
            console.dir(docLoc);
            if (docLoc.exists) {
                const docData = docLoc.data() || { planId: 1 };
                console.dir(docData);
                if (docData && docData.premium && docData.premium.endDate > Date.now()) {
                    // dispatch(setDashboardPlan(docData.planId || 1));
                    setDashboardPlanId(docData.planId || 1);
                }
                billingDataGlobal = docData;
                return docData;
            } else {
                return { planId: 1 };
            }
        }).catch(err => {
            console.dir(err);
            return { planId: 1 };
        })
}

export const setDashboardPlan = (planId) => async (dispatch, getState) => {
    dispatch({
        type: USER_DASHBOARD_PLAN,
        payload: planId,
    })
}


export const fetchShareTranscription = async (shareDocId) => {

    const url = `${serverApiEurope}fetchTranscription?shareDocId=${shareDocId}`;

    // const body = { guid, audioUrl }
    return tokenFetch(url)
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json();
            return data;
        }).catch((err) => {
            throw err;
        });
}

export const createTranscription = async (guid, audioUrl) => {

    const url = `${serverApiEurope}createTranscriptionForHost?rand=1`;

    const body = { guid, audioUrl }
    return tokenFetchPOST(url,
        JSON.stringify(body))
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json() || {};
            return data; //['transcriptFinal'];
        }).catch((err) => {
            throw err;
        });
}

export const fetchDashboardData = async (uuid, type) => {

    const url = `${serverApiEurope}fetchDashboardData?rand=1`;

    // const body = { guid, audioUrl }
    return tokenFetch(url + `&uuid=${uuid}&type=${type}`)
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json();
            console.dir(data);
            return data['data'];
        }).catch((err) => {
            throw err;
        });
}

export const saveHostChanges = async (uuid, type, data) => {

    const url = `${serverApiEurope}saveHostChanges?rand=1`;

    const body = { uuid, type, data }
    return tokenFetchPOST(url,
        JSON.stringify(body))
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json();
            return data['transcriptFinal'];
        }).catch((err) => {
            throw err;
        });
}

export const rewriteContent = async (uuid, type, prompt) => {
    const url = `${serverApiEurope}` +
        `createGenericFromChapters?uuid=${uuid}&type=${type}&prompt=${encodeURIComponent(prompt)}`;
    return tokenFetch(url)
        .then(async (dataInit) => {
            console.dir(dataInit);
            const data = await dataInit.json();
            console.dir(data);
            return data['value'];
        }).catch((err) => {
            throw err;
        });
}