import { FETCH_FOLLOWED_PROFILES, FOLLOW_PROFILE, UNFOLLOW_PROFILE } from "../actions/types";


export const profileReducer = (state = {}, action) => {
    // console.log(action);
    switch (action.type) {
        case FETCH_FOLLOWED_PROFILES:
            return Object.assign({}, state, action.payload);
        case FOLLOW_PROFILE:
            return Object.assign({}, state, { [`${action.uid}`]: action.payload });
        case UNFOLLOW_PROFILE: {
            let uid = action.uid;
            const copy = { ...state };
            delete copy[uid];
            return copy;
        }
        default:
            return state;
    }
}