import fetchJsonp from 'fetch-jsonp';
import {
  setItunesData, getItunesData
} from './utils';

export function searchItunes(podcastName, limit = 10) {
  // const escapedUrl = encodeURIComponent(feedUrl);
  // const podcastNameCleaned = podcastName.replace(/&/g, ' ').replace(/%/g, ' ');

  return `https://itunes.apple.com/search?term=${encodeURIComponent(podcastName)}&media=podcast&entity=podcast&limit=${limit}`; //`https://api.rss2json.com/v1/api.json?rss_url=${escapedUrl}&api_key=${apiKey}&count=${count}&offset=${offset}`;
}

export function itunesByCollectionId(collectionId) {
  // const escapedUrl = encodeURIComponent(feedUrl);

  return `https://itunes.apple.com/lookup?id=${collectionId}`;
}

export const getItunesDataServerByCollectionId = async (collectionId) => {
  try {
    console.log('collectionId in getItunesDataServerByCollectionId : ' + collectionId);
    const response = await fetch(itunesByCollectionId(collectionId));
    const data = await response.json();
    const itunesData = (data && data.resultCount > 0 && data.results.length && data.results[0]) || {};
    return itunesData;
  } catch (err) {
    console.log(JSON.stringify(err));
    throw err;
  }
}

export const getItunesDataByCollectionId = async (collectionId) => {
  const itunesExistingData = getItunesData(collectionId);
  if (itunesExistingData) {
    // console.dir(itunesExistingData);
    return itunesExistingData;
  }
  return fetchJsonp(itunesByCollectionId(collectionId))
    .then(response => response.json())
    .then(data => {
      // console.dir(data);
      const itunesData = (data && data.resultCount > 0 && data.results.length && data.results[0]) || {};
      setItunesData(collectionId, itunesData);
      // itunesData = data;
      // this.setState({ itunesLoaded: true, itunesData: data && data.resultCount > 0 && data.results.length && data.results[0] || {} })
      return itunesData;
    })
    .catch(err => {
      console.error(err);
      // this.setState({ itunesData: {} })
      return {};
    })
}