import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCurrency, getMonthCount, getMonthCountDisplay, getOriginalPrice, getPrice, offerMonths } from '../../lib/utils';

const annualCosts = { 'USD': 1299, 'GBP': 999, 'AUD': 1299, 'CAD': 1299, 'EUR': 999 };
export default class PremiumCard extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         // premiumInfo: undefined,
    //         // loadingPrices: true,
    //         // isRecurring: false,
    //         options: [],
    //         errorObj: {}
    //     }
    // }

    getOfferDiscount(option) {
        const { discount } = this.props;
        if (discount == 1) {
            return 0;
        }
        if (discount > 0) {
            return discount;
        }
        if (option.unit_amount > 4000) {
            return 50;
        }
        const currency = getCurrency();
        const rawAnnualCost = annualCosts[currency] || annualCosts['USD'];
        const fullAmount = (option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount'];
        console.dir(fullAmount);
        console.dir(rawAnnualCost);
        const possCost = (rawAnnualCost * (offerMonths / 12));
        return Math.ceil(100 * (possCost - fullAmount) / possCost);
    }
    getDiscount(option) {
        if (option.unit_amount > 4000) {
            return 50;
        }
        const { options, discount } = this.props;
        const lowestOption = options[0];
        console.dir(options);
        const currency = getCurrency();
        const fullAmount = (option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount'];
        const lowestTerm = getMonthCount(lowestOption);
        console.dir(lowestTerm);
        const lowestFullAmount = (lowestOption['currency_options'][currency.toLowerCase()] || lowestOption['currency_options']['usd'])['unit_amount'];
        // const fullAnnualAmount = 
        const term = getMonthCount(option);
        const possCost = (lowestFullAmount * (term / lowestTerm));
        return Math.ceil(100 * (possCost - fullAmount) / possCost);
    }
    render() {
        const { option, index, isOffer, selectedIndex, discount } = this.props;
        return (
            <div>
                <div key={option.id} className='card' onClick={this.props.choosePremium.bind(this, index)}>
                    {(isOffer && this.getOfferDiscount(option)) ? <div className='discount'>
                        -{this.getOfferDiscount(option)}&#37;
                    </div> : <></>}
                    {(!isOffer && this.getDiscount(option)) ? <div className='discount'>
                        -{this.getDiscount(option)}&#37;
                    </div> : <div>&nbsp;</div>}
                    {selectedIndex == index &&
                        <FontAwesomeIcon style={{
                            position: 'absolute', color: '#E03145',
                            right: '8px', top: '8px',
                        }} icon={'check-circle'} />}
                    <h3>{getMonthCountDisplay(option, index)}</h3>
                    <h4>{getMonthCount(option, index) > 200 ? 'Offer' : 'Months'}</h4>
                    <div className='prices'>
                        {discount > 1 && <h4 className='mb-0'><s>{getOriginalPrice(option, discount)}</s>&nbsp;</h4>}
                        <h4 style={{ fontSize: '1.25em' }}>{getPrice(option)}</h4>
                    </div>
                </div>

                <style jsx>
                    {`
                    .prices {
                        // display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .discount {
                        background: green;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 2px 4px;
                    }
                .card {
                    // background: #956eff;
                    box-shadow: 0 20px 40px 0 rgba(0,11,40,.14);
                    padding: 16px;
                    margin: 24px 16px;
                    border-radius: 12px;
                    color: white;
                    text-align: center;
                    position: relative;
                    width: 120px;
                }
                .card h3,h4 {
                    // color: white !important;
                }
            `}
                </style>
            </div>
        )
    }
}

PremiumCard.propTypes = {
    option: PropTypes.object,
    index: PropTypes.number,
    discount: PropTypes.number,
    options: PropTypes.array,
    selectedIndex: PropTypes.number,
    isOffer: PropTypes.bool,
    choosePremium: PropTypes.func,
}
PremiumCard.defaultProps = {
    discount: 0
}