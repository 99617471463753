import memoizeOne from 'memoize-one';
import { capitalize, latestEpisodeLimitDefault, nameWithoutArticles, sortByTimeAsc, sortByTimeDesc } from './utils';

let browsePageTab = '';
// let browseEpisodes = {};


let maxMarkPlayedIndex = 1;
export const getMaxMarkPlayedIndex = () => {
    return maxMarkPlayedIndex;
}

export const setMaxMarkPlayedIndex = (maxMarkPlayedIndexLoc) => {
    if (maxMarkPlayedIndexLoc > maxMarkPlayedIndex) {
        maxMarkPlayedIndex = maxMarkPlayedIndexLoc;
    }
}

export const sortPodcastsByType = memoizeOne((subscribedPodcasts,
    podcastExtraDetails, type, ignoreArticlesSortPodcasts) => {
    if (type == 0) {
        subscribedPodcasts.sort((p1, p2) => {
            if (ignoreArticlesSortPodcasts) {
                return nameWithoutArticles(capitalize(p1.collectionName))
                    .localeCompare(
                        nameWithoutArticles(capitalize(p2.collectionName)));
            } else {
                return capitalize(p1.collectionName)
                    .localeCompare(capitalize(p2.collectionName));
            }
        });
        return subscribedPodcasts;
    } else if (type == 1) {
        subscribedPodcasts.sort((p1, p2) => {
            const releaseDate1 = podcastExtraDetails[`${p1.collectionId}`]
                ? (podcastExtraDetails[`${p1.collectionId}`]['pubDate'] ||
                    0)
                : 0;
            const releaseDate2 = podcastExtraDetails[`${p2.collectionId}`]
                ? (podcastExtraDetails[`${p2.collectionId}`]['pubDate'] ||
                    0)
                : 0;
            return releaseDate2 - releaseDate1;
        });
        return subscribedPodcasts;
    } else if (type == 2) {
        subscribedPodcasts.sort((p1, p2) => {
            return p1.subscribedTime.seconds - p2.subscribedTime.seconds;
        });
        return subscribedPodcasts;
    } else if (type == 3) {
        subscribedPodcasts.sort((p1, p2) => {
            return p2.subscribedTime.seconds - p1.subscribedTime.seconds;
        });
        return subscribedPodcasts;
    } else {
        return subscribedPodcasts;
    }
})

export const getBrowsePageTab = () => {
    return browsePageTab;
}

export const setBrowsePageTab = (browsePageTabOrg) => {
    browsePageTab = browsePageTabOrg;
    return;
}

// let backButtonCache = {};
// export const getBackButtonCache = (itunesId) => {
//     console.dir(backButtonCache);
//     return backButtonCache[itunesId];
// }

// export const setBackButtonCache = (itunesId, data) => {
//     console.dir(backButtonCache);
//     backButtonCache[itunesId] = data;
//     // return;
// }

let fetchRecentPlayedEpisodes = false;
export function getFetchRecentPlayedEpisodes() {
    return fetchRecentPlayedEpisodes;
}

export function setFetchRecentPlayedEpisodes(fetchRecentPlayedEpisodesOrg) {
    fetchRecentPlayedEpisodes = fetchRecentPlayedEpisodesOrg;
    return;
}

let relevantPodcastGenres = false;
export function getRelevantPodcastGenres() {
    return relevantPodcastGenres;
}

export function setRelevantPodcastGenres(relevantPodcastGenresOrg) {
    relevantPodcastGenres = relevantPodcastGenresOrg;
    return;
}

let locationInfo = {};
export function getlocationInfo() {
    return locationInfo;
}

export function setlocationInfo(locationInfoOrg) {
    locationInfo = locationInfoOrg;
    return;
}

let recommendedEpisodes = [];
export function getRecommendedEpisodes() {
    return recommendedEpisodes;
}

export function setRecommendedEpisodes(recommendedEpisodesOrg) {
    recommendedEpisodes = recommendedEpisodesOrg;
    return;
}


let forYouAdGenre = 'News';
export function getForYouAdGenre() {
    return forYouAdGenre;
}

export function setForYouAdGenre(forYouAdGenreLoc) {
    forYouAdGenre = forYouAdGenreLoc;
    return;
}

let isPremiumUser = false;
export function getIsPremiumUser() {
    return isPremiumUser;
}

export function setIsPremiumUser(isPremiumUserLoc) {
    isPremiumUser = isPremiumUserLoc;
    return;
}

let dashboardPlanId = 1;
export function getDashboardPlanId() {
    return dashboardPlanId;
}

export function setDashboardPlanId(dashboardPlanIdLoc) {
    dashboardPlanId = dashboardPlanIdLoc;
    return;
}

let addToQueueType = 1;
export function getAddToQueueType() {
    return addToQueueType;
}

export function setAddToQueueType(addToQueueTypeOrg) {
    addToQueueType = addToQueueTypeOrg;
    return;
}

let messageForRelated = 'Related';
export function getMessageForRelated() {
    return messageForRelated;
}

export function setMessageForRelated(messageForRelatedOrg) {
    messageForRelated = messageForRelatedOrg;
    return;
}
let countForRelated = 5;
export function getCountForRelated() {
    return countForRelated;
}

export function setCountForRelated(countForRelatedOrg) {
    countForRelated = countForRelatedOrg;
    return;
}

const skipEndingInSecMap = {};
export function getSkipEndingInSec(collectionId) {
    return skipEndingInSecMap[`${collectionId}`] || 0;
}

export function setSkipEndingInSec(collectionId, skipEndingInSec) {
    skipEndingInSecMap[`${collectionId}`] = skipEndingInSec || 0;
}

export const latestEpisodeLimitMapGlobal = {};
export const getLatestEpisodeLimit = (collectionId, customLimit) => {
    return latestEpisodeLimitMapGlobal[`${collectionId}`] ??
        customLimit ??
        latestEpisodeLimitDefault;
}

export const setLatestEpisodeLimit = (collectionId, limit) => {
    latestEpisodeLimitMapGlobal[`${collectionId}`] =
        limit ?? latestEpisodeLimitDefault;
}

const followedPlaylistsData = {};
export const getFollowedPlaylistsData = () => {
    return followedPlaylistsData;
}

export const setFollowedPlaylistsData = (firestoreId, data) => {
    followedPlaylistsData[firestoreId] = data;
    return;
}

const trailerData = {};
export const getTrailerData = () => {
    return trailerData;
}

export const setTrailerData = (guid, maxDuration) => {
    trailerData[guid] = maxDuration;
    return;
}

let isTyping = 0;
export const getIsTyping = () => {
    return Date.now() - isTyping < 1 * 1000;
}

export const setIsTyping = () => {
    isTyping = Date.now();
    return;
}

export const getNextEpisodeFromPlaylist = memoizeOne((episodes, currentEpisode) => {
    for (let i = 0; i < episodes.length; i++) {
        const episode = episodes[i];
        if (episode.guid == currentEpisode.guid && (i + 1) < episodes.length) {
            return episodes[i + 1];
        }
    }
    return null;
});

export const getManualQueueEpisodes = memoizeOne((episodesManualQueue, sortOrderMain) => {
    const episodes = Object.values(episodesManualQueue);
    let sortOrder;
    if (sortOrderMain == 'EARLIEST_FIRST') {
        sortOrder = sortByTimeAsc;
    } else if (sortOrderMain == 'LATEST_FIRST') {
        sortOrder = sortByTimeDesc;
    }
    episodes.sort(sortOrder);
    return episodes;
});