import {
    ADD_TO_EPISODE_REVIEWS_META, UPDATE_EPISODE_REVIEWS_META, DELETE_FROM_REVIEWS_META
}
    from '../actions/types';
// import { initialState } from '../store';
import { mergeDeep } from '../lib/utils';

// REDUCERS
export const episodeReviewsMetaReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case ADD_TO_EPISODE_REVIEWS_META: {
            // return mergeDeep(state, action.payload);
            const reviewsMeta = action.payload;
            const returnData = Object.assign({}, state, reviewsMeta);
            return returnData;
        }
        case UPDATE_EPISODE_REVIEWS_META: {
            // return Object.assign({}, state, action.payload);
            // const guid = action.payload.guid || '';
            // const entity = action.payload.entity || '';
            // const entityValue = action.payload.entityValue || '';
            // const newObj = {};
            // if (entityValue) {
            //     if (entity === 'finalLastModifiedDate') {
            //         // draft[userID][entity][ratingFilter] = entityValue;
            //         newObj[`${guid}`] = { finalLastModifiedDate: entityValue };
            //     } else {
            //         // draft[userID][entity] = entityValue;
            //         // newObj[`${userID}`] = { latestFetchDate: { [`${ratingFilter}`]: entityValue } };
            //     }
            // }

            const updateEpisodeReviewsMeta = action.payload;
            const returnData = Object.assign({}, state, updateEpisodeReviewsMeta);
            // return mergeDeep(state, action.payload);
            return returnData;
        }
        // case DELETE_FROM_REVIEWS_META: {
        //     const reviewId = action.payload || '';
        //     if (reviewId) {
        //         return produce(state, draft => {
        //             delete draft[reviewId];
        //         })
        //     } else {
        //         return state;
        //     }
        // }
        default:
            return state;
    }
};