import {
  FETCH_RECENT_PLAYED_PODCASTS, ADD_TO_RECENT_PLAYED, DELETE_FROM_RECENT_PLAYED
}
  from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const recentPodcastsReducer = (state = [], action) => {
  // console.log('recent : ' + action.type);
  switch (action.type) {
    case FETCH_RECENT_PLAYED_PODCASTS:
      return [...state, ...action.payload];
    // case ADD_TO_RECENT_PLAYED:
    //   return Object.assign({}, state, action.payload);
    // case DELETE_FROM_RECENT_PLAYED: {
    //   const copy = Object.assign({}, state);
    //   if (copy[action.payload])
    //     delete copy[action.payload];
    //   return copy; //Object.assign({}, state, action.payload);
    // }
    default:
      return state;
  }
};