import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
// import AudioPlayer from './AudioPlayer';
import { colors, bottomBarColor } from '../../lib/theme';
import { bottomBarHeight, showVideo } from '../../lib/utils';


const AudioPlayer = dynamic(
  () => import('./AudioPlayer'),
  { ssr: false }
)

const TopBar = ({ audioInfo, audioPlayState }) => (
  (!audioInfo || (Object.keys(audioInfo).length === 0 && audioInfo.constructor === Object)) ? <></> :
    <div className="fix-bottom">
      {audioInfo ?
        <div className='special-video'>
          {!showVideo(audioInfo) ? <div key='audio-key'><audio id="main-player"
            // crossOrigin="anonymous"
            // ref={this.setAudioTagRef} style={{ display: 'none' }}
            // // onDurationChange={this.onAudioStart}
            // onLoadStart={this.audioLoadStart}
            // onLoadedMetadata={this.onAudioStart}
            // onError={this.onAudioError}
            // onTimeUpdate={this.method} onEnded={this.onAudioEnd}
            // // playbackrate={audioPlaybackRate}
            // // defaultPlaybackRate={audioPlaybackRate}
            // // src={audioInfo.enclosure.link} type={audioInfo.enclosure.type}
            controls={false} autoPlay={false} loop={false}
            data-plyr-config='{ "controls": [], "hideControls": true }'
          >
            {/* <source src={audioInfo.enclosure.link} /> */}
            Your browser does not support the
            <code>audio</code> element.
          </audio></div> :
            <div key='video-key' style={{}}>
              <video id="main-player" playsInline controls>
                {/* <source src="/path/to/video.mp4" type="video/mp4" /> */}
                {/* <!-- Captions are optional -->
            <track kind="captions" label="English captions" src="/path/to/captions.vtt" srclang="en" default /> */}
              </video></div>}
        </div>
        : ''
      }
      <AudioPlayer audioPlayState={audioPlayState} />
      <style jsx>
        {`
            .fix-bottom{
                // border-radius: 0.5em 0.5em 0 0;
                position: fixed;
                z-index: 30;
                bottom: 0;
                // background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #122B44), color-stop(100%, hsla(223, 22%, 21%, 0.99))); // ${bottomBarColor}; // ${colors.primary};
                // opacity: 0.98;
                // background-image: radial-gradient( circle 909px at 8.3% 51.5%,  rgba(45,165,229,1) 0%, rgba(126,199,239,1) 74.9% ); 
                // background-image: radial-gradient( circle 909px at 8.3% 51.5%,  #3897f0 0%, rgba(126,199,239,1) 74.9% ); // vv imp
                // background-image: radial-gradient( circle 909px at 8.3% 51.5%,  ${colors.primary} 0%, rgba(126,199,239,1) 74.9% ); // imp
                background: #151515;
                width: 100%;
                left: 0;
                overflow-x: hidden;
                // padding: 0 8px;
                height: ${bottomBarHeight};
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: white;
            }
            .special-video {
              position: fixed;
              right: 0;
              bottom: ${bottomBarHeight};
              width: 30%;
              }
          `}
      </style>
    </div>
)

TopBar.propTypes = {
  audioInfo: PropTypes.object,
  audioPlayState: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    // personalValues: state.personalValues,
    audioInfo: state.audioState,
    audioPlayState: state.audioPlayState
  }
};

export default connect(mapStateToProps, null)(TopBar)
// export default TopBar