import {
  FETCH_AD_PODCASTS_ACTION, PLAY_AUDIO
}
  from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const adPodcastsReducer = (state = {}, action) => {
  switch (action.type) {
    case FETCH_AD_PODCASTS_ACTION:
      return Object.assign({}, state, { [action.genreId]: action.podcasts });
    default:
      return state;
  }
};