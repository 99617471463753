import {
    LOADING_USER_DETAILS_STATE,
    LOADING_PODCASTS,
    LOADING_SIDE_PODCASTS,
    LOADING_LATEST_EPISODES,
    REMOVE_FROM_PLAYLIST_SUCCESS,
    CREATING_NEW_PLAYLIST,
    LOADING_RECENT_PLAYED,
    LOADING_PLAYLISTS_MAIN,
    LOADING_REVIEWS,
    LOADING_REVIEW,
    LOADING_EPISODE_REVIEWS,
    LOADING_REVIEW_REPLIES,
    LOADING_BROWSE_PODCASTS,
    LOADING_BROWSE_EPISODES,
    LOADING_TAGS,
}
    from '../actions/types';

export const initialState = {
    loadingUserDetails: true,
    loadingPodcasts: false,
    // loadingSidePodcasts: true,
    // loadingLatestEpisodes: false,
    loadingTags: false,
    creatingNewPlaylist: false,
    loadingPlaylistMain: false
};

export const loadingReducer = (state = initialState, action) => {
    // console.dir(action);
    switch (action.type) {
        case LOADING_USER_DETAILS_STATE:
            return Object.assign({}, state, action.payload);
        case LOADING_PODCASTS:
            return Object.assign({}, state, action.payload);
        case LOADING_SIDE_PODCASTS:
            return Object.assign({}, state, action.payload);
        case LOADING_LATEST_EPISODES:
            return Object.assign({}, state, action.payload);
        case REMOVE_FROM_PLAYLIST_SUCCESS:
            return Object.assign({}, state, action.payload);
        case CREATING_NEW_PLAYLIST:
            return Object.assign({}, state, action.payload);
        case LOADING_RECENT_PLAYED:
            return Object.assign({}, state, action.payload);
        case LOADING_PLAYLISTS_MAIN:
            return Object.assign({}, state, action.payload);
        case LOADING_REVIEWS:
            return Object.assign({}, state, action.payload);
        case LOADING_REVIEW:
            return Object.assign({}, state, action.payload);
        case LOADING_EPISODE_REVIEWS:
            return Object.assign({}, state, action.payload);
        case LOADING_REVIEW_REPLIES:
            return Object.assign({}, state, action.payload);
        case LOADING_BROWSE_PODCASTS:
            return Object.assign({}, state, action.payload);
        case LOADING_BROWSE_EPISODES:
            return Object.assign({}, state, action.payload);
        case LOADING_TAGS:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};

export const getRequiredLoadersFn = (loadingState, ...loaders) => {
    // const obj = { userDetails: 'loadingUserDetails', recentPlayedEpisodes: 'loadingRecentPlayed' };
    const returnObj = {};
    for (let loader of loaders) {
        // const key = obj[`${loader}`];
        returnObj[loader] = loadingState[loader];
    }
    return returnObj;
}