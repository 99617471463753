import React, { Component } from 'react'
import { isInStandaloneMode, retrieveLocally, saveLocally } from '../../lib/utils';
import { CloseOutlined } from '@ant-design/icons';

export default class CookiePopup extends Component {
    constructor(props) {
        super(props);
        this.state = { showCookieConsent: false };
        // this.deferredPrompt = undefined;
    }

    componentDidMount() {
        if (!retrieveLocally('acceptedCookies') && !isInStandaloneMode()) {
            this.showCookieConsent = true;
            this.setState({ showCookieConsent: true });
            // notification.open({
            //     message: <p style={{ display: 'none' }}>Cookies</p>,
            //     description:
            //         <p>By using this page you agree to our <a href='/explore/cookies-details' target='_blank'>cookie policy.</a></p>,
            //     duration: 0,
            //     placement: 'bottomLeft'
            // });
        }
        saveLocally('acceptedCookies', true, 24 * 1000);
    }

    close = () => {
        this.setState({ showCookieConsent: false })
    }

    render() {
        const { showCookieConsent } = this.state;
        return (
            <div>
                {(showCookieConsent) && <div className='cookie-consent-popup'>
                    <p className='mb-0'>By using this page you agree to our <a href='/explore/cookies-details' target='_blank'>cookie policy.</a> &nbsp;
                        <CloseOutlined onClick={this.close} style={{ cursor: 'pointer' }} /></p>
                </div>}
                <style jsx>
                    {`
                    .cookie-consent-popup {
                        position: fixed;
                        left: 40px;
                        bottom: 60px;
                        z-index: 100;
                        background: white;
                        padding: 12px 24px;
                        border-radius: 4px;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
                    }
                    `}
                </style>
            </div>
        )
    }
}
