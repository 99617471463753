import { firestore } from '../lib/db';
import { doc, setDoc } from "firebase/firestore/lite";
import { ENABLE_KEYBOARD_PLAYBACK, FORWARD_SEEK_TIME, REWIND_SEEK_TIME } from './types';
import { saveLocally } from '../lib/utils';

// Future<void> setInterests(
//     Map genreIds, Map userDetails, bool generalNotifications) {
export const setInterests = async (genreIds, userDetails) => {
    const userUID = userDetails['uid'];
    setDoc(doc(firestore, 'podcastTags', userUID), { 'interests': genreIds }, { merge: true });
}


export const updateForwardSeekTime = (val) => async (dispatch) => {
    dispatch({
        type: FORWARD_SEEK_TIME,
        payload: parseInt(val),
    });
}
export const updateRewindSeekTime = (val) => async (dispatch) => {
    dispatch({
        type: REWIND_SEEK_TIME,
        payload: parseInt(val),
    });
}
export const updateEnableKeyboardPlayback = (val) => async (dispatch) => {
    dispatch({
        type: ENABLE_KEYBOARD_PLAYBACK,
        payload: val,
    });
}