import React from 'react';
import PropTypes from 'prop-types';
import NextHead from 'next/head';
import { connect } from 'react-redux';

import { library, config } from '@fortawesome/fontawesome-svg-core';
// import { fab } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { colors, mainLayoutColor } from '../lib/theme';

// NOTE this example only shows how to use the solid free icons.
// import { faCoffee, faIgloo, faVolumeUp, faCheckSquare, faScrewdriver, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  faRedo, faUndo, faVolumeUp, faVolumeDown, faVolumeMute, faExternalLinkAlt, faListUl, faUserFriends,
  faBars, faTimesCircle, faDownload, faEllipsisH, faPlusCircle, faSync, faUser, faUsers, faThumbsUp, faFlag,
  faChevronDown, faChevronUp,
  faThumbtack,
  faEdit,
  faReply, faGift,
  faBriefcase, faUniversity, faDice, faNewspaper, faUserAstronaut, faFlask, faFutbol, faPeopleCarry, faUserSecret, faArrowLeft, faPaintBrush,
  faTheaterMasks, faBookOpen, faDumbbell, faLandmark, faBabyCarriage, faMusic, faPrayingHands, faFootballBall, faRocket, faTv, faPen, faCheck,
  faPalette, faPodcast, faCheckCircle, faSearch, faPlus, faExclamationCircle, faArrowRight, faStar, faHistory, faPlay, faPause, faBookmark as fasBookmark, faShareSquare, faCopy, faMicrophone, faLink, faEnvelope, faTimes,
  faBolt,
  faCrown, faClock,
  faAd,
  faDesktop,
  faCloudUploadAlt
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faCircle, faPlusSquare, faBookmark, faClone } from '@fortawesome/free-regular-svg-icons';

config.autoAddCss = true;
library.add(faRedo, faUndo, faVolumeUp, faVolumeDown, faVolumeMute, faExternalLinkAlt, faClock, faCalendarAlt, faListUl, faUserFriends,
  faBars, faTimesCircle, faDownload, faEllipsisH, faCircle, faPlus, faPlusCircle, faPlusSquare, faSync, faUser, faUsers, faThumbsUp, faFlag,
  faChevronDown, faChevronUp,
  faThumbtack, faEdit, faReply,
  faBriefcase, faUniversity, faDice, faNewspaper, faUserAstronaut, faFlask, faFutbol, faPeopleCarry, faUserSecret,
  faArrowLeft, faArrowRight, faGift,
  faPaintBrush, faTheaterMasks, faBookOpen, faDumbbell, faLandmark, faBabyCarriage, faMusic, faPrayingHands, faFootballBall, faRocket, faTv, faPen,
  faCheck, faPalette, faPodcast, faCheckCircle, faExclamationCircle, faSearch, faBookmark, fasBookmark, faStar, faHistory, faPlay, faPause, faShareSquare,
  faCopy, faClone, faMicrophone, faLink, faEnvelope, faTimes, faBolt,
  faCrown, faClock, faAd, faDesktop, faCloudUploadAlt
);

// const defaultTitle = 'Home of Podcasts';
// const defaultDescription = '';
// const defaultOGURL = '';
// const defaultOGImage = '';

function Head(props) {
  // console.dir(props.settingsState);
  return (
    <NextHead>
      <meta charSet="UTF-8" />
      {/* <title>{props.title || ''}</title>
      <meta name="description" content={props.description || defaultDescription} /> */}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="icon" href="/static/favicon.png" />
      <meta httpEquiv="content-language" content="en-us"></meta>
      <meta name="apple-itunes-app" content="app-id=1497491520"></meta>
      {/* <meta property="og:url" content={props.url || defaultOGURL} />
      <meta property="og:title" content={props.title || defaultTitle} />
      <meta property="og:description" content={props.description || defaultDescription} /> */}
      {/* <meta name="twitter:site" content={props.url || defaultOGURL} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content={props.ogImage || defaultOGImage} />
    <meta property="og:image" content={props.ogImage || defaultOGImage} /> */}
      {/* <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" /> */}

      <link rel='stylesheet' type='text/css' href='/static/css/common.css' />
      <link rel="manifest" href="/static/manifest.json" />
      {/* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css" /> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,400i" rel="stylesheet"></link> */}
      {/* <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,500,400i" rel="stylesheet"></link> */}
      <link rel="preconnect" href="https://fonts.gstatic.com/"></link>
      <link href="https://fonts.googleapis.com/css?family=Sen:500|Didact+Gothic:wght@400&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=PT+Serif&display=swap" rel="stylesheet"></link>
      <link href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@500;600&display=swap" rel="stylesheet"></link>
      {/* <link rel="stylesheet" href="https://cdn.plyr.io/3.6.8/plyr.css" /> */}
      {/* <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap" rel="stylesheet"></link>  */}
      {/* <link href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap" rel="stylesheet">  */}
      {/* <link href="https://fonts.googleapis.com/css?family=Poppins:400,500&display=swap" rel="stylesheet"></link> */}

      {/* <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}');
          `
        }}
      /> */}
      {/* <script defer
        dangerouslySetInnerHTML={{
          __html: `
                            (function(h,o,t,j,a,r){
                                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                                h._hjSettings={hjid:1327055,hjsv:6};
                                a=o.getElementsByTagName('head')[0];
                                r=o.createElement('script');r.async=1;
                                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                                a.appendChild(r);
                            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
        }}
      /> */}

      {/* <script charSet="UTF-8" src='/static/js/clamp.min.js' async></script> */}

      {/* Dont put jsx in the style below, it causes a bug *************** */}
      <style global>{`
        // body { 
        //   // background: black !important; // ${mainLayoutColor};
        //   background-color: ${props.settingsState.mainLayoutColor} !important;
        //   // font: 11px menlo;
        //   // color: #fff;
        //   // font-family: 'Open Sans', sans-serif;
        //   // font-family: 'Poppins', sans-serif;
        //   // font-family: 'Sen', sans-serif;
        // }
        body {
          color: ${props.settingsState.mediumTextColor};
          font-family: 'Didact Gothic', sans-serif !important;
        }
        h1,h2,h3{
          font-family: 'Sen', sans-serif;
          color:  ${props.settingsState.largeTextColor} !important;
        }
        h2.gothic {
          font-family: 'Didact Gothic', sans-serif;
        }
        h4 {
          // font-family: 'Zilla Slab', sans-serif;
          font-family: 'Didact Gothic', sans-serif;
          color:  ${props.settingsState.largeTextColor} !important;
        }
        h4.white {
          color: white !important;
        }
        h5 {
          color:  ${props.settingsState.largeTextColor} !important;
        }
        p {
          color: ${props.settingsState.mediumTextColor}; // #4e4c68;
        }
        h1.dark,h2.dark,h3.dark {
          color: #030028 !important;
        }
        .dark h1, .dark h2, .dark h3 {
          color: #030028 !important;
        }
        h4.dark, h5.dark,p.dark {
          color: #030028dd !important;
        }
        .dark h4, .dark h5, .dark p {
          color: #030028dd !important;
        }
        h5.light {
          color: white !important;
        }
        h1 {
          font-weight: 700;
        }
        .serif {
          // font-family: 'Merriweather', serif;
          font-family: 'Zilla Slab', serif;
        }
        h1.serif {
          // font-family: 'Playfair Display', serif;
          // font-family: 'Merriweather', serif;
          font-family: 'Zilla Slab', serif;
        }
        p.serif {
          // font-family: 'Playfair Display', serif;
          // font-family: 'Merriweather', serif;
          font-family: 'PT Serif', serif;
          color: ${props.settingsState.mediumTextColor};
        }
        h3.serif {
          // font-family: 'Playfair Display', serif;
          // font-family: 'Merriweather', serif;
          font-family: 'Zilla Slab', serif;
        }
        .extra-line-height {
          line-height: 26px;
        }
        // h3 {
        //   color: #132968;
        // }
        .ant-tabs-tab {
          font-family: 'Sen', sans-serif;
        }
        // html {
        //   background: ${mainLayoutColor};
        // }
        :global(a){
          color: ${colors.primary} !important;
        }
        body {
          color: ${props.settingsState.mediumTextColor} !important;
        }
        .css-link {
          cursor: pointer;
          color: #4E7CFF;
        }
        .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
          color: ${props.settingsState.mediumTextColor} !important;
        }
        .ant-drawer-content-wrapper {
          width: 720px !important;
        }
        .ant-drawer-header {
          background-color: ${props.settingsState.mainLayoutColor} !important;
          border-bottom: 1px solid hsla(223.9, 63.7%, 62.2%, 0.2);
        }
        .ant-drawer-content {
          background-color: ${props.settingsState.mainLayoutColor} !important;
        }
        .ant-drawer-close, .ant-modal-close, .ant-form-item-label > label {
          color: ${props.settingsState.primaryColorDark} !important;
        }
        .ant-dropdown-menu, .ant-select-dropdown {
          background-color: ${props.settingsState.mainLayoutColor} !important;
        }
        .ant-select-item {
          color: ${props.settingsState.mediumTextColor} !important;
        }
        .ant-modal-confirm-content {
          color: ${props.settingsState.mediumTextColor} !important;
        }
        .ant-select-item:hover {
          background-color: ${props.settingsState.mainLayoutColor} !important;
        }
        .ant-modal-header, .ant-modal-body {
          background: ${props.settingsState.mainLayoutColor} !important;
        }
        .ant-modal-header {
          border-bottom: 1px solid hsla(223.9, 63.7%, 62.2%, 0.2);
        }
        .ant-alert-message {
          color: ${props.settingsState.primaryColorDark};
          opacity: 0.85;
        }
        .ant-tabs-bar {
          // color: hsla(223.9, 63.7%, 62.2%, 0.2);
          border-bottom: 1px solid hsla(223.9, 63.7%, 62.2%, 0.2);
        }
        .ant-tabs {
          color: ${props.settingsState.antdTextColor};
        }
        .ant-modal-title, .ant-modal-confirm-body .ant-modal-confirm-title {
          color: ${props.settingsState.primaryColorDark};
        }
        .ant-modal-content {
          border-radius: 24px !important;
        }
        .ant-modal-body {
          border-radius: 24px !important;
        }
        .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
          background-color: #a6a6a611 !important;
        }
        .ant-btn {
          border-radius: 6px !important;
        }
        .mb-0 {
          margin-bottom: 0;
        }
        .ml-2 {
          margin-left: 1em;
        }
        .mt-1 {
          margin-top: 0.5em;
        }
        .mt-2 {
          margin-top: 1em;
        }
        .separator {
          height: 1px;
          width: 100%;
          color: ${colors.primaryDim};
          background-color: ${colors.primaryDim};
          border: none;
        }
        .embed-code {
          font-size: 14px;
          margin-bottom: 0;
          margin-top: 12px;
        }
        .embed-widget {
          background: white;
          border: 1px solid #e5e5e5;
          color: black;
          padding: 8px;
          border-radius: 4px;
          font-size: 12px;
        }
        .text-center {
          text-align: center;
        }
        .place-1 input {
          background: ${props.settingsState.inputBgColor};
          border: 1px solid grey;
          color: ${props.settingsState.primaryColorDark};
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: transparent !important;
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled):hover {
          background-color: transparent !important;
        }
      `}</style>
    </NextHead>
  );
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  settingsState: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    settingsState: state.settingsState,
  }
}

// export default Head
export default connect(mapStateToProps, null)(Head);
