import {
    USER_INTERESTS_ACTION
}
    from '../actions/types';

export const userInterestsReducer = (state = null, action) => {
    // console.dir(action);
    switch (action.type) {
        case USER_INTERESTS_ACTION:
            return Object.assign({}, action.payload);
        default:
            return state;
    }
};